import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    HostListener,
    Inject,
    Input,
    OnDestroy,
    ViewChild
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth } from '@trade-platform/ui-shared';
import {
    AixImageViewerComponent,
    AixLoadingComponent,
    AixNotificationComponent,
    AixPdfViewerComponent,
    debounceListener
} from '@trade-platform/ui-components';
import { ENVIRONMENT, getFromStorage, IEnvironment, pdfName } from '@trade-platform/ui-utils';
import * as PDFJS from 'pdfjs-dist';
import { merge, Observable, Subject, Subscription } from 'rxjs';
import { AixAuthService, routeConstants } from '@advisor-ui/app-services';
import { getFormByIndex } from '../../process/overview/utils/order-utils';
import { getDocumentIndexById } from '../../utils';
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { RemoteDataModule } from 'ngx-remotedata';
import { SecurePipe } from '@advisor-ui/app-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '@advisor-ui/orders';

type PDFSource = {
    url: string;
    httpHeaders?: Record<string, any>;
};

@Component({
    selector: 'aix-document-viewer',
    templateUrl: './document-viewer.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgStyle,
        NgIf,
        AixLoadingComponent,
        AixNotificationComponent,
        AixPdfViewerComponent,
        LetDirective,
        AixImageViewerComponent,
        NgClass,
        AsyncPipe,
        RemoteDataModule,
        SecurePipe
    ]
})
export class AixDocumentViewerComponent implements OnDestroy {
    @Input()
    currentDocument = 0;
    @Input()
    documentControls = {};

    @ViewChild(AixPdfViewerComponent)
    pdf: AixPdfViewerComponent;

    @ViewChild(AixImageViewerComponent)
    image: AixImageViewerComponent;

    currentPage = 0;

    actionsLoadingState$: Observable<any>;

    authToken: string;

    styles = {
        pdfWrapper: {}
    };

    PDFJSloaded = false;
    offline = false;

    loadingImage = new Subject();
    isPdf = true;
    isSpreadsheet = false;
    pdfLoaded = false;
    pdfLoadError = false;
    pdfFullscreen = false;
    pdfName: (name: string) => string;

    subscriptions: Subscription[] = [];

    readonly staticStyles = {
        pdfFullScreenStyle: {
            position: 'absolute',
            left: '0',
            width: '100%',
            'z-index': '1002'
        },
        pdfInnerFullscreenStyle: {
            width: '100%'
        }
    };

    form: UntypedFormGroup;

    pdfSource: PDFSource;

    constructor(
        @Inject(ENVIRONMENT) private environment: IEnvironment,
        public router: Router,
        public route: ActivatedRoute,
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        private ref: ChangeDetectorRef,
        private authService: AixAuthService
    ) {
        this.authToken = 'Bearer ' + (getFromStorage('auth') as Auth).accessToken;

        this.pdfName = pdfName;

        this.form = new UntypedFormGroup({
            recipient: new UntypedFormControl()
        });

        this.actionsLoadingState$ = merge(
            this.storeFacade.orderApproveRemoteData$,
            this.storeFacade.orderSignRemoteData$,
            this.storeFacade.orderMarkNigoRemoteData$
        );

        this.storeFacade.actions.documentViewerSetTab.dispatch({ tab: 'Order' });

        Promise.all([
            // @ts-ignore: only accepted in commonJs o esNext
            import(/* webpackChunkName: "PDFJS" */ 'pdfjs-dist/build/pdf.min.mjs')
        ]).then(() => {
            PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.mjs';
            this.PDFJSloaded = true;
            this.init();
            this.storeFacade.actions.documentViewerSetInitialized.dispatch({ initialized: true });
        });
    }

    init() {
        // Subscriptions;
        this.subscriptions.push(
            this.storeFacade.actions.documentViewerSetDocument.success$.subscribe(action => {
                const filesToDisplay = this.storeFacade.documentViewer.filesToDisplay;
                this.currentDocument = getDocumentIndexById(
                    filesToDisplay,
                    action.payload.currentDocument
                );
                this.activateFile(this.currentDocument);
            }),
            this.storeFacade.actions.documentViewerSetForm.success$.subscribe(_ => {
                this.createPdfSource(true);
            }),
            this.storeFacade.actions.documentViewerSetOffline.success$.subscribe(action => {
                if (action.payload.offline) {
                    this.styles.pdfWrapper = this.staticStyles.pdfInnerFullscreenStyle;
                } else {
                    this.styles.pdfWrapper = {};
                }
                this.ref.detectChanges();
            }),
            this.storeFacade.orderApproveFailure$.subscribe(_ => {
                if (this.storeFacade.order) {
                    this.createPdfSource();
                }
            }),
            this.storeFacade.orderSignSuccess$.subscribe(oSign => {
                // Redirect to returned docusign url;
                this.router.navigate(
                    routeConstants.routes.externalDocusignRedirect.index(oSign.url),
                    {
                        skipLocationChange: true
                    }
                );
            })
        );

        this.ref.markForCheck();
    }

    getCurrentIndex(id: string) {
        const docState = this.storeFacade.documentViewer;
        return getDocumentIndexById(docState.filesToDisplay, id);
    }

    onClickNextDocument() {
        const docState = this.storeFacade.documentViewer;
        if (!docState.offline) {
            if (this.pdf) {
                this.pdf.isLoading$.next(true);
            }

            const totalDocs = docState.filesToDisplay.length;
            let index = getDocumentIndexById(docState.filesToDisplay, docState.currentDocument);
            index++;

            if (this.pdfFullscreen) {
                this.pdfFullscreen = !this.pdfFullscreen;
            }

            if (index === totalDocs) {
                index = 0;
            }

            const order = this.storeFacade.order;
            const nextDoc = this.storeFacade.documentViewer.filesToDisplay[index];
            this.router.navigate(this.storeFacade.routes.documentViewer(order.id, nextDoc.id));
        }
    }

    onClickPrevDocument() {
        const docState = this.storeFacade.documentViewer;
        if (!docState.offline) {
            if (this.pdf) {
                this.pdf.isLoading$.next(true);
            }

            const totalDocs = docState.filesToDisplay.length;
            let index = getDocumentIndexById(docState.filesToDisplay, docState.currentDocument);
            index--;

            if (this.pdfFullscreen) {
                this.pdfFullscreen = !this.pdfFullscreen;
            }

            if (index < 0) {
                index = totalDocs - 1;
            }

            const order = this.storeFacade.order;
            const prevDoc = this.storeFacade.documentViewer.filesToDisplay[index];
            this.router.navigate(this.storeFacade.routes.documentViewer(order.id, prevDoc.id));
        }
    }

    /**
     * Activates the file at the given file index;
     * @param fileIndex The index of the newly active file;
     */
    activateFile(fileIndex: number) {
        const order = this.storeFacade.order;
        const filesToDisplay = this.storeFacade.documentViewer.filesToDisplay;
        let offline = false;

        if (order && order.forms) {
            let currentFile = null;
            if (filesToDisplay && filesToDisplay.length > 0) {
                currentFile = filesToDisplay[fileIndex];
            }

            // If file doesn't exist at index, check for form;
            const currentForm =
                currentFile && currentFile.formId
                    ? order.forms[currentFile.formId]
                    : getFormByIndex(order, fileIndex);

            // Check for not onboarded form;
            if (currentForm && currentForm.isOnboarded === false) {
                offline = true;
                this.isPdf = offline;
            }

            if (currentFile) {
                this.isPdf = currentFile.name.toLowerCase().endsWith('.pdf');
                this.isSpreadsheet =
                    currentFile.name.toLowerCase().endsWith('.csv') ||
                    currentFile.name.toLowerCase().endsWith('.xlsx');
            }

            if (currentFile || currentForm) {
                if (this.isPdf) {
                    this.createPdfSource(offline);
                } else if (this.isSpreadsheet) {
                    console.log('Download in order to view file.');
                } else {
                    this.createImageSource();
                }
            }
        }
        this.ref.markForCheck();
    }

    createImageSource() {
        this.loadingImage.next(true);

        const order = this.storeFacade.order;
        const filesToDisplay = this.storeFacade.documentViewer.filesToDisplay;
        this.pdfLoaded = false;
        const file = filesToDisplay[this.currentDocument];

        this.pdfSource = {
            url: `${this.storeFacade.apiUrl}/${order.id}/download/${
                file.id
            }?download=false&cacheBuster=${new Date().getTime()}`
        };
        this.ref.markForCheck();
    }

    createPdfSource(offline = false) {
        const order = this.storeFacade.order;
        const docState = this.storeFacade.documentViewer;
        const filesToDisplay = docState.filesToDisplay;
        const currentFile = docState.currentFile;
        const currentForm = docState.currentForm;
        let url = '';

        if (currentForm && (docState.offline || offline)) {
            const formId = currentForm.id;
            url = `${this.environment.adminApi}forms/${formId}/download/template`;
        }

        if (currentFile) {
            if (order && filesToDisplay.length) {
                const file = filesToDisplay[this.currentDocument];
                url = `${this.storeFacade.apiUrl}/${order.id}/download/${
                    file.id
                }?download=false&cacheBuster=${new Date().getTime()}`;
            }
        }

        if (url) {
            const pdfSource: PDFSource = { url };

            if (this.authToken) {
                pdfSource.httpHeaders = {
                    Authorization: this.authToken,
                    'Accept-Content-Type': 'application/pdf'
                };
            }

            this.pdfSource = pdfSource;
            this.ref.markForCheck();
        }
    }

    canDisplayPagination() {
        return (
            this.pdf &&
            this.isPdf &&
            Number.parseFloat(this.pdf.page() as any) >= 0 &&
            Number.parseFloat(this.pdf.totalPages as any) >= 0
        );
    }

    onLoadImage() {
        this.loadingImage.next(false);
        this.pdfLoaded = true;
        this.pdfLoadError = false;
    }

    onLoadDocument() {
        this.loadingImage.next(false);
        this.pdfLoaded = true;
        this.pdfLoadError = false;
    }

    onLoadDocumentError(error: any) {
        this.pdfLoaded = true;
        this.pdfLoadError = true;

        if (error && (error.status === 401 || error.status === 403)) {
            this.authService.callLogout(true);
        }
    }

    onClickFullscreen() {
        const docState = this.storeFacade.documentViewer;
        this.pdfFullscreen = !this.pdfFullscreen;

        if (this.pdfFullscreen) {
            this.styles.pdfWrapper = this.staticStyles.pdfFullScreenStyle;
        } else {
            this.styles.pdfWrapper = docState.offline
                ? this.staticStyles.pdfInnerFullscreenStyle
                : {};
        }

        this.fitDocument();
    }

    onClickRotate() {
        if (this.isPdf) {
            this.pdf.rotate();
        } else {
            this.image.rotate();
        }
    }

    zoomIn() {
        if (this.isPdf) {
            this.pdf.zoomIn();
        } else {
            this.image.zoomIn();
        }
    }

    zoomOut() {
        if (this.isPdf) {
            this.pdf.zoomOut();
        } else {
            this.image.zoomOut();
        }
    }

    fitDocument() {
        // Wait for parent container to go fullscreen before attempting to fit PDF into it;
        setTimeout(() => {
            this.doFit();
        }, 100);
    }

    doFit() {
        if (this.pdf) {
            this.pdf.fit();
            this.pdf.simulateScroll(); // Trigger PDF resize;
        } else {
            this.image.fit();
        }
    }

    @HostListener('window:resize', ['$event'])
    @debounceListener()
    scroll() {
        this.doFit();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
