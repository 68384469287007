<div
    class="u-flex flex-between-center checkbox-filter"
    [class.checkbox-filter__active]="filterLength > 0 && filterLength + 1 !== filterList().length"
    (click)="toggleOpenDropdown()"
    [data-testing]="'open-close-advance-filter'"
>
    <span>
        @if (!isDropdownOpen && filterLength > 0 && (filterLength + 1) !== filterList().length) {
        <span class="aix-pill">{{ filterLength }}</span>
        }
        <span class="u-ml4 u-mr4">{{ name() }}</span>
    </span>

    @if (!isDropdownOpen && filterLength > 0 && (filterLength + 1) !== filterList().length) {
    <span
        class="fa-solid fa-xmark-large checkbox-filter-close-button"
        (click)="clearFilter(true)"
    ></span>
    } @else {
    <span class="icon fa-solid fa-caret-down"></span>
    }
</div>

@if (isDropdownOpen) {
<div #coverRef class="checkbox-filter__list-cover"></div>
<div class="flex-col flex-left-center checkbox-filter-dropdown checkbox-filter__list">
    <div class="u-full-width flex-between-center">
        <span class="u-fw600">Selected</span>
        <span class="aix-pill">{{ filterLength }}</span>
    </div>

    <div class="u-full-width flex-between-center search-box-container">
        <aix-search-box
            class="u-full-width"
            #searchBoxComponent
            [placeholder]="'Search'"
            [iconClasses]="'icon fa-regular fa-search'"
            (onStartSearch)="updateFilters()"
            (onSearch)="updateFilters()"
        >
        </aix-search-box>
    </div>

    <div class="u-full-width u-mb16 u-pr16 u-overflow-scroll">
        <aix-checkbox-list
            [hasPartialSelect]="true"
            [filters]="filteredList"
            [activeFilter]="activeFilter()"
            (onSelectFilter)="onSelectFilter($event)"
        ></aix-checkbox-list>
    </div>

    <div class="u-full-width flex-between-center">
        <aix-button
            [isDisabled]="
                filterLength + 1 === filterList().length &&
                searchBoxComponent.searchText.length === 0
            "
            [data-testing]="'clearFilterButton'"
            (click)="clearFilter(true)"
            [buttonType]="clearFilterButtonType"
            [buttonLabel]="'Clear Filter'"
        >
        </aix-button>
        <aix-button
            class="checkbox-filter-apply-button"
            [isDisabled]="!applyFilterButtonEnabled()"
            [data-testing]="'setFilterButton'"
            (click)="setFilter()"
            [buttonType]="setFilterButtonType"
            [buttonLabel]="'Apply Filter'"
        >
        </aix-button>
    </div>
</div>
}
