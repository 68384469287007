<div class="header--section u-mt40">
    <div class="-heading">
        <h3>Account Information</h3>
    </div>
</div>

<h5>Account Details</h5>
<p class="u-mt8">Select an investor account.</p>

<form [formGroup]="form">
    <div class="aix-form__container">
        <div class="aix-form__group u-mt16">
            <aix-dropdown
                #accountDropdownRef
                class="aix-form__control--required"
                [class.aix-form__control--dirty]="accountDropdownRef.isDirty"
                [class.aix-form__control--invalid-required]="accountDropdownRef.isActionButtonSelected ? false :
                        (form.value.accountSelected?.length >= 0 ?
                            !form.value.accountSelected?.length :
                            !form.value.accountSelected)"
                [class.aix-form__control--invalid]="accountDropdownRef.isActionButtonSelected ? false :
                        (form.value.accountSelected?.length >= 0 ?
                            !form.value.accountSelected?.length :
                            !form.value.accountSelected)"
                [aixPendingField]="{ control: form.get('accountSelected') }"
                [formControlName]="'accountSelected'"
                labelField="displayName"
                valueField="id"
                placeholder=""
                data-testing="account"
                [options]="accounts"
                (filter)="onFilterAccounts($event)"
                [omitFiltering]="true"
                [actionButtonLabel]="'Add Investor Account'"
                (onClickAction)="onClickDropdownAction()"
                (onBlurred)="onBlurInvestorAccount()"
            >
            </aix-dropdown>
            <label class="aix-form__label aix-label">Investor Account</label>
        </div>
    </div>
</form>
