import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    output
} from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '@advisor-ui/orders';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { FormListItem, AixFormlistComponent } from '../../formlist/formlist';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderForm } from '@trade-platform/ui-shared';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'aix-map-signatures',
    templateUrl: './map-signatures.html',
    styleUrls: ['./map-signatures.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        AixDataTestingDirective,
        AixButtonComponent,
        AixFormlistComponent,
        AsyncPipe
    ]
})
export class AixMapSignaturesComponent implements OnInit, OnDestroy {
    submitForm = output();
    cancelMapSignatures = output();

    protected orderId: string;

    subscriptions: Subscription[] = [];

    cancelMappingButtonType = BUTTON_TYPE.link;
    startMappingButtonType = BUTTON_TYPE.primary;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        protected route: ActivatedRoute,
        protected router: Router
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            (this.route.parent as ActivatedRoute).params.subscribe(params => {
                this.orderId = params['orderId'];
            })
        );
    }

    isFormNotOnboarded(form: OrderForm) {
        return !form.isOnboarded;
    }

    goToForm(item?: FormListItem) {
        if (item && item.form.isOnboarded === false) {
            this.router.navigate(
                this.storeFacade.routes.documentViewer(this.orderId, item.document.id)
            );
        } else {
            this.router.navigate(
                this.storeFacade.routes.form(
                    this.orderId,
                    item
                        ? item.form.id
                        : Object.keys(this.storeFacade.order.forms)
                              .map(itm => this.storeFacade.order.forms[itm])
                              .sort((a, b): number => a.index - b.index)[0].id
                )
            );
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
