import { ChangeDetectionStrategy, Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
    AppState,
    FlashMessage,
    FlashMessageType,
    RemoveFlashMessageAction
} from '@trade-platform/ui-shared';
import { Observable } from 'rxjs';
import { NgFor, NgClass, NgIf, AsyncPipe } from '@angular/common';
import { AixSanitizePipe } from 'libs/ui-utils/src/lib/utils/pipes/sanitize';

@Component({
    selector: 'aix-flash-messages',
    templateUrl: 'flash-messages.html',
    styleUrls: ['flash-messages.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgFor, NgClass, NgIf, AsyncPipe, AixSanitizePipe]
})
export class FlashMessagesComponent {
    messages$: Observable<FlashMessage[]>;
    MsgType = FlashMessageType; // enums cant be used in templates

    constructor(public store: Store<AppState>) {
        this.messages$ = this.store.pipe(select(state => state.flashMessages.messages));
    }

    closeMessage(uid: string) {
        this.store.dispatch(new RemoveFlashMessageAction({ uid }));
    }
}
