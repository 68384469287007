import { Component, Input, output } from '@angular/core';
import {
    AixProductCardComponent,
    ProductCardSelection,
    ProductRecord
} from '@trade-platform/ui-shared';
import { NgFor } from '@angular/common';
import { ProductsPipe } from '@advisor-ui/app-components';

@Component({
    selector: 'aix-product-container-list',
    templateUrl: './product-container-list.component.html',
    styleUrls: ['./product-container-list.component.scss'],
    standalone: true,
    imports: [NgFor, AixProductCardComponent, ProductsPipe]
})
export class AixProductContainerListComponent {
    @Input() cards: ProductRecord[];
    @Input() productSearchText: string;
    @Input() productCardLanguage: { notificationText: string };

    productCardSelected = output<ProductCardSelection>();

    productCardFavorite = output<ProductRecord>();

    constructor() {}

    favoriteCard(card: ProductRecord) {
        this.productCardFavorite.emit(card);
    }

    productCardSelect(e: ProductCardSelection) {
        this.productCardSelected.emit(e);
    }
}
