<aix-input
    [config]="config"
    [isStandalone]="false"
    [isRequired]="templateData.fieldIsRequired"
    [isDisabled]="templateData.fieldIsDisabled"
    [isDirty]="templateData.fieldIsDirty"
    [isValid]="!templateData.ctrlIsInvalid"
    [name]="config.refId ?? ''"
    [aixDynamicNextPendingField]="{ config: config }"
    [value]="templateData.valueToRender"
    [placeholder]="config?.placeholder ?? ''"
    [hint]="config?.hint ?? ''"
    (setDirty)="setDirty()"
    (valueChanges)="valueChange($event)"
>
    {{ config.label }}
</aix-input>

@if (templateData.canDisplayErrors) {
<div class="form-error--container">
    <div
        class="form-error"
        title="{{ templateData.ctrlErrors | aixFormErrors }}"
        [data-testing]="config.refId! + '_validation_error'"
    >
        {{ templateData.ctrlErrors | aixFormErrors : config.label }}
    </div>
</div>
}
