import { JsonDecoder } from 'ts.data.json';
import { Plan } from '../../models/plan/model';

export const planDecoder = JsonDecoder.object<Plan>(
    {
        planName: JsonDecoder.optional(JsonDecoder.string),
        planNumber: JsonDecoder.optional(JsonDecoder.string),
        sponsorEin: JsonDecoder.optional(JsonDecoder.string),
        sponsorName: JsonDecoder.optional(JsonDecoder.string),
        sponsorDbaName: JsonDecoder.optional(JsonDecoder.string),
        sponsorAddressStreet1: JsonDecoder.optional(JsonDecoder.string),
        sponsorAddressStreet2: JsonDecoder.optional(JsonDecoder.string),
        sponsorAddressCity: JsonDecoder.optional(JsonDecoder.string),
        sponsorAddressState: JsonDecoder.optional(JsonDecoder.string),
        sponsorAddressZip: JsonDecoder.optional(JsonDecoder.string)
    },
    'Plan'
);
