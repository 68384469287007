<div
    class="u-flex flex-between-center date-filter"
    (click)="showDropdown()"
    [data-testing]="'open-close-date-filter'"
>
    <span>
        @if (hasFilter) {
        <span class="fa-regular fa-calendar-days"></span>
        }
        <span class="u-ml4 u-mr4">{{ label() }}</span>
    </span>
    @if (hasFilter) {
    <span
        class="fa-solid fa-xmark-large date-filter-close-button"
        (click)="onClickClearFilterIcon()"
    ></span>
    } @if (!hasFilter) {
    <span class="fa-solid fa-caret-down u-font-size--12"></span>
    }
</div>

@if (isFilterOpen()) {
<div #coverRef class="date-filter__cover"></div>
<div class="flex-col flex-left-center date-filter-dropdown">
    <form [formGroup]="dateFilterForm" class="u-full-width">
        <aix-radio-group
            formControlName="dateFilter"
            [options]="dateFilterOptions"
            [labelField]="'title'"
            [valueField]="'value'"
            [descriptionField]="'description'"
            [isValid]="!!dateFilterForm.get('dateFilter')?.value"
            [isRequired]="true"
            (valueChange)="onSelectDateFilter($event)"
        >
        </aix-radio-group>

        <div class="aix-form__group">
            <aix-date
                formControlName="beginDate"
                [data-testing]="'beginDate'"
                [isDisabled]="dateFilterForm.get('dateFilter')?.value !== 'custom'"
                [isRequired]="dateFilterForm.get('dateFilter')?.value === 'custom'"
                [format]="'mmddyyyy'"
                [isValid]="!dateFilterForm.get('beginDate')?.errors"
                (valueChanges)="updateDateValidation()"
            >
                Begin Date
            </aix-date>

            @if (dateFilterForm.value?.beginDate &&
            dateFilterForm.get('beginDate')?.errors?.validateMaxDate?.maxDate) {
            <div class="form-error--container">
                <div class="form-error">
                    {{ dateFilterForm.get('beginDate')?.errors?.validateMaxDate?.maxDate }}
                </div>
            </div>
            }
        </div>

        <div class="aix-form__group">
            <aix-date
                formControlName="endDate"
                [data-testing]="'endDate'"
                [isDisabled]="dateFilterForm.get('dateFilter')?.value !== 'custom'"
                [isRequired]="dateFilterForm.get('dateFilter')?.value === 'custom'"
                [format]="'mmddyyyy'"
                [isValid]="!dateFilterForm.get('endDate')?.errors"
                (valueChanges)="updateDateValidation()"
            >
                End Date
            </aix-date>

            @if (dateFilterForm.value?.endDate &&
            dateFilterForm.get('endDate')?.errors?.validateMaxDate?.maxDate) {
            <div class="form-error--container">
                <div class="form-error">
                    {{ dateFilterForm.get('endDate')?.errors?.validateMaxDate?.maxDate }}
                </div>
            </div>
            }
        </div>

        @if ((dateFilterForm.value?.beginDate &&
        dateFilterForm.get('beginDate')?.errors?.invalidRange) || (dateFilterForm.value?.beginDate
        && dateFilterForm.get('endDate')?.errors?.invalidRange)) {
        <div class="form-error--container">
            <div class="form-error">The End Date cannot be earlier than the Begin Date.</div>
        </div>
        }
    </form>

    <div class="u-full-width flex-between-center">
        <aix-button
            [isDisabled]="dateFilterForm.controls.dateFilter.value === 'allTime'"
            [data-testing]="'clearFilterButton'"
            (click)="clearFilter()"
            [buttonType]="clearFilterButtonType"
            [buttonLabel]="'Clear Filter'"
        >
        </aix-button>
        <aix-button
            [isDisabled]="dateFilterForm.invalid || dateFilterForm.controls.dateFilter.pristine"
            class="date-filter-apply-button"
            [data-testing]="'setFilterButton'"
            (click)="setFilter()"
            [buttonType]="setFilterButtonType"
            [buttonLabel]="'Apply Filter'"
        >
        </aix-button>
    </div>
</div>
}
