import { AixAction, ErrorWrapper, OrderQueueGroup, type } from '@trade-platform/ui-shared';

export class LoadOrdersAdvancedFiltersActionTypes {
    static readonly LOAD_ORDERS_ADVANCED_FILTERS = type('[Orders] Load Orders Advanced Filters');
    static readonly LOAD_ORDERS_ADVANCED_FILTERS_SUCCESS = type(
        '[Orders] Load Orders Advanced Filters Success'
    );
    static readonly LOAD_ORDERS_ADVANCED_FILTERS_FAILURE = type(
        '[Orders] Load Orders Advanced Filters Failure'
    );
}

export type LoadOrdersAdvancedFiltersActions =
    | LoadOrdersAdvancedFilters
    | LoadOrdersAdvancedFiltersSuccess
    | LoadOrdersAdvancedFiltersFailure;

export class LoadOrdersAdvancedFilters implements AixAction {
    readonly type = LoadOrdersAdvancedFiltersActionTypes.LOAD_ORDERS_ADVANCED_FILTERS;
}

export class LoadOrdersAdvancedFiltersSuccess implements AixAction {
    readonly type = LoadOrdersAdvancedFiltersActionTypes.LOAD_ORDERS_ADVANCED_FILTERS_SUCCESS;

    constructor(public payload: OrderQueueGroup[]) {}
}

export class LoadOrdersAdvancedFiltersFailure implements AixAction {
    readonly type = LoadOrdersAdvancedFiltersActionTypes.LOAD_ORDERS_ADVANCED_FILTERS_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
