import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import {
    createPaginationHttpParams,
    OrderRecord,
    orderRecordDecoder,
    Page,
    paginationDecoder,
    PaginationOptions
} from '@trade-platform/ui-shared';

@Injectable()
export class OrdersService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getOrders(opts: PaginationOptions<any>): Observable<Page<OrderRecord>> {
        let advancedFilters = '';
        if (opts.filters?.advanced) {
            advancedFilters = '?' + opts?.filters?.advanced;
        }

        return this.http
            .get<Page<OrderRecord>>(`${this.environment.api}orders/listByQueue${advancedFilters}`, {
                params: createPaginationHttpParams(opts),
                observe: 'response'
            })
            .pipe(
                concatMap(response => {
                    return fromPromise(
                        paginationDecoder<OrderRecord>(
                            orderRecordDecoder,
                            parseInt(response.headers.get('x-total-count') as string, 10),
                            parseInt(response.headers.get('x-total-pages') as string, 10),
                            'Orders'
                        ).decodeToPromise(response)
                    );
                })
            );
    }
}
