<aix-ssn
    [config]="config"
    [isStandalone]="false"
    [isRequired]="templateData.fieldIsRequired"
    [isDisabled]="templateData.fieldIsDisabled"
    [isDirty]="templateData.fieldIsDirty"
    [isValid]="!templateData.ctrlIsInvalid"
    [aixDynamicNextPendingField]="{ config: config }"
    [initialValue]="value"
    [hint]="config?.hint ?? ''"
    (onFocus)="onFocus()"
    (onBlur)="onBlur()"
    (setDirty)="setDirty()"
    (valueChanges)="valueChange($event)"
>
    {{ config.label }}

    <!-- Field decoration -->
    @if (fieldDecoration) {
    <div fieldDecoration>
        <ng-container
            aixFieldDecoration
            [decoration]="fieldDecoration"
            [params]="fieldDecorationParams"
            [hostObservables]="fieldDecorationHostObservables"
        >
        </ng-container>
    </div>
    }
</aix-ssn>

@if (templateData.canDisplayErrors) {
<div class="form-error--container">
    <div
        class="form-error"
        title="{{ templateData.ctrlErrors | aixFormErrors }}"
        [data-testing]="config.refId! + '_validation_error'"
    >
        {{ templateData.ctrlErrors | aixFormErrors : config.label }}
    </div>
</div>
}
