@for (msg of (messages$ | async); track msg) {
<div
    class="flash-message"
    [ngClass]="{
        default: msg.type === MsgType.DEFAULT,
        error: msg.type === MsgType.ERROR,
        warn: msg.type === MsgType.WARN,
        success: msg.type === MsgType.SUCCESS
    }"
>
    <span class="flash-message__message" [innerHTML]="msg.text | aixSanitize"></span>
    @if (msg.closeBehaviour.isClosable) {
    <span class="flash-message__btn" (click)="closeMessage(msg.uid)">
        <span class="fa-regular fa-xmark"></span>
    </span>
    }
</div>
}
