import { JsonDecoder } from 'ts.data.json';
import { accountDecoder } from '../../account/decoder';
import { advisorDecoder } from '../../advisor/decoder';
import { signingStatusDecoder } from '../../docusign/signing-status/decoder';
import { firmDecoder } from '../../firm/decoder';
import { holdingOptionDecoder } from '../../holding-option/decoder';
import { orderApprovalsDecoder, orderCurrentApprovalDecoder } from '../../order-approve/decoder';
import { orderFormDecoder } from '../../order-form/decoder';
import { productDecoder } from '../../product/decoder';
import { profileRepCodeDecoder } from '../../profile/decoder';
import {
    eSignDecoder,
    fileDecoder,
    orderValidationDecoder,
    presignatureReviewDecoder,
    requiredDocument,
    settlementDetailsDecoder
} from '../decoder';
import { MaintenanceOrder } from '../../../models/order/maintenance/model';

export const maintenanceOrderDecoder = JsonDecoder.object<MaintenanceOrder>(
    {
        title: JsonDecoder.optional(JsonDecoder.string).map(title => title || ''),
        id: JsonDecoder.string,
        status: JsonDecoder.string,
        statusLabel: JsonDecoder.string,
        completed: JsonDecoder.number,
        orderNumber: JsonDecoder.number,
        customData: JsonDecoder.succeed,
        orderSpecificData: JsonDecoder.succeed,
        account: JsonDecoder.oneOf(
            [JsonDecoder.nullable(accountDecoder), JsonDecoder.isUndefined(null)],
            'Account'
        ),
        holdingOption: JsonDecoder.optional(holdingOptionDecoder),
        fund: productDecoder,
        advisor: JsonDecoder.optional(advisorDecoder),
        forms: JsonDecoder.dictionary(orderFormDecoder, '{[name: string]: OrderForm}'),
        files: JsonDecoder.array(fileDecoder, 'DocumentFile[]'),
        requiredSupplementalFiles: JsonDecoder.array(requiredDocument, 'RequiredDocument[]'),
        repCode: profileRepCodeDecoder,
        signingStatus: JsonDecoder.optional(signingStatusDecoder),
        validation: JsonDecoder.optional(orderValidationDecoder),
        approvals: JsonDecoder.optional(orderApprovalsDecoder),
        currentApproval: JsonDecoder.optional(orderCurrentApprovalDecoder),
        firm: firmDecoder,
        envelopeId: JsonDecoder.string,
        eSign: JsonDecoder.optional(eSignDecoder),
        wetSign: JsonDecoder.optional(JsonDecoder.boolean),
        tooltip: JsonDecoder.optional(JsonDecoder.string),
        maintenanceChanges: JsonDecoder.succeed,
        createdBy: JsonDecoder.optional(advisorDecoder),
        presignatureReview: JsonDecoder.optional(presignatureReviewDecoder),
        settlementDetails: JsonDecoder.oneOf(
            [JsonDecoder.nullable(settlementDetailsDecoder), JsonDecoder.isUndefined(null)],
            'SettlementDetails'
        )
    },
    'MaintenanceOrder'
);
