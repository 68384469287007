import {
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { DocumentViewerDownloadService, OrderForm } from '@trade-platform/ui-shared';
import { OrderFormCommentsService } from '../order-comments/order-form-comments.helper';
import { AixAbstractUploadComponent } from '../abstract-upload';
import { AixFilelistComponent } from './filelist/filelist';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../base.orders.store.facade';
import { AsyncPipe, NgIf } from '@angular/common';
import { AixUploadDocumentComponent } from '@advisor-ui/app-components';
import { AixLabelUploadedFileDocumentsComponent } from './file-documents/label-uploaded-file-documents';
import { AixLabelUploadedFormDocumentsComponent } from './form-documents/label-uploaded-form-documents';
import {
    AixCheckboxComponent,
    AixDataTestingDirective,
    AixModalComponent
} from '@trade-platform/ui-components';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'aix-file-upload',
    templateUrl: './file-upload.html',
    styleUrls: ['./file-upload.scss'],
    standalone: true,
    imports: [
        NgIf,
        AixDataTestingDirective,
        AixUploadDocumentComponent,
        AixLabelUploadedFileDocumentsComponent,
        AixLabelUploadedFormDocumentsComponent,
        AixFilelistComponent,
        AixModalComponent,
        AsyncPipe,
        AixCheckboxComponent,
        FormsModule
    ]
})
export class AixFileUploadComponent
    extends AixAbstractUploadComponent
    implements OnInit, OnDestroy
{
    @ViewChild('filelistRef') filelistRef: AixFilelistComponent;

    @Input() enableIgnore = false;
    @Input() isReadOnly = false;
    @Input() type = this.fileType.supplemental;
    @Input() instructionalText = '';

    acceptIgnoreRequired = (button: string) =>
        !this.acknowledgeIgnoreRequired && button !== 'Cancel';

    constructor(
        @Inject(ENVIRONMENT) public environment: IEnvironment,
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public docDownloader: DocumentViewerDownloadService,
        public commentsService: OrderFormCommentsService,
        public cd: ChangeDetectorRef
    ) {
        super(environment, storeFacade, docDownloader, commentsService, cd);
    }
}
