import { JsonDecoder } from 'ts.data.json';
import { AixFilterItem } from '@trade-platform/ui-components';

export const filterListItemDecoder = JsonDecoder.object<AixFilterItem>(
    {
        id: JsonDecoder.string,
        label: JsonDecoder.string,
        count: JsonDecoder.optional(JsonDecoder.number),
        index: JsonDecoder.optional(JsonDecoder.number),
        show: JsonDecoder.optional(JsonDecoder.boolean),
        tooltip: JsonDecoder.optional(JsonDecoder.string),
        description: JsonDecoder.optional(JsonDecoder.string),
        tags: JsonDecoder.optional(JsonDecoder.array(JsonDecoder.string, 'string[]'))
    },
    'AixFilterItem'
);

export const filterListDecoder = JsonDecoder.array<AixFilterItem>(
    filterListItemDecoder,
    'AixFilterItem[]'
);
