import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    output
} from '@angular/core';
import { Router } from '@angular/router';
import { fileType } from '@trade-platform/lib-enums';
import { DocumentFile } from '@trade-platform/ui-shared';
import { isInProgress } from 'ngx-remotedata';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AixRequiredFilesMenuOption } from '../filelist';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixTagComponent,
    AixTooltipDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { isDisabledFile } from '../../../utils';
import { AsyncPipe, DatePipe, JsonPipe, NgFor, NgIf } from '@angular/common';
import { AixUploadDocumentComponent } from '@advisor-ui/app-components';
import { AixLabelUploadedFileDocumentsComponent } from '../../file-documents/label-uploaded-file-documents';
import { getLanguage } from '../../../../../../language/language.base';
import { OrderOverview } from '../../../../../../language/language.interface';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '@advisor-ui/orders';

@Component({
    selector: 'aix-filelist-items',
    templateUrl: 'filelist-items.html',
    styleUrls: ['filelist-items.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgFor,
        NgIf,
        AixDataTestingDirective,
        AixTooltipDirective,
        AixButtonComponent,
        AixUploadDocumentComponent,
        AixLabelUploadedFileDocumentsComponent,
        AsyncPipe,
        AixTagComponent,
        JsonPipe,
        DatePipe
    ]
})
export class AixFilelistItemsComponent {
    @Input() files: any[] = [];
    @Input() uploadUrl = '';
    @Input() uploadCompleted = false;
    @Input() enableIgnore = false;
    @Input() isReadOnly = false;
    @Input() fileUploadOpen = false;

    ignoreFile = output<AixRequiredFilesMenuOption>();
    unignoreFile = output<AixRequiredFilesMenuOption>();
    editFile = output();
    removeFile = output<DocumentFile>();

    language: OrderOverview = getLanguage('orderOverview');
    hideFilelistEntityName = !!this.language?.hideFilelistEntityName;
    hideFilelistDocumentOwner = !!this.language?.hideFilelistDocumentOwner;

    supportingDocumentsPage = false;

    isLoading$: Observable<boolean>;

    isClosed = true;

    fileSizeLimit =
        this.environment.fileUploadMaxSize && !isNaN(Number(this.environment.fileUploadMaxSize))
            ? Number(this.environment.fileUploadMaxSize)
            : 25000000;

    readonly reducerSuffix = this.store.type;
    readonly fileType = fileType;

    ignoreButtonType = BUTTON_TYPE.link;
    manageButtonType = BUTTON_TYPE.link;
    viewButtonType = BUTTON_TYPE.link;
    relabelButtonType = BUTTON_TYPE.link;
    removeButtonType = BUTTON_TYPE.link;
    uploadButtonType = BUTTON_TYPE.link;

    constructor(
        @Inject(ENVIRONMENT) private environment: IEnvironment,
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        this.isLoading$ = this.store.orderRemoteData$.pipe(map(isInProgress));

        const url = this.router.url.split('/').pop() || [];
        if (url === 'supporting-documents') {
            this.supportingDocumentsPage = true;
        }
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    checkLineHeight(id: string) {
        const ul = document.getElementById(id);
        if (ul === null) {
            // There is no element with the id, therefore do not need to limit height
            return 0;
        }

        if ((ul.children.item(0) as HTMLElement).scrollHeight > 33) {
            // First bullet point is taller than 32px
            return 32;
        }
        // There is more than one bullet point and total height is greater than 40 (32px of text plus 8px between li elements)

        return ul && ul.scrollHeight > 40 && ul.childElementCount > 1 ? 40 : 0;
    }

    //#region File Events

    replaceEvent(item: any) {
        const file = item.file as DocumentFile;

        item.isOpen = true;
        item.isRelationOpen = false;

        item.replacePayload = this.getReplacePayload(file);
    }

    viewEvent(fileId: string) {
        const order = this.store.order;
        this.router.navigate(this.store.routes.documentViewer(order.id, fileId));
    }

    editEvent(item: any) {
        item.isOpen = false;
        item.isRelationOpen = true;
        this.editFile.emit();
    }

    onCloseReplace(item: any) {
        item.isOpen = false;
        item.replacePayload = null;
    }

    closeRelations(item: any) {
        item.isRelationOpen = false;
        item.isOpen = false;
    }

    onSaveRelations(item: any) {
        item.isRelationOpen = false;
        this.loadOrder();
    }

    isDisabledFile(file: File) {
        return isDisabledFile(file);
    }

    //#endregion

    //#region File Utils

    getReplacePayload(document: DocumentFile) {
        return {
            id: document.id,
            supplementalFileTypeId: document.supplementalFileTypeId,
            contextMap: document.contextMap,
            formId: document.formId && document.formId !== '' ? document.formId : undefined,
            firmId: document.firmId,
            holdingOptionId: document.holdingOptionId,
            fundId: document.fundId
        };
    }

    //#endregion

    //#region Order

    loadOrder() {
        const order = this.store.order;
        if (order) {
            this.store.actions.getOrder.dispatch({
                orderId: order.id,
                reducerSuffix: this.reducerSuffix
            });
        }
    }

    //#endregion
}
