import {
    AfterViewInit,
    Component,
    output,
    QueryList,
    TemplateRef,
    ViewChildren
} from '@angular/core';
import { AixTemplateDirective } from './aix-template.directive';
import { NgClass, NgIf } from '@angular/common';
import { AixDataTestingDirective } from '@trade-platform/ui-components';

export interface AixTemplateConfig {
    title: string;
}

@Component({
    selector: 'aix-templates',
    templateUrl: './aix-templates.component.html',
    standalone: true,
    imports: [AixTemplateDirective, AixDataTestingDirective, NgClass, NgIf]
})
export class AixTemplatesComponent implements AfterViewInit {
    @ViewChildren(AixTemplateDirective) templateRefs: QueryList<AixTemplateDirective>;
    valueChanged = output<any>();
    onInit = output<boolean>();

    _initialized = false;

    get initialized(): boolean {
        return this._initialized;
    }

    constructor() {}

    ngAfterViewInit() {
        this._initialized = true;
        setTimeout(() => this.onInit.emit(this._initialized));
    }

    getTemplate(templateName: string): TemplateRef<any> {
        return (
            this.templateRefs
                .toArray()
                .find(
                    (t: AixTemplateDirective) => t.name.toLowerCase() === templateName.toLowerCase()
                ) as AixTemplateDirective
        ).template;
    }
}
