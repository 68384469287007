import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    OrderFormComment,
    OrderFormCommentAction,
    OrderFormCommentActionType,
    OrderFormCommentTagInfo,
    OrderFormCommentTagsInfo
} from '@trade-platform/ui-shared';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixExpansionPanelComponent,
    AixModalComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { Subscription } from 'rxjs';
import { commentTag as commentTagTypes, commentType } from '@trade-platform/lib-enums';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { FormatPipeModule } from 'ngx-date-fns';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { AixOrderFormCommentTagsComponent } from '../order-form-comment-tags/order-form-comment-tags';
import { AixOrderFormCommentsListComponent } from '../order-form-comments-list/order-form-comments-list';

@Component({
    selector: 'aix-order-form-comment',
    templateUrl: 'order-form-comment.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixModalComponent,
        AixDataTestingDirective,
        NgClass,
        NgIf,
        FormsModule,
        AixOrderFormCommentTagsComponent,
        AixButtonComponent,
        AixOrderFormCommentsListComponent,
        AixExpansionPanelComponent,
        FormatPipeModule
    ]
})
export class AixOrderFormCommentComponent implements OnInit, OnChanges, OnDestroy {
    @Input() comment: OrderFormComment;
    @Input() canReply = false;

    onCommentAction = output<OrderFormCommentAction>();

    @ViewChild('deleteModal', { static: true })
    deleteModal: AixModalComponent;

    editModeActive = false;
    replyModeActive = false;
    commentToDelete: OrderFormComment | null = null;

    commentTypes = commentType;

    subscriptions: Subscription[] = [];

    commentReplyLabel = '';
    commentReplyText = '';
    commentChildrenHeaderClass = {
        'order-form-comments__no-border': true,
        'order-form-comments__header': true,
        'u-mt16': true,
        'u-fw600': true,
        'br-primary__color': true
    };
    commentChildrenBodyClass = {
        'order-form-comments__no-border': true
    };
    commentChildrenArrowClass = {
        'br-primary__color': true
    };

    commentText = '';
    commentDate?: Date | null = null;
    commentTag?: string | null = null;
    commentTagTypes = commentTagTypes;
    commentTagInfo: OrderFormCommentTagInfo;
    readonly commentTagsInfo: OrderFormCommentTagsInfo = {
        [commentTagTypes.change]: {
            class: 'warn',
            label: 'Needs Changes'
        },
        [commentTagTypes.clarification]: {
            class: 'alert',
            label: 'Needs Clarification'
        },
        [commentTagTypes.information]: {
            class: 'note',
            label: 'Note'
        }
    };

    isPrivate = false;
    isCommentChildrenClosed = false;

    cancelButtonType = BUTTON_TYPE.link;
    saveButtonType = BUTTON_TYPE.primary;
    replyPrimaryButtonType = BUTTON_TYPE.primary;
    replySecondaryButtonType = BUTTON_TYPE.secondary;

    constructor(
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        private ref: ChangeDetectorRef
    ) {}

    ngOnInit() {
        const file = this.store.order.files.find(f => f.id === this.comment.documentId);
        this.isPrivate = file?.isPrivate === true;

        this.subscriptions.push(
            this.store.actions.updateFormComment.success$.subscribe(action => {
                const updatedComment = action.payload;
                if (updatedComment && updatedComment.id === this.comment.id) {
                    this.editModeActive = false;
                    this.comment.updatedAt = updatedComment.updatedAt;
                }
            }),
            this.store.actions.createFormComment.success$.subscribe(action => {
                const createdComment = action.payload;
                if (createdComment && createdComment.parentId === this.comment.id) {
                    this.replyModeActive = false;
                    this.commentReplyText = '';
                }
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.comment && changes.comment.currentValue) {
            if (
                this.comment &&
                this.comment.commentTag &&
                Object.keys(this.commentTagsInfo).includes(this.comment.commentTag)
            ) {
                this.commentDate = this.comment.updatedAt
                    ? new Date(this.comment.updatedAt)
                    : undefined;
                this.commentTag = this.comment.commentTag;
                this.commentTagInfo = this.commentTagsInfo[this.comment.commentTag];
            }

            this.commentText = this.comment.text;
            this.commentReplyLabel = this.getCommentReplyLabel(this.comment);

            const file = this.store.order.files.find(f => f.id === this.comment.documentId);
            this.isPrivate = file?.isPrivate === true;
        }
    }

    editComment() {
        this.editModeActive = true;
    }

    onCancelEdit() {
        this.commentText = this.comment.text;
        this.commentTag = this.comment.commentTag;
        this.editModeActive = false;
    }

    onCancelReply() {
        this.commentReplyText = '';
        this.replyModeActive = false;
    }

    onClickDelete(comment: OrderFormComment) {
        this.commentToDelete = comment;
        this.deleteModal.openModal();
        this.ref.detectChanges();
    }

    onClickSave(comment: OrderFormComment) {
        comment.text = this.commentText;
        comment.commentTag = this.commentTag as string;
        this.transmitCommentAction('save', comment);
    }

    onClickReply() {
        if (this.replyModeActive) {
            this.store.actions.createFormComment.dispatch({
                parentId: this.comment.id,
                commentType: this.comment.commentType,
                commentTag: this.comment.commentTag,
                text: this.commentReplyText.trim(),
                documentName: this.comment.documentName,
                data: {
                    orderId: this.comment.orderId,
                    documentId: this.comment.documentId
                }
            });
        }
        this.replyModeActive = true;
    }

    onClosedChange(isClosed: boolean) {
        this.isCommentChildrenClosed = isClosed;
    }

    modalSelected(e: string) {
        switch (e) {
            case 'Yes, continue':
                this.transmitCommentAction('delete', this.commentToDelete as OrderFormComment);
                this.commentToDelete = null;
                break;
        }
    }

    transmitCommentAction(action: OrderFormCommentActionType, comment: OrderFormComment) {
        this.onCommentAction.emit({ type: action, comment: comment });
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }

    private getCommentReplyLabel(comment: OrderFormComment): string {
        if (comment.children) {
            const childrenCount = comment.children.length;
            const replyLabel = comment.children.length > 1 ? 'replies' : 'reply';
            return `${childrenCount} ${replyLabel}`;
        }
        return '';
    }
}
