import { Component, input, model, output } from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgFor, NgIf } from '@angular/common';
import { AixTooltipDirective } from '../../directives/tooltip/aix-tooltip';

export interface AixFilterItem {
    description?: string;
    id: string;
    label: string;
    count?: number;
    index?: number;
    tags?: string[];
    show?: boolean;
    tooltip?: string;
}

@Component({
    selector: 'aix-filter-list',
    templateUrl: './aix-filter-list.html',
    styleUrls: ['./aix-filter-list.scss'],
    standalone: true,
    imports: [NgFor, NgIf, AixDataTestingDirective, AixTooltipDirective]
})
export class AixFilterListComponent {
    filters = input<AixFilterItem[]>();
    activeFilter = model<AixFilterItem | AixFilterItem[]>();
    showDivider = input(false);
    showCheckbox = input(false);
    onSelectFilter = output<AixFilterItem | AixFilterItem[] | undefined>();

    constructor() {}

    selectFilter(filter: AixFilterItem) {
        if (Array.isArray(this.activeFilter())) {
            if ((this.activeFilter() as AixFilterItem[]).some(active => active.id === filter.id)) {
                this.activeFilter.set(
                    (this.activeFilter() as AixFilterItem[]).filter(
                        active => active.id !== filter.id
                    )
                );
            } else {
                (this.activeFilter() as AixFilterItem[]).push(filter);
            }
        } else {
            this.activeFilter.set(filter);
        }

        this.onSelectFilter.emit(this.activeFilter());
    }

    isActiveFilter(id: string | undefined) {
        if (this.activeFilter() == null || id == null) {
            return false;
        }

        if (Array.isArray(this.activeFilter())) {
            return (this.activeFilter() as AixFilterItem[]).reduce((acc, item) => {
                return acc || id === item.id;
            }, false);
        } else {
            return id === (this.activeFilter() as AixFilterItem)?.id;
        }
    }
}
