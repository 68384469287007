import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    output,
    Output,
    ViewChild
} from '@angular/core';
import {
    BaseOrder,
    DocumentFile,
    OrderForm,
    OrderStep,
    removeFileExtension
} from '@trade-platform/ui-shared';
import { Subscription } from 'rxjs';
import { getUploadedFormFiles, hasMaxFormUploads } from '../../process/overview/utils/order-utils';
import { Router } from '@angular/router';
import { fileType } from '@trade-platform/lib-enums';
import { ENVIRONMENT, IEnvironment, objectHasValue } from '@trade-platform/ui-utils';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixInlineDropdownComponent,
    AixLoadingButtonComponent,
    AixTagComponent,
    AixTooltipDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { isDisabledFile } from '../../utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../base.orders.store.facade';
import { OrderOverview } from 'libs/advisor-ui/language/language.interface';
import { getLanguage } from 'libs/advisor-ui/language/language.base';
import { RemoteDataModule } from 'ngx-remotedata';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import { AixUploadDocumentComponent } from '@advisor-ui/app-components';
import { AixFileUploadComponent } from '../../file-upload/file-upload';
import { AixLabelUploadedFormDocumentsComponent } from '../../file-upload/form-documents/label-uploaded-form-documents';

export interface AixUploadedFormFilesMenuOption {
    file: DocumentFile | null;
    form: OrderForm | null;
    isOpen: boolean;
    isUploading: boolean;
    isUploadCompleted: boolean;
    showAsSpan?: boolean;
    showAsLink?: boolean;
}

@Component({
    imports: [
        NgIf,
        AixDataTestingDirective,
        NgFor,
        AixTagComponent,
        AixTooltipDirective,
        AixLoadingButtonComponent,
        NgClass,
        AixButtonComponent,
        AixInlineDropdownComponent,
        AixUploadDocumentComponent,
        AsyncPipe,
        RemoteDataModule,
        AixLabelUploadedFormDocumentsComponent,
        AixFileUploadComponent
    ],
    selector: 'aix-form-filelist',
    standalone: true,
    styleUrls: ['./form-filelist.scss'],
    templateUrl: './form-filelist.html'
})
export class AixFormFilelistComponent implements OnInit, OnDestroy {
    @ViewChild('aixFileUpload') aixFileUpload: AixFileUploadComponent;

    @Input()
    acceptedFormIds: string[] = [];
    @Input()
    isReadOnly = false;

    onDownload = output<DocumentFile>();
    onReplace = output<DocumentFile>();
    onRemove = output<DocumentFile>();
    onUpload = output<never>();
    onEdit = output<never>();

    language: OrderOverview = getLanguage('orderOverview');
    hideFormDocumentOwner = !!this.language?.hideFormDocumentOwner;

    subscriptions: Subscription[] = [];
    arrayFiles: AixUploadedFormFilesMenuOption[] = [];
    relabelForm: OrderForm | null = null;
    hasMaxFormUploads = false;
    currentStep: OrderStep;

    uploadUrl: string;

    fileSizeLimit =
        this.environment.fileUploadMaxSize && !isNaN(Number(this.environment.fileUploadMaxSize))
            ? Number(this.environment.fileUploadMaxSize)
            : 25000000;

    readonly removeFileExtension = removeFileExtension;
    readonly notOnboardedTooltipText = 'Please upload the completed and signed application.';
    readonly noSignatures = 'This document does not require signatures.';
    readonly reducerSuffix = this.store.type;
    readonly fileType = fileType;

    uploadButtonType = BUTTON_TYPE.link;

    constructor(
        @Inject(ENVIRONMENT) private environment: IEnvironment,
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.orderSuccess$.subscribe(order => {
                this.uploadUrl = `${this.store.apiUrl}/${order.id}/files`;
                this.updateArrays(order);
                this.cd.detectChanges();
            }),
            this.store.documentViewer$.subscribe(() => this.cd.detectChanges()),
            this.store.orderSteps$.subscribe((step: { currentStep: OrderStep }) => {
                this.currentStep = step.currentStep;
                this.cd.detectChanges();
            })
        );

        if (this.store.order) {
            this.updateArrays(this.store.order);
        }
    }

    loadOrder() {
        const order = this.store.order;
        if (order) {
            this.store.actions.getOrder.dispatch({
                orderId: order.id,
                reducerSuffix: this.reducerSuffix
            });
        }
    }

    updateArrays(order: BaseOrder) {
        this.arrayFiles = getUploadedFormFiles(order)
            .filter(item => {
                if (this.acceptedFormIds.length > 0) {
                    return (
                        (objectHasValue(item.form) &&
                            this.acceptedFormIds.indexOf(item.form.id) > -1) ||
                        (objectHasValue(item.file) &&
                            item.file.type === fileType.unassignedForm &&
                            item.file.orderStatus === order.status)
                    );
                } else {
                    // Hide only onboarded forms with no signatures
                    return !(item.form && item.form.isOnboarded && item.form.noSignatures);
                }
            })
            .map(item => {
                return {
                    ...item,
                    showAsLink:
                        (this.isFileUploaded(item) && !item.isUploading) ||
                        (item?.form?.isOnboarded && item.form.noSignatures),
                    showAsSpan:
                        !this.isFileUploaded(item) &&
                        (!item?.form?.isOnboarded ||
                            (item.form.isOnboarded && !item.form.noSignatures))
                };
            });
        this.hasMaxFormUploads = hasMaxFormUploads(order, this.acceptedFormIds);
    }

    onOptionSelected(evt: string, item: AixUploadedFormFilesMenuOption) {
        switch (evt) {
            case 'View':
                this.viewEvent(item);
                break;

            case 'Relabel':
                this.relabelForm = item.form;
                break;

            case 'Replace':
                item.isOpen = true;
                this.onReplace.emit(item.file as DocumentFile);
                break;

            case 'Remove':
                this.onRemove.emit(item.file as DocumentFile);
                break;
        }
    }

    viewEvent(item: AixUploadedFormFilesMenuOption) {
        if (!this.isFormNotOnboarded(item)) {
            const order = this.store.order;
            this.store.actions.documentViewerShowModal.dispatch({ showModal: false });
            this.router.navigate(
                this.store.routes.documentViewer(order.id, (item.file as DocumentFile).id)
            );
        }
    }

    uploadEvent(menuOption: AixUploadedFormFilesMenuOption) {
        menuOption.isOpen = true;
    }

    onFilesSelected(item: AixUploadedFormFilesMenuOption) {
        item.isUploading = true;
        item.isUploadCompleted = false;
    }

    onFilesUploaded(item: AixUploadedFormFilesMenuOption) {
        item.isUploading = false;
        item.isUploadCompleted = true;
        this.loadOrder();
    }

    isFileUploaded(item: AixUploadedFormFilesMenuOption): boolean {
        if (item.file === null) {
            return false;
        }

        const order = this.store.order;

        const alreadyUploaded: boolean =
            objectHasValue(order) &&
            objectHasValue(item.file) &&
            objectHasValue(order.approvals) &&
            !!item.file.isUserUploaded &&
            (order.approvals.length > 0
                ? objectHasValue(item.file.config) &&
                  item.file.config.id === order.approvals[0].config.id
                : true);

        return alreadyUploaded && (item.file as DocumentFile).orderStatus === order?.status;
    }

    isFormNotOnboarded(item: AixUploadedFormFilesMenuOption): boolean {
        return objectHasValue(item.form) && item.form.isOnboarded === false && item.file === null;
    }

    isFileNotOnboarded(item: AixUploadedFormFilesMenuOption): boolean {
        return objectHasValue(item.form) && item.form.isOnboarded === false && item.file !== null;
    }

    onClickDownload(item: AixUploadedFormFilesMenuOption) {
        this.onDownload.emit(item.file as DocumentFile);
    }

    isDisabledFile(file: File | DocumentFile | null) {
        const disabledOptions = [];

        if (file && isDisabledFile(file)) {
            disabledOptions.push('View');
        }

        if (this.isReadOnly) {
            disabledOptions.push('Remove');
        }

        return disabledOptions;
    }

    showRelabelDialog(item: OrderForm) {
        return item.isOnboarded == false && item.isRequired === false && item === this.relabelForm;
    }

    showMissingLabel(item: OrderForm) {
        return (
            item.isOnboarded === false &&
            item.isRequired === false &&
            !item.documentOwner &&
            !this.showRelabelDialog(item) &&
            !this.aixFileUpload?.fileUploadOpen
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
