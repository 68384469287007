@for (item of files; track item.id; let index = $index) {
<div class="u-pb16 flex-col" [attr.data-testing]="item.type + '-files-' + item.file.id">
    <!-- Required -->
    @if (item.type === 'required') {
    <div class="order-step-form-row item-header" [data-testing]="'filelist-required-files'">
        <div class="order-step-form-row-container u-mr16">
            <div>
                <p class="order-step-form-row-title item-title" [data-testing]="'title'">
                    @if (item.name) {
                    <span>{{ item.name }}</span>
                    } @if (item.entityName && !hideFilelistEntityName) {
                    <span> - {{ item.entityName }}</span>
                    } @if (item.tooltip) {
                    <span aixTooltip [aixTooltipContent]="item.tooltip"></span>
                    }
                </p>
                @if ((store.documentTypesSuccess$ | async)?.length && !hideFilelistDocumentOwner) {
                <p class="order-step-form-row-subtitle" [data-testing]="'subtitle'">
                    {{ item.documentOwner === 'Alternative Investment Exchange' ? 'Your Firm' :
                    item.documentOwner }}
                </p>
                }
            </div>
        </div>
        <div
            class="order-step-form-row__actions"
            [data-testing]="'actions'"
            style="padding-top: 2px"
        >
            @if (!item.file.dismissedDate) {
            <aix-button
                style="padding-top: 2px"
                [data-testing]="'upload-button'"
                (click)="item.isOpen = true"
                [isDisabled]="item.isOpen || isReadOnly"
                class="order-step-action-items__menu-button u-ml24"
                [buttonType]="uploadButtonType"
                [buttonLabel]="'Upload'"
                [icon]="'fa-upload u-mr4'"
            >
            </aix-button>
            } @else {
            <aix-button
                style="padding-top: 2px"
                [data-testing]="'unignore-button'"
                (click)="unignoreFile.emit(item)"
                class="order-step-action-items__menu-button u-ml24"
                [buttonType]="uploadButtonType"
                [buttonLabel]="'Unignore'"
                [icon]="'fa-file-minus u-mr4'"
            >
            </aix-button>
            }
        </div>
    </div>

    @if (item.isOpen) {
    <div class="file-upload-container">
        <div class="flex-between-center">
            <span class="u-font-size--16 u-fw500">Upload</span>
            <span
                class="fa-regular fa-xmark u-color-tertiary-black"
                (click)="item.isOpen = false"
            ></span>
        </div>
        <div class="u-mt16">
            <aix-upload-document
                [uploadUrl]="uploadUrl"
                [type]="fileType.supplemental"
                [fileId]="item.id"
                [contextMap]="item.contextMap"
                [isModal]="true"
                [fileSizeLimit]="fileSizeLimit"
                (filesUploaded)="loadOrder()"
                [firmId]="item.firmId"
                [fundId]="item.fundId"
                [holdingOptionId]="item.holdingOptionId"
            >
            </aix-upload-document>
        </div>
    </div>
    } }

    <!-- Uploaded -->
    @if (item.type === 'uploaded') { @if (item.documentOwner || (!item.documentOwner &&
    !uploadCompleted)) {
    <div class="order-step-form-row item-header" [data-testing]="'filelist-supplemental-files'">
        <div class="order-step-form-row-container">
            <div>
                @if (!item.entityName || hideFilelistEntityName) {
                <p class="order-step-form-row-title item-title" [data-testing]="'title'">
                    {{ item.name }} @if (item.file.isPrivate) {
                    <span
                        aixTooltip
                        [aixTooltipContent]="'Private documents are only visible to members within your organization with the same Reviewer / Compliance role.'"
                        [aixIcon]="'fa-lock'"
                        [data-testing]="'isPrivateFile'"
                    ></span>
                    }
                </p>
                } @if (item.entityName && !hideFilelistEntityName) {
                <p class="order-step-form-row-title item-title" [data-testing]="'title'">
                    {{ item.name }} - {{ item.entityName }} @if (item.file.isPrivate) {
                    <span
                        aixTooltip
                        [aixTooltipContent]="'Private documents are only visible to members within your organization with the same Reviewer / Compliance role.'"
                        [aixIcon]="'fa-lock'"
                        [data-testing]="'isPrivateFile'"
                    ></span>
                    }
                </p>
                } @if ((store.documentTypesSuccess$ | async)?.length && !hideFilelistDocumentOwner)
                {
                <p class="order-step-form-row-subtitle" [data-testing]="'subtitle'">
                    {{ item.documentOwner === 'Alternative Investment Exchange' ? 'Your Firm' :
                    item.documentOwner }}
                </p>
                }
            </div>
        </div>
        <div class="order-step-form-row__actions">
            <div class="order-step-action-items order-step-action-items__remove">
                @if (!item.isOpen || item.replacePayload) {
                <aix-button
                    style="padding-top: 2px"
                    class="order-step-action-items__menu-button"
                    [data-testing]="'manageButton' + index"
                    [icon]="'fa-ellipsis-v'"
                    [buttonLabel]="'Manage'"
                    [buttonType]="manageButtonType"
                >
                </aix-button>
                } @if (item.unresolvedFileComments.length > 0) {
                <span class="u-warning-orange u-pb6 u-pt2" (click)="replaceEvent(item)">
                    <i
                        class="fa-regular fa-triangle-exclamation u-ml16"
                        title="Resolve NIGO comments"
                    ></i>
                    {{ item.commentsLabel }}
                </span>
                }
                <ul class="order-step-action-items-menu aix-flex-grid aix-flex-grid__col">
                    <li>
                        <aix-button
                            (click)="viewEvent(item.file.id)"
                            [buttonType]="viewButtonType"
                            [buttonLabel]="'View'"
                            [isDisabled]="isDisabledFile(item.file)"
                        >
                        </aix-button>
                    </li>
                    <li>
                        <aix-button
                            [data-testing]="'relabel-button'"
                            (click)="editEvent(item)"
                            [isDisabled]="isReadOnly"
                            [buttonLabel]="'Relabel'"
                            [buttonType]="relabelButtonType"
                        >
                        </aix-button>
                    </li>
                    <li>
                        <aix-button
                            (click)="removeFile.emit(item.file)"
                            [isDisabled]="isReadOnly"
                            [buttonType]="removeButtonType"
                            [buttonLabel]="'Remove'"
                        >
                        </aix-button>
                    </li>
                </ul>
            </div>
        </div>
    </div>
    } @if (!item.replacePayload && item.isOpen) {
    <div class="file-upload-container">
        <div class="flex-between-center">
            <span class="u-font-size--16 u-fw500">Upload</span>
            <span
                class="fa-regular fa-xmark u-color-tertiary-black"
                (click)="item.isOpen = false"
            ></span>
        </div>
        <div class="u-mt16">
            <aix-upload-document
                [uploadUrl]="uploadUrl"
                [type]="fileType.supplemental"
                [multiple]="false"
                [isModal]="true"
                [replacePayload]="getReplacePayload(item.file)"
                [fileSizeLimit]="fileSizeLimit"
                (filesUploaded)="loadOrder()"
            >
            </aix-upload-document>
        </div>
    </div>
    } @if (!!item.replacePayload && item.isOpen) {
    <div class="file-upload-container">
        @if (!uploadCompleted) {
        <div>
            @for (comment of item.unresolvedFileComments; track comment) {
            <p class="order-detail-modal-comments u-mb16" class="order-form-comments__comment-text">
                {{ comment.text }}
            </p>
            }
            <div class="u-mb16 u-mt16">
                @if (item.unresolvedFileComments.length > 0) {
                <p>{{ item.commentsText }}</p>
                }
            </div>
            <div class="flex-between-center u-mb16">
                <span class="u-font-size--16 u-fw500">Resolve Document Comments</span>
                <span
                    class="fa-regular fa-xmark u-color-tertiary-black"
                    (click)="onCloseReplace(item)"
                ></span>
            </div>
            <aix-upload-document
                [uploadUrl]="uploadUrl"
                [type]="fileType.supplemental"
                [multiple]="true"
                [isModal]="true"
                [replacePayload]="item.replacePayload"
                [fileSizeLimit]="fileSizeLimit"
                (filesUploaded)="loadOrder()"
            >
            </aix-upload-document>
        </div>
        }
    </div>
    } @if (item.isRelationOpen) {
    <div class="file-upload-container">
        <div class="flex-between-center">
            <span class="u-font-size--16 u-fw500">Label</span>
            <span
                class="fa-regular fa-xmark u-color-tertiary-black"
                (click)="closeRelations(item)"
            ></span>
        </div>
        <aix-label-uploaded-file-documents
            class="aix-form__container u-block"
            [filterBy]="item.file.id"
            [documentTypes]="(store.documentTypesSuccess$ | async)"
            (filesUpdated)="onSaveRelations(item)"
            (dismissModal)="closeRelations(item)"
        >
        </aix-label-uploaded-file-documents>
    </div>
    } }

    <!-- full template after uploaded or for upload -->
    <div class="flex-left-center u-mb8" [class.u-mt16]="!item.documentOwner && uploadCompleted">
        @if (item.type === 'uploaded') { @if (item.documentOwner) {<aix-tag
            [color]="'success'"
            [icon]="'far fa-check'"
            [label]="'Uploaded'"
            [labelColor]="'#186860'"
        ></aix-tag
        >} @else if (!item.documentOwner && !item.isRelationOpen && !fileUploadOpen) {
        <aix-tag
            [color]="'warn'"
            [icon]="'far fa-exclamation-triangle'"
            [label]="'Document Missing Label'"
            [labelColor]="'#263238'"
        ></aix-tag>
        } @if (item.file.originalUploadedAt) {
        <span class="tag-description"
            >{{ item.file.originalUploadedAt | date:'MM/dd/yyyy hh:mm a' }}</span
        >
        } @else {
        <span class="tag-description">{{ item.file.createdAt | date:'MM/dd/yyyy hh:mm a' }}</span>
        }
        <span class="tag-description">-</span>
        <span class="tag-description">{{ item.file.name }}</span>
        } @else if (item.file.dismissedDate) {
        <aix-tag
            [color]="'error'"
            [icon]="'far fa-exclamation-triangle'"
            [label]="'Ignored'"
        ></aix-tag>
        <span class="tag-description"
            >{{ item.file.dismissedDate | date:'MM/dd/yyyy hh:mm a' }}</span
        >
        } @else {
        <aix-tag
            [color]="'warn'"
            [icon]="'far fa-exclamation-triangle'"
            [label]="'Not Uploaded'"
        ></aix-tag>
        }
    </div>

    @if (item.type === 'required' || item.firmId || item.fundId || item.holdingOptionId) { @if
    (item.file?.descriptionHeader || item.file?.descriptionBullets?.length > 0 ||
    item.file?.descriptionFooter) {
    <div>
        <h6 class="u-mt8">Description</h6>
        <p class="description-header" [innerHTML]="item.file?.descriptionHeader"></p>
        <div
            [class.show-closed-small]="checkLineHeight(item.file.id) === 32"
            [class.show-closed-large]="checkLineHeight(item.file.id) === 40"
            [class.show-closed-ignore]="
                        !item.isDescriptionOpen
                        && enableIgnore
                        && checkLineHeight(item.file.id)
                        && !item.file.dismissedDate && item.type !== 'uploaded'"
            [class.show-open]="item.isDescriptionOpen"
        >
            <ul class="description-list" [attr.id]="item.file.id">
                @for (description of item.file?.descriptionBullets; track description) {
                <li
                    class="bullet-description"
                    [data-testing]="'bullet-description-' + item.file.id"
                    [class.bullet-description__closed]="!item.isDescriptionOpen"
                    [innerHTML]="description"
                ></li>
                }
            </ul>
            <p class="order-step-form-row-title" [innerHTML]="item.file?.descriptionFooter"></p>
        </div>
    </div>
    } @if (enableIgnore && !item.file.dismissedDate && item.type !== 'uploaded' &&
    item.isDescriptionOpen) {
    <div>
        <aix-button
            [data-testing]="'ignore-button'"
            (click)="ignoreFile.emit(item)"
            [isDisabled]="isReadOnly"
            [buttonType]="ignoreButtonType"
            [buttonLabel]="'Ignore'"
            class="u-mb8 u-mt8 order-step-action-items__menu-button"
            [icon]="'fa-file-minus u-mr4'"
        >
        </aix-button>
    </div>
    } } @if (checkLineHeight(item.file.id) || item.type !== 'uploaded') {
    <div>
        @if ((checkLineHeight(item.file.id) || !item.file.dismissedDate) && !item.isDescriptionOpen
        ) {
        <a
            (click)="item.isDescriptionOpen = true"
            class="aix-btn aix-btn--link br-link u-pb0"
            [class.u-pt0]="checkLineHeight(item.file.id) === 0"
            [data-testing]="'show-more-button'"
            >Show More...</a
        >
        } @else if ((checkLineHeight(item.file.id) || !item.file.dismissedDate) &&
        item.isDescriptionOpen ) {
        <a
            (click)="item.isDescriptionOpen = false"
            class="aix-btn aix-btn--link br-link u-pb0"
            [data-testing]="'show-more-button'"
            >Show Less...</a
        >
        }
    </div>
    }
</div>
}
