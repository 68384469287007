import {
    Component,
    HostBinding,
    input,
    model,
    OnChanges,
    OnInit,
    output,
    SimpleChanges,
    ViewEncapsulation
} from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'aix-notification',
    styleUrls: ['aix-notification.scss'],
    template: `
        <div
            class="aix-notification aix-notification-closed flex-between-top"
            [ngClass]="cssClasses"
        >
            <div class="aix-notification-message" data-testing="notification-message">
                <ng-content></ng-content>
            </div>
            @if (canClose()) {
                <span
                    class="aix-notification-close-button fa-regular fa-xmark"
                    (click)="closeNotification()"
                ></span>
            }
        </div>
    `,
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgClass, AixDataTestingDirective, NgIf]
})
export class AixNotificationComponent implements OnInit, OnChanges {
    static HOST_CLASS = 'aix-flex-grid u-full-width';
    @HostBinding('class')
    classNames = AixNotificationComponent.HOST_CLASS;

    status = input<string>();
    canClose = input(true);
    classList = input<string[]>([]);
    isOpen = model(false);

    onOpenNotification = output();
    onCloseNotification = output();

    cssClasses: Record<string, boolean> = {};

    ngOnInit() {
        this.cssClasses['aix-notification-' + this.status()] = true;

        this.classList().forEach(className => {
            this.cssClasses[className] = true;
        });

        if (this.isOpen()) {
            this.openNotification();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        // Activate new notification status style and remove existing one;
        if (changes.status && changes.status.currentValue) {
            this.cssClasses['aix-notification-' + this.status()] = true;

            if (changes.status.previousValue) {
                this.cssClasses['aix-notification-' + changes.status.previousValue] = false;
            }
        }
    }

    public openNotification() {
        this.isOpen.set(true);
        this.cssClasses['aix-notification-closed'] = false;
        this.onOpenNotification?.emit();
    }

    public closeNotification() {
        this.isOpen.set(false);
        this.cssClasses['aix-notification-closed'] = true;
        !this.onCloseNotification['destroyed'] ? this.onCloseNotification?.emit() : null;
    }
}
