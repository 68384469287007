import {
    Component,
    effect,
    ElementRef,
    forwardRef,
    HostBinding,
    input,
    model,
    OnInit,
    output,
    viewChild
} from '@angular/core';
import {
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    UntypedFormControl,
    ValidationErrors,
    Validator
} from '@angular/forms';
import {
    createSeparatorMask,
    handleCaret,
    isSafari,
    unMaskSeparatorValue,
    validateZipFactory
} from '@trade-platform/ui-utils';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-zip',
    styleUrls: ['./aix-zip.component.scss'],
    templateUrl: './aix-zip.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AixZipComponent)
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: AixZipComponent
        }
    ],
    standalone: true,
    imports: [AixDataTestingDirective]
})
export class AixZipComponent implements ControlValueAccessor, Validator, OnInit {
    _propagateChanges: (value: string) => void = () => ({});
    _propagateTouches: () => void = () => ({});

    config = input<any>();
    isStandalone = input(true);
    isRequired = input(false);
    isDisabled = input(false);
    isValid = input(false);
    isDirty = model(false);
    initialValue = model<string>();
    hint = input<string>('');

    valueChanges = output<string>();
    setDirty = output();

    inputField = viewChild<ElementRef<HTMLInputElement>>('inputField');

    @HostBinding('attr.aix-control')
    aixControl: string;

    private readonly MAX_LENGTH = 9;
    private caretPositionStart: number;
    private caretPositionEnd: number;
    private keyDownEvent: KeyboardEvent;
    private mask = createSeparatorMask([], this.MAX_LENGTH, '-');

    constructor() {
        effect(() => {
            if (this.initialValue()) {
                this.valueToSend(this.initialValue() || '');
            }
        });
    }

    onKeyDown(e: KeyboardEvent & { target: any }) {
        this.caretPositionStart = e.target.selectionStart;
        this.caretPositionEnd = e.target.selectionEnd;
        this.keyDownEvent = e;
    }

    ngOnInit() {
        return 0;
    }

    valueToSend(rawValue: string) {
        const unmaskedValue = unMaskSeparatorValue(rawValue ?? null, this.MAX_LENGTH);
        const dashPositions = unmaskedValue && unmaskedValue.length > 5 ? [5] : [];

        this.mask = createSeparatorMask(dashPositions, this.MAX_LENGTH, '-');
        (<ElementRef>this.inputField()).nativeElement.value = this.mask(unmaskedValue as string);

        if (isSafari) {
            handleCaret(
                dashPositions,
                this.keyDownEvent,
                (<ElementRef>this.inputField()).nativeElement as HTMLInputElement,
                this.caretPositionStart,
                this.caretPositionEnd
            );
        }

        const val = unmaskedValue && unmaskedValue.length ? unmaskedValue : '';
        this.valueChanges.emit(val);
        return val;
    }

    onUserInput(evt: Event | ClipboardEvent) {
        const val = this.valueToSend((evt.target as HTMLInputElement).value);
        if (!this.isDirty()) {
            this.setDirty.emit();

            // It's standalone, we set the dirty state here instead through the store
            if (this.isStandalone()) {
                this.isDirty.set(true);
            }
        }
        this._propagateChanges(val);
    }

    validate(control: UntypedFormControl) {
        const value: any = control.value;
        const validationResult: ValidationErrors | null = validateZipFactory()(control);
        return !value || !validationResult ? null : { invalid: true };
    }

    writeValue(value: any) {
        this.valueToSend(value);
    }

    registerOnChange(fn: (value: string) => void) {
        this._propagateChanges = fn;
    }

    registerOnTouched(fn: () => void) {
        this._propagateTouches = fn;
    }
}
