import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    AixDataTestingDirective,
    AixImageViewerComponent,
    AixLoadingComponent,
    AixNotificationComponent,
    AixPdfViewerComponent
} from '@trade-platform/ui-components';
import { Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as PDFJS from 'pdfjs-dist';
import { extractStreamValue, getFromStorage, pdfName } from '@trade-platform/ui-utils';
import { AppState } from '../../store/reducers';
import { Auth } from '../../models/auth/model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AixEffectActions } from '../../store/utils/aix-dispatcher.service';
import { DocumentViewerSetInitialized } from '../../store/document-viewer/actions';
import { AsyncPipe, NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'aix-ui-document-viewer',
    templateUrl: './document-viewer.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixDataTestingDirective,
        NgStyle,
        NgIf,
        AixLoadingComponent,
        AixPdfViewerComponent,
        AixImageViewerComponent,
        AixNotificationComponent,
        NgClass,
        AsyncPipe
    ]
})
export class AixUIDocumentViewerComponent implements OnChanges, OnInit, OnDestroy {
    @Input() url: string;
    @Input() documentCount = 0;
    @Input() currentDocument = 0;
    onNext = output<null>();
    onPrev = output<null>();
    onError = output<any>();

    @ViewChild(AixPdfViewerComponent)
    pdf: AixPdfViewerComponent;

    @ViewChild(AixImageViewerComponent)
    image: AixImageViewerComponent;

    authToken: string;

    PDFJSloaded = false;
    currentPage = 0;

    isPdf: boolean | undefined = false;
    isSupportedFile: boolean | undefined = true;

    loadingFile$ = new Subject<boolean>();
    pdfLoaded = false;
    pdfLoadError = false;
    pdfFullscreen = false;
    pdfName: (name: string) => string;
    pdfSource: any;

    imageDataUrl: SafeUrl;

    productId: number;
    orderId: string;
    formId: string;
    materialId: string;

    subscriptions: Subscription[] = [];

    readonly staticStyles = {
        pdfFullScreenStyle: {
            position: 'absolute',
            left: '0',
            width: '100%',
            'z-index': '1002'
        },
        pdfInnerFullscreenStyle: {
            width: '100%'
        }
    };

    styles = {
        pdfWrapper: this.staticStyles.pdfInnerFullscreenStyle
    };

    constructor(
        private ref: ChangeDetectorRef,
        public route: ActivatedRoute,
        public store: Store<AppState>,
        public actions$: AixEffectActions,
        private domSanitizer: DomSanitizer
    ) {
        this.authToken = 'Bearer ' + (getFromStorage('auth') as Auth).accessToken;

        this.pdfName = pdfName;

        Promise.all([
            // @ts-ignore: only accepted in commonJs o esNext
            import(/* webpackChunkName: "PDFJS" */ 'pdfjs-dist/build/pdf.min.mjs')
        ]).then(() => {
            PDFJS.GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.mjs';
            this.PDFJSloaded = true;
            this.store.dispatch(new DocumentViewerSetInitialized({ initialized: true }));
        });
    }

    ngOnInit() {
        this.subscriptions.push(
            (this.route as ActivatedRoute).params.subscribe(params => {
                this.productId = parseInt(params['productId'], 10);
                this.orderId = params['orderId'];
                this.formId = params['formId'];
                this.materialId = params['materialId'];
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.url && !!changes.url.currentValue) {
            this.loadDocument();
        }
    }

    onClickNextDocument() {
        // Close fullscreen mode if we're navigating to another document;
        if (this.documentCount > 1 && this.pdfFullscreen) {
            this.onClickFullscreen();
        }

        this.onNext.emit(null);
    }

    onClickPrevDocument() {
        // Close fullscreen mode if we're navigating to another document;
        if (this.documentCount > 1 && this.pdfFullscreen) {
            this.onClickFullscreen();
        }

        this.onPrev.emit(null);
    }

    async fetchWithAuthentication(url: string, authToken: string) {
        const headers = new Headers();
        headers.set('Authorization', authToken);
        return fetch(url, { headers });
    }

    async loadDocument() {
        this.ref.detectChanges();
        this.loadingFile$.next(true);

        const documentViewer$ = this.store.pipe(select(state => state.documentViewer));
        const docState = extractStreamValue(documentViewer$);
        const currentForm = docState.currentForm;

        if (currentForm && !!currentForm.id) {
            const response = await this.fetchWithAuthentication(this.url, this.authToken);
            const data = await response.arrayBuffer();

            const contentType = response.headers.get('Content-Type');
            this.isPdf = contentType === 'application/pdf';

            if (this.isPdf) {
                this.isSupportedFile = true;
                this.createPdfSource(data);
            } else if (contentType?.includes('image/')) {
                this.isSupportedFile = true;
                this.createImageSource(data);
            } else {
                // xls, xlsx, csv
                this.loadingFile$.next(false);
                this.isSupportedFile = false;
                this.pdfLoaded = false;
            }
        }
    }

    arrayBufferToBase64(buffer: ArrayBuffer) {
        const bufferArray = new Uint8Array(buffer);
        const stringCharArray = bufferArray.reduce((data: string, byte: number) => {
            return data + String.fromCharCode(byte);
        }, '');

        return btoa(stringCharArray);
    }

    createImageSource(data: ArrayBuffer) {
        const base64 = this.arrayBufferToBase64(data);
        this.imageDataUrl = this.domSanitizer.bypassSecurityTrustUrl(`data:image;base64,${base64}`);
        this.ref.markForCheck();

        this.pdfLoaded = false;
    }

    createPdfSource(data: ArrayBuffer) {
        this.pdfSource = { data };
        this.ref.markForCheck();
    }

    canDisplayPagination() {
        return (
            this.pdf &&
            Number.parseFloat(this.pdf.page() as any) >= 0 &&
            Number.parseFloat(this.pdf.totalPages as any) >= 0
        );
    }

    onLoadImage() {
        this.loadingFile$.next(false);
        this.pdfLoaded = true;
        this.pdfLoadError = false;
    }

    onLoadDocument() {
        this.loadingFile$.next(false);
        this.pdfLoaded = true;
        this.pdfLoadError = false;
    }

    onLoadDocumentError(error: any) {
        this.loadingFile$.next(false);
        this.pdfLoaded = true;
        this.pdfLoadError = true;

        if (error && (error.status === 401 || error.status === 403)) {
            this.onError.emit(error);
        }
    }

    onClickFullscreen() {
        this.pdfFullscreen = !this.pdfFullscreen;

        if (this.pdfFullscreen) {
            this.styles.pdfWrapper = this.staticStyles.pdfFullScreenStyle;
        } else {
            this.styles.pdfWrapper = this.staticStyles.pdfInnerFullscreenStyle;
        }

        this.fitPdf();
    }

    zoomIn() {
        if (this.isPdf) {
            this.pdf.zoomIn();
        } else {
            this.image.zoomIn();
        }
    }

    zoomOut() {
        if (this.isPdf) {
            this.pdf.zoomOut();
        } else {
            this.image.zoomOut();
        }
    }

    onClickRotate() {
        if (this.isPdf) {
            this.pdf.rotate();
        } else {
            this.image.rotate();
        }
    }

    fitPdf() {
        // Wait for parent container to go fullscreen before attempting to fit PDF into it;
        setTimeout(() => {
            if (this.pdf) {
                this.pdf.fit();
                this.pdf.simulateScroll(); // Trigger PDF resize;
            } else {
                this.image.fit();
            }
        }, 100);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
