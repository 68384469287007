import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { from as fromPromise, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { ENVIRONMENT, IEnvironment } from '@trade-platform/ui-utils';
import { planDecoder } from '../../decoders/plan/decoder';
import { Plan } from '../../models/plan/model';

@Injectable()
export class PlanService {
    constructor(private http: HttpClient, @Inject(ENVIRONMENT) private environment: IEnvironment) {}

    getPlanByTaxId(taxId: string, planNumber: string): Observable<Plan> {
        return this.http
            .get<Plan>(
                `${this.environment.api}planFilings?sponsorEin=${taxId}&planNumber=${planNumber}`
            )
            .pipe(
                concatMap(p => {
                    return fromPromise(planDecoder.decodeToPromise(p));
                })
            );
    }
}
