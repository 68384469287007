import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import {
    GridApi,
    IHeaderParams,
    IRowNode,
    PaginationChangedEvent,
    SelectionChangedEvent
} from 'ag-grid-community';
import { NgClass } from '@angular/common';

interface PaginationPageRange {
    startRange: number;
    endRange: number;
}

type CheckboxState = 'unchecked' | 'checked' | 'indeterminate';

@Component({
    template: `
        <div
            role="presentation"
            ref="cbSelectAll"
            class="ag-header-select-all ag-labeled ag-label-align-right ag-checkbox ag-input-field"
        >
            <div
                ref="eWrapper"
                class="ag-wrapper ag-input-wrapper ag-checkbox-input-wrapper"
                [ngClass]="'ag-' + checkboxState"
                role="presentation"
            >
                <input
                    (click)="onClick()"
                    ref="eInput"
                    class="ag-input-field-input ag-checkbox-input"
                    type="checkbox"
                    id="ag-input-id-9"
                />
            </div>
        </div>
    `,
    standalone: true,
    imports: [NgClass]
})
export class AixGridSelectCurrentPageHeaderComponent implements IHeaderAngularComp {
    public params: IHeaderParams;
    checkboxState: CheckboxState = 'unchecked';

    constructor() {}

    refresh(params: IHeaderParams): boolean {
        // TODO: https://www.ag-grid.com/angular-data-grid/component-header/#refresh
        return true;
    }

    agInit(params: IHeaderParams): void {
        this.params = params;
        if (this.params.api) {
            this.params.api.addEventListener(
                'paginationChanged',
                (event: PaginationChangedEvent) => {
                    event.api.deselectAll();
                    this.checkboxState = this.getCheckboxState(event.api);
                }
            );
            this.params.api.addEventListener('selectionChanged', (event: SelectionChangedEvent) => {
                this.checkboxState = this.getCheckboxState(event.api);
            });
        }
    }

    /**
     * Called when the header checkbox is clicked;
     * This sets the current checkbox header state based on previous state and checks/unchecks the current pages rows accordingly;
     */
    onClick(): void {
        this.checkboxState = this.checkboxState === 'checked' ? 'unchecked' : 'checked';
        if (this.params.api) {
            // Check or uncheck all items in the current page based on the current checkbox state;
            const pageRange: PaginationPageRange = this.getCurrentPageRange(this.params.api);

            this.params.api.forEachNodeAfterFilterAndSort((node: IRowNode, index: number) => {
                if (index >= pageRange.startRange && index <= pageRange.endRange) {
                    node.setSelected(this.checkboxState === 'checked');
                }
            });
        }
    }

    /**
     * Get the current page range in relation to the entire data set;
     * @param gridApi {GridApi} - the grid api used to get paging data;
     * @returns {PaginationPageRange} - the index range of the current page of the given grid;
     */
    private getCurrentPageRange(gridApi: GridApi): PaginationPageRange {
        const pageSize = gridApi.paginationGetPageSize();
        const currentPage = gridApi.paginationGetCurrentPage();

        let startRange = 0;
        let endRange = pageSize - 1;
        if (currentPage > 0) {
            const offset = currentPage * pageSize;
            startRange += offset;
            endRange += offset;
        }

        return {
            startRange,
            endRange
        };
    }

    /**
     * Determines the current state of the header checkbox based on the current pages selections;
     * @param gridApi {GridApi} - the grid api to use to determine the header checkbox state;
     * @returns {CheckboxState} - either checked, unchecked, or indeterminate based on the current pages selections;
     */
    private getCheckboxState(gridApi: GridApi): CheckboxState {
        const pageRange: PaginationPageRange = this.getCurrentPageRange(gridApi);
        let currentPageNodeCount = 0;
        let selectionCount = 0;
        for (let i = pageRange.startRange; i <= pageRange.endRange; i++) {
            const rowNode: IRowNode | undefined = gridApi.getDisplayedRowAtIndex(i);
            if (rowNode) {
                if (rowNode.isSelected()) {
                    selectionCount++;
                }
                currentPageNodeCount++;
            }
        }

        switch (selectionCount) {
            case 0:
                return 'unchecked';
            case currentPageNodeCount:
                return 'checked';
            default:
                return 'indeterminate';
        }
    }
}
