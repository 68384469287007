import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    output,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import {
    AixNotificationExtra,
    BaseOrder,
    constants,
    DocumentViewerReturnTradePackage,
    OrderApprovalBody,
    OrderFormComment
} from '@trade-platform/ui-shared';
import { commentStatus, commentTag, commentType, orderStatus } from '@trade-platform/lib-enums';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixLoadingButtonComponent,
    AixModalComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { Subscription } from 'rxjs';
import { getApprovalId } from '../document-viewer.helper';
import { AixFileUploadComponent } from '../../../file-upload/file-upload';
import { AixMakeChangesComponent } from '../../../process/overview/docusign/make-changes/make-changes';
import { areApprovalFormUploadsCompleted, hasMaxFormUploads } from '@advisor-ui/orders';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { AixFormUploadComponent } from '../../../form-upload/form-upload';
import {
    BaseOrdersStoreFacade,
    ORDERS_STORE_FACADE,
    OrderTriggerMakeChangesAction
} from '@advisor-ui/orders';
@Component({
    selector: 'aix-document-action-bar',
    templateUrl: './document-action-bar.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixModalComponent,
        AixMakeChangesComponent,
        NgIf,
        AixDataTestingDirective,
        AixFormUploadComponent,
        AixButtonComponent,
        NgClass,
        AixLoadingButtonComponent,
        AsyncPipe
    ]
})
export class AixDocumentViewerActionBarComponent implements OnInit, OnDestroy {
    @Input() downloadAll = false;
    @Input() isReadOnly = false;

    clickDownload = output();
    clickClose = output();

    @ViewChild('returnTradePackageModal') returnTradePackageModal: AixModalComponent;
    @ViewChild('needsActionModal') needsActionModal: AixModalComponent;
    @ViewChild('authorizeModal') authorizeModal: AixModalComponent;
    @ViewChild('sentModal') sentModal: AixModalComponent;
    @ViewChild('signFlowSelectionModal') signFlowSelectionModal: AixModalComponent;
    @ViewChild('aixMakeChangesAndCancelEnvelopeModal')
    aixMakeChangesAndCancelEnvelopeModal: AixMakeChangesComponent;
    @ViewChild('downloadAndSignModal')
    downloadAndSignModal: AixModalComponent;
    @ViewChild('orderFileUpload')
    orderFileUpload: AixFileUploadComponent;

    hasMaxFormUploads = false;
    hasUnfinishedFormUploads = true;
    acceptedFormIds: string[] = [];

    isSigner: boolean | null = null;
    makeChangesRedirectUrl = '';
    subscriptions: Subscription[] = [];
    notificationLanguage = {
        resolveNeedsAction: () =>
            'Orders with "Needs Changes" or "Needs Clarification" comments cannot be downloaded and signed.',
        needsAction: () =>
            'Please add a "Needs Changes" or "Needs Clarification" comment before selecting "Needs Action".',
        returnTradePackage: () =>
            'Please reply to all "Needs Clarification" comments before returning the order.',
        needsClarificationResolved: () => 'You have replied to all "Needs Clarification" comments.',
        makeChanges: () =>
            'Please reply to all "Needs Clarification" comments before editing order.',
        approve: () =>
            'Orders with "Needs Changes" or "Needs Clarification" comments cannot be authorized.',
        sign: () =>
            'Orders with "Needs Changes" or "Needs Clarification" comments cannot be signed.'
    };

    orderStatus = orderStatus;
    downloadFlag = false;
    hasNeedsClarificationComments = false;

    cancelButtonType = BUTTON_TYPE.link;
    markAsSignedButtonType = BUTTON_TYPE.primary;
    authorizeOrderButtonType = BUTTON_TYPE.primary;
    downloadAndSignButtonType = BUTTON_TYPE.primary;
    eSignButtonType = BUTTON_TYPE.primary;
    returnOrderButtonType = BUTTON_TYPE.primary;
    editOrderButtonType = BUTTON_TYPE.primary;
    editOrderNeedsClarificationButtonType = BUTTON_TYPE.secondary;
    needsActionButtonType = BUTTON_TYPE.secondary;

    readonly commentTag = commentTag;
    readonly reducerSuffix = this.storeFacade.type;
    readonly styles = {
        loading: {
            verticalAlign: 'middle',
            display: 'inline-flex',
            position: 'relative'
        }
    };

    constructor(
        private router: Router,
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public profileStoreFacade: ProfileStoreFacade,
        private cd: ChangeDetectorRef
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.storeFacade.orderSuccess$.subscribe(order => {
                this.isSigner = !!this.profileStoreFacade.profile.roles.find(
                    r => r.name === constants.profileTypes.SIGNER
                );
                this.setFormData(order);
            }),
            this.storeFacade.orderApproveSuccess$.subscribe(approveResponse => {
                this.loadOrder();
                this.router.navigate(this.storeFacade.routes.status());
            }),
            this.storeFacade.orderTakeOfflineSuccess$.subscribe(_ => {
                this.loadOrder();
            }),
            this.storeFacade.orderMakeChangesSuccess$.subscribe(_ => {
                const order = this.storeFacade.order;
                if (order) {
                    if (
                        this.profileStoreFacade.profile.roles.some(role =>
                            ['admin', 'advisor', 'assistant', 'activeInvestor'].includes(role.name)
                        )
                    ) {
                        this.router.navigate(this.storeFacade.routes.process.overview(order.id));
                    } else {
                        this.router.navigate(this.storeFacade.routes.detailOverview(order.id));
                    }
                }
            }),
            this.storeFacade.actions.documentViewerSetPermissions.success$.subscribe(_ => {
                const order = this.storeFacade.order;
                const docState = this.storeFacade.documentViewer;
                if (order && docState.action === 'wetSign' && docState.hasPermission) {
                    this.storeFacade.actions.loadApprovalForms.dispatch({ orderId: order.id });
                }
                if (
                    docState.action === 'approve' &&
                    docState.hasPermission &&
                    order?.currentApproval?.type === 'integration' &&
                    order.currentApproval.additionalData?.bannerText
                ) {
                    const bannerType =
                        (order.currentApproval.additionalData?.bannerType as
                            | 'error'
                            | 'ok'
                            | 'warning'
                            | 'alert') || 'alert';
                    this.showNotification(
                        order.currentApproval.additionalData?.bannerText as string,
                        bannerType
                    );
                }
            }),
            this.storeFacade.orderApprovalFormsSuccess$.subscribe(forms => {
                const order = this.storeFacade.order;
                this.acceptedFormIds =
                    forms && forms.length ? forms.map((f: { id: string }) => f.id) : [];
                if (order && this.acceptedFormIds.length > 0) {
                    this.setFormData(order);
                }
                this.cd.detectChanges();
            }),
            this.storeFacade.actions.getFormComments.success$.subscribe(comments => {
                this.hasNeedsClarificationComments = comments.payload
                    ? comments.payload.some(
                          (comment: any) =>
                              comment.commentType === commentType.userGenerated &&
                              comment.status === commentStatus.unresolved &&
                              comment.commentTag === commentTag.clarification
                      )
                    : false;
                this.showOnAllCommentsReplied();
            }),
            this.storeFacade.actions.documentViewerReturnTradePackage.success$.subscribe(_ => {
                this.onReturnTradePackage();
            }),
            this.storeFacade.actions.orderTriggerMakeChanges.success$.subscribe(_ => {
                this.makeChanges();
            }),
            this.storeFacade.documentViewer$.subscribe(_ => {
                if (!_.downloading[this.storeFacade?.order?.title]) {
                    this.downloadFlag = false;
                }
            })
        );
    }

    onClickApprove() {
        if (!this.hasUnresolvedNeedsActionComments()) {
            this.storeFacade.actions.documentViewerHideNotification.dispatch();
            this.authorizeModal.openModal();
            this.cd.detectChanges();
        } else {
            this.showNotification(this.notificationLanguage.approve(), 'warning');
        }
    }

    loadOrder() {
        const order = this.storeFacade.order;
        if (order) {
            this.storeFacade.actions.getOrder.dispatch({
                orderId: order.id,
                reducerSuffix: this.reducerSuffix
            });
        }
    }

    authorizeModalSelected(e: string) {
        if (e === 'Yes, continue') {
            this.approve();
        }
    }

    needsActionSelected(e: string) {
        const order = this.storeFacade.order;
        if (order && e === 'Yes, continue') {
            // If we have needs changes comments - NIGO order;
            // If we dont have needs changes comments - mark order as Needs Clarification;
            const comments = this.storeFacade.orderFormComments;
            if (
                comments &&
                comments.find(
                    comment =>
                        comment.status !== commentStatus.resolved &&
                        comment.commentTag === commentTag.change
                )
            ) {
                this.storeFacade.actions.markNigo.dispatch({ orderId: order.id });
            } else {
                this.storeFacade.actions.needsClarification.dispatch({
                    orderId: order.id
                });
            }
        }
    }

    returnTradePackageSelected(e: string) {
        const order = this.storeFacade.order;
        if (order && e === 'Yes, continue') {
            this.storeFacade.actions.resumeApprovals.dispatch({
                orderId: order.id
            });
        }
    }

    uploadDocumentRedirect() {
        const order = this.storeFacade.order;
        this.router.navigate(this.storeFacade.routes.supportingDocuments(order.id));
    }

    sentModalSelected(e: string) {
        if (e === 'Close') {
            this.loadOrder();
        }
    }

    sign() {
        if (!this.hasUnresolvedNeedsActionComments()) {
            const order = this.storeFacade.order;
            this.storeFacade.actions.documentViewerHideNotification.dispatch();
            this.storeFacade.actions.sign.dispatch({ orderId: order.id });
        } else {
            this.showNotification(this.notificationLanguage.sign(), 'warning');
        }
    }

    approve() {
        const order = this.storeFacade.order;
        const approvalId = getApprovalId(order);
        const approvalPayload: OrderApprovalBody = {
            approved: true
        };

        this.storeFacade.actions.approve.dispatch({
            orderId: order.id,
            approvalId: approvalId as number,
            body: approvalPayload
        });
    }

    makeChanges() {
        const order = this.storeFacade.order;

        switch (order.status) {
            case orderStatus.formsComplete:
            case orderStatus.readyToSend:
            case orderStatus.readyForPresignatureReview:
            case orderStatus.pendingPresignatureReview:
            case orderStatus.presignatureReviewComplete:
                this.editOrder();
                break;
            case orderStatus.pendingSignatures:
            case orderStatus.pendingApprovalProcess:
                this.aixMakeChangesAndCancelEnvelopeModal.open();
                break;
            case orderStatus.nigo:
            case orderStatus.needsClarification:
                if (this.hasUnrepliedComments()) {
                    this.showNotification(this.notificationLanguage.makeChanges(), 'warning');
                } else if (order.envelopeId && order.envelopeId !== '') {
                    this.storeFacade.actions.documentViewerHideNotification.dispatch();
                    this.aixMakeChangesAndCancelEnvelopeModal.open();
                } else {
                    this.storeFacade.actions.documentViewerHideNotification.dispatch();
                    this.editOrder();
                }
                break;
            default:
                this.aixMakeChangesAndCancelEnvelopeModal.open();
                break;
        }
    }

    editOrder() {
        const order = this.storeFacade.order;
        this.storeFacade.actions.makeChanges.dispatch({ orderId: order.id });
    }

    downloadAndSignForms() {
        if (!this.hasUnresolvedNeedsActionComments()) {
            this.storeFacade.actions.documentViewerHideNotification.dispatch();
            this.downloadAndSignModal.openModal();
        } else {
            this.showNotification(this.notificationLanguage.sign(), 'warning');
        }
    }

    markAsSigned() {
        if (!this.hasUnresolvedNeedsActionComments()) {
            this.storeFacade.actions.documentViewerHideNotification.dispatch();
            this.approve();
        } else {
            this.showNotification(this.notificationLanguage.sign(), 'warning');
        }
    }

    onMarkOrderNeedsAction() {
        if (this.hasUnresolvedNeedsActionComments()) {
            this.storeFacade.actions.documentViewerHideNotification.dispatch();
            this.needsActionModal.openModal();
        } else {
            this.showNotification(this.notificationLanguage.needsAction(), 'warning');
        }
    }

    showOnAllCommentsReplied() {
        const order = this.storeFacade.order;
        if (
            this.currentNeedsClarificationComments().length > 0 &&
            !this.hasUnrepliedComments() &&
            order &&
            (order.status === orderStatus.needsClarification || order.status === orderStatus.nigo)
        ) {
            const extras: AixNotificationExtra[] = this.hasNeedsChangesComments()
                ? [
                      {
                          text: 'Please',
                          type: 'text'
                      },
                      {
                          text: 'edit order',
                          type: 'link',
                          action: new OrderTriggerMakeChangesAction()
                      },
                      {
                          text: 'and resubmit.',
                          type: 'text'
                      }
                  ]
                : [
                      {
                          text: 'Please',
                          type: 'text'
                      },
                      {
                          text: 'return order',
                          type: 'link',
                          action: new DocumentViewerReturnTradePackage()
                      },
                      {
                          text: 'for review.',
                          type: 'text'
                      }
                  ];
        } else {
            // Only hide notification if the needs clarification resolved text is being actively displayed;
            const activeNotification = this.storeFacade.documentViewer.notification;
            if (
                activeNotification &&
                activeNotification.text === this.notificationLanguage.needsClarificationResolved()
            ) {
                this.storeFacade.actions.documentViewerHideNotification.dispatch();
            }
        }
    }

    onReturnTradePackage() {
        if (this.hasUnrepliedComments()) {
            this.showNotification(this.notificationLanguage.returnTradePackage(), 'warning');
        } else {
            this.returnTradePackageModal.openModal();
            this.cd.detectChanges();
        }
    }

    showNotification(
        text: string,
        status: 'error' | 'ok' | 'warning' | 'alert',
        extras?: AixNotificationExtra[]
    ) {
        this.storeFacade.actions.documentViewerShowNotification.dispatch({
            notification: {
                text: text,
                status: status,
                extras: extras
            }
        });
    }

    selectSignFlow() {
        const order = this.storeFacade.order;
        if (order?.fund?.reviewerCanESign) {
            this.signFlowSelectionModal.openModal();
        } else {
            this.displayModal(true);
        }
    }

    signFlowSelected(e: string) {
        if (e === 'Wet Sign') {
            this.displayModal(true);
        } else if (e === 'eSign') {
            this.sign();
        }
    }

    displayModal(value: boolean) {
        if (this.hasUnresolvedNeedsActionComments()) {
            this.showNotification(this.notificationLanguage.resolveNeedsAction(), 'warning');
        } else {
            this.storeFacade.actions.documentViewerShowModal.dispatch({ showModal: value });
        }
    }

    currentNeedsClarificationComments(): OrderFormComment[] {
        const comments = this.storeFacade.orderFormComments;
        if (!comments) {
            return [];
        } else {
            return comments.filter(
                comment =>
                    comment.commentType === commentType.userGenerated &&
                    comment.commentTag === commentTag.clarification &&
                    comment.status !== commentStatus.resolved
            );
        }
    }

    /**
     * Determines if there are any "needs clarification" comments that weren't replied to by the active user;
     * @returns {boolean} - true if there are "needs clarification" comments that were not replied to by this user, false otherwise;
     */
    hasUnrepliedComments(): boolean {
        const needsClarificationComments = this.currentNeedsClarificationComments();
        const organizationId = this.profileStoreFacade.profile?.organization?.id;
        let hasUnreplied = false;
        needsClarificationComments.forEach(comment => {
            if (
                comment.children &&
                !comment.children.find(c => c.organizationId === organizationId)
            ) {
                hasUnreplied = true;
            }
        });
        return hasUnreplied;
    }

    /**
     * Determines if there are any unresolved needs action comments on the order;
     * @returns {boolean} - true if there are unresolved needs action comments on the order, false otherwise;
     */
    hasUnresolvedNeedsActionComments(): boolean {
        const orderFormComments = this.storeFacade.orderFormComments;
        if (orderFormComments) {
            return (
                orderFormComments.find(
                    comment =>
                        comment.commentType === commentType.userGenerated &&
                        comment.status === commentStatus.unresolved &&
                        comment.commentTag !== commentTag.information
                ) !== undefined
            );
        }
        return false;
    }

    isPending() {
        const order = this.storeFacade.order;
        if (!order) {
            return true;
        }
        return (
            order.status === orderStatus.pendingPresignatureReview ||
            order.status === orderStatus.pendingSubmissionToTransferAgent ||
            order.status === orderStatus.pendingSubmissionToFundSponsor ||
            order.status === orderStatus.pendingSubmissionToCustodian ||
            order.status === orderStatus.pendingCustodianApproval ||
            order.status === orderStatus.pendingFirmApproval ||
            order.status === orderStatus.pendingFundSponsorApproval
        );
    }

    /**
     * Determines if we have an unresolved needs changes comment;
     * @returns {boolean} - true if we have at least one unresolved needs changes comment, false otherwise;
     */
    hasNeedsChangesComments(): boolean {
        const orderFormComments = this.storeFacade.orderFormComments;
        if (orderFormComments) {
            return (
                orderFormComments.find(
                    comment =>
                        comment.commentType === commentType.userGenerated &&
                        comment.status === commentStatus.unresolved &&
                        comment.commentTag === commentTag.change
                ) !== undefined
            );
        }
        return false;
    }

    onDownload() {
        this.downloadFlag = true;
        this.clickDownload.emit();
    }

    ngOnDestroy() {
        this.storeFacade.actions.approveStart.reset();
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    private setFormData(order: BaseOrder) {
        this.hasMaxFormUploads = hasMaxFormUploads(order, this.acceptedFormIds);
        this.hasUnfinishedFormUploads = !areApprovalFormUploadsCompleted(
            order,
            this.acceptedFormIds
        );
    }
}
