import { Subject, Subscription } from 'rxjs';
import {
    Component,
    ElementRef,
    HostBinding,
    input,
    OnDestroy,
    OnInit,
    output,
    viewChild
} from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { NgIf } from '@angular/common';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-search-box',
    templateUrl: 'aix-search-box.component.html',
    styleUrls: ['aix-search-box.component.scss'],
    standalone: true,
    imports: [AixDataTestingDirective, NgIf]
})
export class AixSearchBoxComponent implements OnInit, OnDestroy {
    searchBox = viewChild<ElementRef<HTMLInputElement>>('searchBox');

    urlParam = input<string>('search');
    dataTestingString = input<string>('search-input');
    placeholder = input<string>('Filter');
    iconClasses = input<string>('icon fa-regular fa-filter');
    onStartSearch = output<string>();
    onSearch = output<string>();

    @HostBinding('attr.data-testing')
    dataTesting: string;

    private subscriptions: Subscription[] = [];
    private onSearchText = new Subject<string>();

    get searchText() {
        return (<ElementRef>this.searchBox()).nativeElement.value
            ? (<ElementRef>this.searchBox()).nativeElement.value
            : '';
    }

    constructor(private route: ActivatedRoute) {}

    ngOnInit() {
        this.subscriptions.push(
            this.route.paramMap.subscribe(result => {
                (<ElementRef>this.searchBox()).nativeElement.value = result.has(this.urlParam())
                    ? result.get(this.urlParam())
                    : '';

                this.onSearch.emit((<ElementRef>this.searchBox()).nativeElement.value);
            }),
            this.onSearchText.pipe(debounceTime(600)).subscribe(searchText => {
                this.onStartSearch.emit(searchText);
            })
        );
        this.dataTesting = this.dataTestingString();
    }

    search(target: EventTarget | null) {
        const searchText = (target as HTMLInputElement).value.trim();
        this.onSearchText.next(searchText);
    }

    clear() {
        (<ElementRef>this.searchBox()).nativeElement.value = '';
        this.onSearch.emit((<ElementRef>this.searchBox()).nativeElement.value);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
