import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import {
    AixProgressComponent,
    DocumentFile,
    OrderForm,
    OrderFormComment,
    OrderForms
} from '@trade-platform/ui-shared';
import { AixFileUploadComponent } from '../../../file-upload/file-upload';
import { getFormFile, isPendingSubmission } from '../utils/order-utils';
import { OrderFormCommentsService } from '../../../order-comments/order-form-comments.helper';
import { fileType, formStatus, orderStatus } from '@trade-platform/lib-enums';
import { Subscription } from 'rxjs/internal/Subscription';
import { ENVIRONMENT, IEnvironment, objectHasValue } from '@trade-platform/ui-utils';
import { AixUploadDocumentComponent, ReplacePayload } from '@advisor-ui/app-components';
import { isDisabledFile } from '../../../utils';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';
import { AixLabelUploadedFormDocumentsComponent } from '../../../file-upload/form-documents/label-uploaded-form-documents';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixInlineDropdownComponent,
    AixTagComponent,
    AixTooltipDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { OrderOverview } from 'libs/advisor-ui/language/language.interface';
import { getLanguage } from 'libs/advisor-ui/language/language.base';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';

export interface FormListItem {
    document: DocumentFile;
    form: OrderForm;
}

@Component({
    selector: 'aix-formlist',
    templateUrl: 'formlist.html',
    styleUrls: ['formlist.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        NgIf,
        NgFor,
        AixDataTestingDirective,
        NgClass,
        AixTagComponent,
        AixTooltipDirective,
        AixInlineDropdownComponent,
        AixButtonComponent,
        AixProgressComponent,
        AixUploadDocumentComponent,
        AixFileUploadComponent,
        AixLabelUploadedFormDocumentsComponent,
        AsyncPipe
    ]
})
export class AixFormlistComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('aixFileUpload') aixFileUpload: AixFileUploadComponent;

    @Input() filterBy = (form: OrderForm) => true;

    @Input() set forms(rawforms: OrderForms | undefined) {
        if (rawforms) {
            this.arrayForms = Object.keys(rawforms)
                .map(itm => {
                    rawforms[itm].isOpen = false;
                    return rawforms[itm];
                })
                .filter(this.filterBy)
                .sort((a, b): number => a.index - b.index);
            this.setFormData();
        }
    }

    @Input() orderFormComments: OrderFormComment[];
    @Input() title: string;
    @Input() clickable = false;
    @Input() isReadOnly = false;
    @Input() includeRemove = false;

    clickElem = output<FormListItem>();
    onRemove = output<DocumentFile>();

    language: OrderOverview = getLanguage('orderOverview');
    hideFormDocumentOwner = !!this.language?.hideFormDocumentOwner;

    subscriptions: Subscription[] = [];
    arrayForms: OrderForm[] = [];
    unfinishedForms: OrderForm[] = [];
    totalForms: OrderForm[] = [];
    relabelForm: OrderForm | null = null;
    selectedFiles: File[] = [];
    unresolvedFileComments: OrderFormComment[] = [];
    uploadUrl: string;
    isPending = true;

    fileSizeLimit =
        this.environment.fileUploadMaxSize && !isNaN(Number(this.environment.fileUploadMaxSize))
            ? Number(this.environment.fileUploadMaxSize)
            : 25000000;

    readonly orderStatus = orderStatus;
    readonly formStatus = formStatus;
    readonly fileType = fileType;
    readonly reducerSuffix = this.store.type;
    readonly uploadButtonType: BUTTON_TYPE = BUTTON_TYPE.link;
    readonly notOnboardedFormWithSignaturesInstructions =
        'Upload a completed, unsigned copy of the required form. You will have the ability to collect signatures at a later step.';

    constructor(
        @Inject(ENVIRONMENT) private environment: IEnvironment,
        @Inject(ORDERS_STORE_FACADE) public store: BaseOrdersStoreFacade,
        public commentsService: OrderFormCommentsService
    ) {}

    ngOnInit() {
        this.subscriptions.push(
            this.store.orderSuccess$.subscribe(order => {
                this.uploadUrl = `${this.store.apiUrl}/${order.id}/files`;
                this.isPending = isPendingSubmission(this.store.order);
            })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.orderFormComments && changes.orderFormComments.currentValue) {
            this.setFormData();
        }
    }

    showManageMenu(item: OrderForm) {
        return (
            ((this.store.order?.wetSign &&
                this.isPending &&
                (!item.isOnboarded || (item.isOnboarded && !item.noSignatures))) ||
                this.isFormNotOnboardedWithComments(item) ||
                (this.isFormNotOnboardedInReview(item) && this.isFormUserUploaded(item))) &&
            (!this.aixFileUpload?.uploadCompleted ?? true)
        );
    }

    getManageDropdownOptions(item: OrderForm) {
        const options = ['View'];

        if (item.isRequired === false && this.store.orderSteps.currentStep === 3) {
            options.push('Relabel');
        } else {
            options.push('Replace');
        }

        if (this.includeRemove || (item.isOnboarded === false && item.isRequired === false)) {
            options.push('Remove');
        }

        return options;
    }

    onOptionSelected(evt: string, item: OrderForm) {
        switch (evt) {
            case 'View':
                this.viewEvent(item);
                break;
            case 'Relabel':
                this.relabelForm = item;
                break;
            case 'Replace':
                this.onReplaceForm(item);
                break;
            case 'Remove':
                const file = getFormFile(this.store.order, item);
                this.onRemove.emit(file as DocumentFile);
                break;
        }
    }

    viewEvent(form: OrderForm) {
        const currentStep = this.store.orderSteps?.currentStep;
        if (
            form.isOnboarded !== false ||
            this.isFormNotOnboardedWithComments(form) ||
            this.isFormNIGOUploaded(form) ||
            (this.isFormUserUploaded(form) && currentStep !== 1)
        ) {
            const file = getFormFile(this.store.order, form);
            this.clickElem.emit({
                document: file as DocumentFile,
                form: form
            });
        }
    }

    formCommentsLabel(form: OrderForm) {
        const noOfFiles = this.getFormCommentsCount(form.id);
        return `${noOfFiles} Item${noOfFiles > 1 ? 's' : ''}`;
    }

    getFormCommentsCount(formId: string): number {
        return this.commentsService.getComments(formId).length;
    }

    isFormNIGOUploaded(form: OrderForm): boolean {
        const order = this.store.order;
        const file = getFormFile(order, form);
        return (
            objectHasValue(file) &&
            file.isUserUploaded === true &&
            file.orderStatus === orderStatus.draft
        );
    }

    isFormNotOnboardedWithComments(form: OrderForm): boolean {
        return form.isOnboarded === false && this.getFormCommentsCount(form.id) > 0;
    }

    isFormNotOnboardedWithoutComments(form: OrderForm): boolean {
        return form.isOnboarded === false && this.getFormCommentsCount(form.id) === 0;
    }

    isFormUserUploaded(form: OrderForm): boolean {
        const order = this.store.order;
        const file = getFormFile(order, form);
        return file != null;
    }

    isFormNotOnboardedInReview(form: OrderForm): boolean {
        const currentStep = this.store.orderSteps?.currentStep;
        return (currentStep === 3 || currentStep === 4) && form.isOnboarded === false;
    }

    onReplaceForm(form: OrderForm) {
        this.unresolvedFileComments = this.commentsService.getComments(form.id);
        form.isOpen = true;
    }

    getOrderFileCommentsText() {
        return `Please re-upload or remove this document in order to resolve the comment${
            this.unresolvedFileComments.length > 1 ? 's' : ''
        }`;
    }

    loadOrder() {
        const order = this.store.order;
        if (order) {
            this.store.actions.getOrder.dispatch({
                orderId: order.id,
                reducerSuffix: this.reducerSuffix
            });
        }
    }

    onReplaceFilesSelected(files: File[]) {
        Array.from(files).forEach(file => {
            this.selectedFiles.push(file);
        });
    }

    getReplacePayload(form: OrderForm): ReplacePayload | undefined {
        if (this.store.order) {
            const formFile = this.store.order.files.filter(file => file.formId === form.id)[0];
            return {
                id: formFile.id,
                formId: form.id
            };
        }
        return undefined;
    }

    currentStepIsNot(orderSteps: any, n: number): boolean {
        return orderSteps && orderSteps.currentStep !== n;
    }

    onFilesUploaded(item: OrderForm | null) {
        if (item) {
            const index = this.selectedFiles.findIndex(f => f.name === item.name);
            this.selectedFiles.splice(index, 1);

            // Load order when all uploads are completed
            if (this.selectedFiles.length === 0) {
                this.loadOrder();
            }
        } else {
            // This is an additional not onboarded form added
            this.loadOrder();
        }
    }

    isDisabledFile(file?: File) {
        const disabledOptions = [];

        if (file && isDisabledFile(file)) {
            disabledOptions.push('View');
        }

        if (this.isReadOnly) {
            disabledOptions.push('Replace');
        }

        return disabledOptions;
    }

    getNotOnboardedTooltipText(item: OrderForm) {
        const currentStep = this.store.orderSteps?.currentStep;
        if (currentStep === 1 && item.isRequired !== false) {
            return `You will need to obtain the most up to date version of this form from the fund sponsor directly. Please complete the form manually and you'll be prompted to upload it during a later step.`;
        } else if (currentStep === 1 && item.isRequired === false) {
            return 'This form was manually uploaded. You will have the opportunity to manage the file during a later step.';
        } else if (item.noSignatures && item.isOnboarded) {
            return 'This document does not require signatures.';
        } else {
            return this.notOnboardedFormWithSignaturesInstructions;
        }
    }

    showRelabelDialog(item: OrderForm) {
        return item.isOnboarded == false && item.isRequired === false && item === this.relabelForm;
    }

    showMissingLabel(item: OrderForm) {
        return (
            item.isOnboarded === false &&
            item.isRequired === false &&
            !item.documentOwner &&
            !this.showRelabelDialog(item) &&
            !this.aixFileUpload?.fileUploadOpen
        );
    }

    showUploadMoreForms() {
        return (
            this.store.orderSteps.currentStep === 3 &&
            this.arrayForms.every(form => this.isFormUserUploaded(form))
        );
    }

    private setFormData() {
        this.totalForms = this.arrayForms.filter(
            form => form.isOnboarded !== false || this.isFormNotOnboardedWithComments(form)
        );
        this.unfinishedForms = this.totalForms.filter(form => form.status !== formStatus.completed);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
