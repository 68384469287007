import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { ErrorWrapper } from '../models/common';
import { PlanActions, PlanActionTypes } from './actions';
import { Plan } from '../../models/plan/model';

export type PlanState = RemoteData<Plan, ErrorWrapper>;
const initialState = notAsked<Plan, ErrorWrapper>();

export function planReducer(state = initialState, action: PlanActions): PlanState {
    switch (action.type) {
        case PlanActionTypes.CLEAR_PLAN:
            return notAsked();
        case PlanActionTypes.LOAD_PLAN_BY_TAX_ID:
            return inProgress({} as Plan);
        case PlanActionTypes.LOAD_PLAN_BY_TAX_ID_SUCCESS:
            return success(action.payload.plan);
        case PlanActionTypes.LOAD_PLAN_BY_TAX_ID_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
