<aix-templates #aixTemplates (onInit)="aixTemplatesInitialized = true"></aix-templates>

<ng-template #signaturesStepContent>
    @if (!filesGenerated) {
    <div class="u-pt24 u-pb24 u-pl24">
        <aix-pdf-generation
            [order]="order"
            [viewState]="viewState"
            (onMakeChanges)="makeChanges()"
            (onGenerateFiles)="filesGenerated = true"
        >
        </aix-pdf-generation>
    </div>
    } @if (filesGenerated) {
    <div class="order-step-content">
        <p class="u-mb24">
            Review a preview of the PDFs associated with this order by selecting
            <span class="br-link selectable" (click)="viewOrder()">Preview</span>. To make changes
            to your order, select
            <span class="br-link selectable" (click)="makeChanges()">Edit Order</span>.
        </p>

        @if ((showStep === 0 || showStep === 2) && !inPreviewSend) {

        <aix-header-section [title]="'Required Forms'"></aix-header-section>
        <aix-formlist
            [forms]="(storeFacade.orderSuccess$ | async)?.forms"
            [clickable]="true"
            [includeRemove]="true"
            (clickElem)="goToForm($event)"
            (onRemove)="removeDocument($event)"
        ></aix-formlist>

        <div class="order-step-forms u-mt64">
            <aix-header-section
                [sectionClasses]="'u-mb0'"
                [title]="'Supporting Documents'"
                data-testing="filelist-header"
            >
                <aix-button
                    [buttonType]="uploadButtonType"
                    [buttonLabel]="'Upload'"
                    icon="'fa-upload u-mr4'"
                    data-testing="upload-button"
                    [isDisabled]="orderFileUploadRef.fileUploadOpen || isReadOnly"
                    (click)="uploadDocuments()"
                >
                </aix-button>
            </aix-header-section>
            @if (!arrayFiles?.length && !arrayRequiredFiles?.length &&
            !orderFileUploadRef.fileUploadOpen) {
            <div class="u-mt16">
                <p>There are no uploaded documents.</p>
            </div>
            }
            <aix-file-upload
                #orderFileUploadRef
                [isReadOnly]="isReadOnly"
                [orderFileComments]="orderComments"
                [enableIgnore]="true"
            >
            </aix-file-upload>
        </div>

        } @if (showStep !== 2) {
        <aix-pre-review
            [order]="order"
            [isRequired]="order?.presignatureReview?.required"
            [inPreviewSend]="inPreviewSend"
            (onCancelReview)="cancelReview()"
            (onChangeSelection)="onSelectReviewMethod($event)"
        >
        </aix-pre-review>
        }

        <aix-notification
            #formsMustBeLabeledNotification
            [classList]="['u-mt24']"
            status="warning"
            [canClose]="true"
        >
            All uploaded forms must be labeled to continue.
        </aix-notification>

        @if (order?.status === orderStatus.readyForPresignatureReview && !inPreviewSend) {
        <div class="-controls aix-toolbar u-mt24">
            <aix-button
                [buttonType]="sendButtonType"
                (click)="confirmReviewType()"
                [isDisabled]="disableContinueButton()"
                data-testing="confirmReviewButton"
                [buttonLabel]="'Continue'"
            ></aix-button>
        </div>
        } @if (order?.status === orderStatus.readyForPresignatureReview && inPreviewSend) {
        <div class="-controls aix-toolbar u-mt24">
            <aix-button
                [buttonType]="sendButtonType"
                (click)="send()"
                [isDisabled]="false"
                data-testing="sendButton"
                [buttonLabel]="'Send'"
            ></aix-button>
        </div>
        } @if (order?.status !== orderStatus.readyForPresignatureReview) {
        <div class="-controls aix-toolbar u-mt24">
            <aix-button
                [buttonType]="sendButtonType"
                (click)="continue()"
                [isDisabled]="order?.status !== orderStatus.presignatureReviewComplete || !notOnboardedFormsCompleted"
                data-testing="continueButton"
                [buttonLabel]="'Continue'"
            ></aix-button>
        </div>
        }
    </div>
    }
</ng-template>

@if (aixTemplatesInitialized) {

<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(1, 3)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(2, 3)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(3, 3)"
    [bodyTemplate]="signaturesStepContent"
    [isClosed]="false"
    [isDisabled]="false"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(4, 3)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(5, 3)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>

}

<aix-modal
    #cancelPresignatureReviewModal
    modalType="confirmation"
    [modalTitle]="'Cancel pre-signature review?'"
    (optionSelected)="confirmCancelReview($event)"
>
    <ng-template>
        <p>
            The order will be removed from the reviewer's queue and returned to its previous state.
        </p>
    </ng-template>
</aix-modal>
<aix-modal
    #deleteModal
    modalType="confirmation"
    [modalZIndex]="6000"
    [modalTitle]="'Remove document?'"
    (optionSelected)="onRemoveUploadOptionSelected($event)"
>
    <ng-template #deleteTemplate>
        <p class="u-fw500">This document will be removed.</p>
        <p class="u-mb16">You can upload a new version of this document.</p>
    </ng-template>
</aix-modal>
