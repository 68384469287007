import { Component, Input, OnInit, output, ViewChild } from '@angular/core';
import { DynamicColorStyle } from '@trade-platform/ui-utils';
import {
    AixUploadDocumentComponent,
    FileSizeLimitUnits,
    OrientationLimiters,
    ReplacePayload
} from '../upload-document/upload-document';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixHeaderSectionComponent,
    AixInlineDropdownComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { NgClass, NgIf, NgStyle } from '@angular/common';

@Component({
    selector: 'aix-upload-image',
    templateUrl: 'upload-image.html',
    styleUrls: ['upload-image.scss'],
    standalone: true,
    imports: [
        AixHeaderSectionComponent,
        NgIf,
        AixButtonComponent,
        AixDataTestingDirective,
        NgStyle,
        AixInlineDropdownComponent,
        AixUploadDocumentComponent,
        NgClass
    ]
})
export class AixUploadImageComponent implements OnInit {
    @ViewChild('imageDocumentsRef', { static: false })
    imageDocumentsRef: AixUploadDocumentComponent;

    @Input() primaryColor = '';
    @Input() file: any;
    @Input() label: string;
    @Input() styles: DynamicColorStyle = {
        links: {
            color: null
        }
    };
    @Input() infoText: string;
    @Input() infoBoxCustomClass: string;
    @Input() uploadUrl: string;
    @Input() type: string;
    @Input() formId: string;
    @Input() fileId: string;
    @Input() isModal = false;
    @Input() replacePayload: ReplacePayload;
    @Input() multiple = false;
    @Input() disabled = false;
    @Input() accept = 'image/jpg,image/jpeg,image/png,image/bmp'; //image default
    @Input() fileSizeLimit = 300000; // 300KB default (in bytes);
    @Input() fileSizeLimitUnit: FileSizeLimitUnits = 'KB';
    @Input() fileMinWidth = 0; // in pixels;
    @Input() fileMinHeight = 0; // in pixels;
    @Input() fileMaxWidth = 0; // in pixels;
    @Input() fileMaxHeight = 0; // in pixels;
    @Input() orientationLimit: OrientationLimiters = 'none'; // no orientation limit, by default;

    filesSelected = output<File[]>();
    optionSelected = output<any>();

    showImageUploader = false;
    math = Math;

    uploadButtonType = BUTTON_TYPE.link;

    constructor() {}

    ngOnInit() {
        return;
    }

    onImageSelected(e: File[]) {
        this.filesSelected.emit(e);
        this.showImageUploader = false;
    }

    onOptionSelected(e: any) {
        this.showImageUploader = true;
    }

    convertFileSize(fileSize: number) {
        switch (this.fileSizeLimitUnit) {
            case 'MB':
                return fileSize * 0.000001;
            case 'KB':
                return fileSize * 0.001;
            default:
                return fileSize;
        }
    }
}
