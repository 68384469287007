import {
    Component,
    HostListener,
    input,
    OnInit,
    output,
    viewChild,
    ViewEncapsulation
} from '@angular/core';
import {
    CellClickedEvent,
    GridApi,
    GridOptions,
    GridReadyEvent,
    RowClickedEvent,
    SelectionChangedEvent
} from 'ag-grid-community';
import { AgGridAngular, AgGridModule, ICellRendererAngularComp } from 'ag-grid-angular';
import { NgClass, NgStyle } from '@angular/common';

@Component({
    selector: 'aix-data-grid',
    templateUrl: './aix-data-grid.component.html',
    styleUrls: ['./aix-data-grid.component.scss'],
    /**
     * Note: ViewEncapsulation specified because we are styling the underlying
     * ag-grid
     */
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AgGridModule, NgClass, NgStyle]
})
export class AixDataGridComponent implements OnInit {
    agGrid = viewChild<AgGridAngular>('agGrid');

    frameworkComponents = input<{ [key: string]: new () => ICellRendererAngularComp }>();
    appearance = input<string>();
    domLayout = input<'normal' | 'autoHeight' | 'print' | undefined>();
    style = input<{ [key: string]: any }>();
    sortable = input(false);
    fitColumns = input(true);
    noHoverStyle = input(false);
    showHeader = input(false);
    rowData = input<any[]>();
    gridOptions = input<GridOptions>();
    rowSelection = input<'single' | 'multiple'>();
    context = input(null);

    gridReady = output<GridReadyEvent>();
    rowClicked = output<RowClickedEvent>();
    cellClicked = output<CellClickedEvent>();
    selectionChanged = output<SelectionChangedEvent>();

    mergedGridOpts: GridOptions;
    defaultGridOpts: GridOptions;

    gridApi: GridApi | undefined;
    paginationPageSize = 9;
    headerHeight: number;
    rowHeight: number;

    constructor() {}

    ngOnInit() {
        this.setupGrid();
    }

    setupGrid() {
        // NB> headerHeight and rowHeight need to match the equivalent CSS variables
        this.headerHeight = 56;
        this.rowHeight = 56;

        const columnDefs = this.gridOptions()?.columnDefs;

        this.defaultGridOpts = {
            columnDefs: columnDefs,
            rowClass: this.noHoverStyle() ? 'no-hover' : '',
            rowHeight: this.rowHeight,
            headerHeight: this.headerHeight,
            rowSelection: this.rowSelection(),
            suppressCellFocus: true,
            suppressRowHoverHighlight: this.noHoverStyle(),
            suppressMovableColumns: true,
            pagination: true,
            paginationPageSize: this.paginationPageSize,
            suppressPaginationPanel: true,
            suppressRowClickSelection: true,
            maxConcurrentDatasourceRequests: 1,
            maxBlocksInCache: 50,
            cacheBlockSize: 25,
            overlayLoadingTemplate: ' ',
            overlayNoRowsTemplate:
                '<p class="u-mt16 u-mb32">No records found, please try a different search term or filter.</p>',
            onGridReady: this.onGridReady.bind(this),
            onRowClicked: this.onRowClicked.bind(this),
            onCellClicked: this.onCellClicked.bind(this),
            onSelectionChanged: this.onSelectionChanged.bind(this),
            onFirstDataRendered: this.onResize.bind(this)
        };

        this.mergedGridOpts = { ...this.defaultGridOpts, ...this.gridOptions() };
    }

    search(searchText: string) {
        this.gridApi?.setGridOption('quickFilterText', searchText);
        if (this.gridApi?.getDisplayedRowCount() === 0) {
            this.gridApi.showNoRowsOverlay();
        } else {
            this.gridApi?.hideOverlay();
        }
    }

    onGridReady(event: GridReadyEvent) {
        this.gridApi = event.api;
        this.gridReady.emit(event);
    }

    @HostListener('window:resize')
    onResize() {
        if (this.fitColumns()) {
            setTimeout(() => {
                this.gridApi?.sizeColumnsToFit();
            });
        }
    }

    onRowClicked(event: RowClickedEvent) {
        if (!this.noHoverStyle()) {
            this.rowClicked.emit(event);
        }
    }

    onCellClicked(event: CellClickedEvent) {
        if (!this.noHoverStyle()) {
            this.cellClicked.emit(event);
        }
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        this.selectionChanged.emit(event);
    }
}
