import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { Store } from '@ngrx/store';
import {
    AixEffectActions,
    AppState,
    Auth,
    Brand,
    ClearPreviewBrandingSuccessAction,
    ErrorWrapper,
    FileUpload,
    FileUploadCancelAction,
    FileUploadFailureAction,
    FileUploadStartAction,
    FileUploadStatus,
    FileUploadSuccessAction,
    GetActivePreviewBrandingSuccessAction,
    PreviewBrandingSuccessAction,
    ReturnToUploaderAction
} from '@trade-platform/ui-shared';
import { DynamicColorStyle, getFromStorage, objectHasValue } from '@trade-platform/ui-utils';
import { cloneDeep } from 'lodash-es';
import { Subscription } from 'rxjs';
import * as PDFJS from 'pdfjs-dist';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixLoadingComponent,
    AixNotificationComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { NgFor, NgIf, NgStyle } from '@angular/common';

export interface ReplacePayload {
    id: string;
    contextMap?: string;
    supplementalFileTypeId?: string;
    formId?: string;
    firmId?: number;
    fundId?: number;
    holdingOptionId?: number;
}

export type OrientationLimiters = 'square' | 'horizontal' | 'both' | 'none';
export type FileSizeLimitUnits = 'MB' | 'KB' | 'bytes';
export type RestrictionTypes = 'minDimension' | 'maxDimension' | 'size' | 'orientation' | 'type';

@Component({
    selector: 'aix-upload-document',
    templateUrl: './upload-document.html',
    styleUrls: ['./upload-document.scss'],
    standalone: true,
    imports: [
        NgIf,
        AixNotificationComponent,
        NgStyle,
        NgFor,
        AixButtonComponent,
        AixDataTestingDirective,
        AixLoadingComponent
    ]
})
export class AixUploadDocumentComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild('uploadButtonRef') uploadButtonRef: ElementRef;

    filesSelected = output<File[]>();
    filesUploaded = output();
    uploadError = output();
    closeUpload = output<boolean>();

    @Input() uploadUrl: string;
    @Input() type: string;
    @Input() formId: string;
    @Input() fileId: string;
    @Input() contextMap: string;
    @Input() firmId: number;
    @Input() fundId: number;
    @Input() holdingOptionId: number;
    @Input() isModal = false;
    @Input() showCloseUploaderButton = true;
    @Input() replacePayload?: ReplacePayload;
    @Input() multiple = false;
    @Input() disabled = false;
    @Input() accept =
        'application/pdf,image/jpg,image/jpeg,image/png,image/bmp,.csv,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    @Input() acceptedTypeDisplayNames = 'PDF, JPG, JPEG, PNG, BMP, CSV, XLSX';
    @Input() fileSizeLimit = 2000000; // 2MB default (in bytes);
    @Input() fileSizeLimitUnit: FileSizeLimitUnits = 'MB';
    @Input() fileMinWidth: number | null = null; // in pixels;
    @Input() fileMinHeight: number | null = null; // in pixels;
    @Input() fileMaxWidth: number | null = null; // in pixels;
    @Input() fileMaxHeight: number | null = null; // in pixels;
    @Input() orientationLimit: OrientationLimiters = 'none'; // no orientation limit, by default;
    @Input() filenameLengthLimit: number | null = null;

    @Input() headingText: string;

    @Input() hasUploadTitle = false;

    isReady = true;
    isDragOver = false;
    fileUploadError = false;
    fileError = false;
    filePasswordError = false;
    fileErrorMessage = '';
    fileErrorTroubleshootingTips: string[] = [];
    filesUploadCount = 0;
    filesUploading: File[] = [];
    activeFileUpload: FileUpload;
    selectedFiles: File[] = [];
    acceptedFileTypes: string[] = [];

    selectedFilesCount = 0;
    checkedFilesCount = 0;
    verifiedFilesCount = 0;

    activePreviewBrand: Brand | null = null;
    styles: DynamicColorStyle = {
        links: {
            color: null
        },
        upload: {
            color: null,
            borderColor: null
        }
    };

    subscriptions: Subscription[] = [];

    closeUploaderButtonType = BUTTON_TYPE.link;

    private readonly FILE_ERROR_MESSAGES = {
        tooManyFiles: () => {
            return `Please upload one file.`;
        },
        fileSize: (fileSizeLimit: number) => {
            return `File size is too large, please upload a file that is less than ${
                this.convertFileSize(fileSizeLimit) + this.fileSizeLimitUnit
            }.`;
        },
        minFileDimensions: (fileMinWidth: number, fileMinHeight: number) => {
            return `File does not meet minimum size requirements, please upload a file that is at least ${fileMinWidth} pixels x ${fileMinHeight} pixels`;
        },
        maxFileDimensions: (fileMaxWidth: number, fileMaxHeight: number) => {
            return `File does not meet maximum size requirements, please upload a file that is at the most ${fileMaxWidth} pixels x ${fileMaxHeight} pixels`;
        },
        fileOrientation: (orientationLimit: OrientationLimiters) => {
            return `File orientation does not meet requirements, please upload a file with an orientation of ${this.getFileOrientationMesssage(
                orientationLimit
            )}`;
        },
        passwordProtection: () => {
            return `Unable to upload password protected documents.`;
        },
        fileType: () => {
            return `File type unsupported, please upload a (${this.fileTypesToLabel()})`;
        },
        filenameLength: (filenameLengthLimit: number) => {
            return `Filename is too long, please shorten the filename to fewer than ${filenameLengthLimit} characters.`;
        }
    };

    private readonly FILE_TROUBLESHOOTING_TIPS = {
        fileSize: () => {
            return [
                'Scan your documents at a lower image quality.',
                'Use Adobe Acrobat to compress your file size.'
            ];
        }
    };

    // enums cannot be used in templates
    UploadStatus = FileUploadStatus;

    constructor(
        private ref: ChangeDetectorRef,
        public actions$: AixEffectActions,
        private store: Store<AppState>
    ) {}

    ngOnInit() {
        if (this.accept && this.accept.length) {
            this.acceptedFileTypes = this.accept.split(',');
        }

        this.subscriptions.push(
            this.actions$
                .ofClass<PreviewBrandingSuccessAction>(PreviewBrandingSuccessAction)
                .subscribe(data => {
                    if (data && data.payload) {
                        this.previewBrand(data.payload);
                    }
                }),
            this.actions$
                .ofClass<ClearPreviewBrandingSuccessAction>(ClearPreviewBrandingSuccessAction)
                .subscribe(data => {
                    this.clearPreviewBrand();
                }),
            this.actions$
                .ofClass<GetActivePreviewBrandingSuccessAction>(
                    GetActivePreviewBrandingSuccessAction
                )
                .subscribe(action => {
                    this.activePreviewBrand = action.payload;
                }),
            this.actions$
                .ofClass<ReturnToUploaderAction>(ReturnToUploaderAction)
                .subscribe(action => {
                    this.returnToUploader();
                })
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (
            changes &&
            changes.accept &&
            changes.accept.isFirstChange() &&
            changes.accept.currentValue.length
        ) {
            this.acceptedFileTypes = changes.accept.currentValue.split(',');
        }
    }

    previewBrand(brand: Brand) {
        if (brand && brand.secondaryColor) {
            this.styles.links.color = brand.secondaryColor;
            this.styles.upload.color = brand.secondaryColor;
        }
        this.ref.detectChanges();
    }

    clearPreviewBrand() {
        this.styles.links.color = null;
        this.styles.upload.color = null;
        this.styles.upload.borderColor = null;
        this.ref.detectChanges();
    }

    onHoverUpload(e: any) {
        if (
            this.activePreviewBrand &&
            this.activePreviewBrand.secondaryColor &&
            this.activePreviewBrand.secondaryColor.length
        ) {
            e.style.borderColor = this.activePreviewBrand.secondaryColor;
        }
    }

    onLeaveUpload(e: any) {
        e.style.borderColor = '';
    }

    getToken() {
        return (getFromStorage('auth') as Auth).accessToken;
    }

    onDragOver(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.isDragOver = true;
    }

    onDragOut(event: Event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.isDragOver = false;
    }

    onDragAndDrop(event: DragEvent) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        this.isDragOver = false;

        const dataTransfer = (event as DragEvent).dataTransfer;
        const files: FileList = objectHasValue(dataTransfer)
            ? dataTransfer.files
            : ((event.target as HTMLInputElement).files as FileList);

        if (!this.multiple && files.length > 1) {
            this.fileError = true;
            this.fileErrorMessage = this.FILE_ERROR_MESSAGES.tooManyFiles();
        } else {
            this.onFileSelected(event);
        }
    }

    fileTypeToLabel(fileType: string) {
        if (fileType && fileType.length) {
            const split = fileType.split('/');
            if (split && split.length > 1) {
                return `.${split[1].toUpperCase()}`;
            }
        }
        return fileType;
    }

    fileTypesToLabel() {
        const fileTypes: string[] = [];
        this.acceptedFileTypes.forEach(fileType => {
            fileTypes.push(this.fileTypeToLabel(fileType));
        });
        return fileTypes.join(', ');
    }

    onFileSelected(event: Event) {
        const dataTransfer = (event as DragEvent).dataTransfer;
        const files: FileList = objectHasValue(dataTransfer)
            ? dataTransfer.files
            : ((event.target as HTMLInputElement).files as FileList);
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        if (!files.length) {
            return;
        }

        this.checkedFilesCount = 0;
        this.verifiedFilesCount = 0;

        if (this.multiple) {
            this.selectedFiles = cloneDeep(Array.from(files));
            this.selectedFilesCount = this.selectedFiles.length;
            Array.from(files).forEach(file => {
                if (file.type.split('/')[0] === 'image') {
                    this.setupReader(file);
                } else {
                    this.checkFile(file);
                }
            });
        } else {
            // Only verify first file when set to not allow multiple files;
            this.selectedFiles = cloneDeep(Array.from([files[0]]));
            this.selectedFilesCount = this.selectedFiles.length;
            if (files[0].type.split('/')[0] === 'image') {
                this.setupReader(files[0]);
            } else {
                this.checkFile(files[0]);
            }
        }
    }

    setupReader(file: File) {
        const reader = new FileReader(); // used to get file size and src;
        reader.onload = (e: any) => {
            const img = new Image(); // used to get file dimensions and orientation;
            img.onload = () => {
                if (!this.doRestrictionCheck('minDimension', null, img)) {
                    // Min file dimension check;
                    this.fileError = true;
                    this.fileErrorMessage = this.FILE_ERROR_MESSAGES.minFileDimensions(
                        this.fileMinWidth as number,
                        this.fileMinHeight as number
                    );
                } else if (!this.doRestrictionCheck('maxDimension', null, img)) {
                    // Max file dimension check;
                    this.fileError = true;
                    this.fileErrorMessage = this.FILE_ERROR_MESSAGES.maxFileDimensions(
                        this.fileMaxWidth as number,
                        this.fileMaxHeight as number
                    );
                } else if (!this.doRestrictionCheck('orientation', null, img)) {
                    // File orientation check;
                    this.fileError = true;
                    this.fileErrorMessage = this.FILE_ERROR_MESSAGES.fileOrientation(
                        this.orientationLimit
                    );
                } else if (!this.doRestrictionCheck('size', file)) {
                    // File size check;
                    this.fileError = true;
                    this.fileErrorMessage = this.FILE_ERROR_MESSAGES.fileSize(this.fileSizeLimit);
                    this.fileErrorTroubleshootingTips = this.FILE_TROUBLESHOOTING_TIPS.fileSize();
                } else if (!this.doRestrictionCheck('type', file)) {
                    // File type check;
                    this.fileError = true;
                    this.fileErrorMessage = this.FILE_ERROR_MESSAGES.fileType();
                } else {
                    // All checks passed;
                    this.verifiedFilesCount++;
                }

                this.checkedFilesCount++;
                this.doFilesCheck();
            };
            img.onerror = error => {
                // console.log(`Error creating image from file ${file.name}`);
            };
            img.src = e.target.result;
        };
        reader.onerror = error => {
            // console.log(`Error creating file reader from file ${file.name}`);
        };
        reader.readAsDataURL(file);
    }

    checkFile(file: File) {
        if (
            file.type === 'text/csv' ||
            file.type === 'application/vnd.ms-excel' ||
            file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ) {
            this.verifiedFilesCount++;
            this.doFilesCheck();
            return;
        }

        this.fileError = false;
        this.filePasswordError = false;
        this.fileErrorMessage = '';
        this.fileErrorTroubleshootingTips = [];

        const fileReader = new FileReader();
        fileReader.onload = () => {
            const byteArray = new Uint8Array(fileReader.result as ArrayBufferLike);
            // RJ: Added an any here because type is missing `catch` in `promise`
            (PDFJS.getDocument(byteArray).promise as any)
                .then(() => {
                    if (!this.doRestrictionCheck('size', file)) {
                        this.fileError = true;
                        this.fileErrorMessage = this.FILE_ERROR_MESSAGES.fileSize(
                            this.fileSizeLimit
                        );
                        this.fileErrorTroubleshootingTips =
                            this.FILE_TROUBLESHOOTING_TIPS.fileSize();
                    } else if (!this.doRestrictionCheck('type', file)) {
                        this.fileError = true;
                        this.fileErrorMessage = this.FILE_ERROR_MESSAGES.fileType();
                    } else if (
                        this.filenameLengthLimit &&
                        file.name.length > this.filenameLengthLimit
                    ) {
                        this.fileError = true;
                        this.fileErrorMessage = this.FILE_ERROR_MESSAGES.filenameLength(
                            this.filenameLengthLimit
                        );
                    } else {
                        this.verifiedFilesCount++;
                    }

                    this.checkedFilesCount++;
                    this.doFilesCheck();
                })
                .catch((err: Error) => {
                    if (err.message === 'No password given') {
                        this.fileError = true;
                        this.filePasswordError = true;
                        this.fileErrorMessage = this.FILE_ERROR_MESSAGES.passwordProtection();
                    } else {
                        // Invalid PDF structure error
                        this.fileError = true;
                        this.fileErrorMessage = err.message;
                    }

                    this.checkedFilesCount++;
                    this.doFilesCheck();
                });
        };
        fileReader.readAsArrayBuffer(file);
    }

    doFilesCheck() {
        if (this.verifiedFilesCount === this.selectedFilesCount) {
            this.fileError = false;
            this.filesSelected.emit(this.selectedFiles);
            if (this.replacePayload) {
                this.startReplace(this.selectedFiles, this.replacePayload);
            } else {
                this.startUpload(this.selectedFiles);
            }
        } else if (this.checkedFilesCount === this.selectedFilesCount) {
            this.ref.detectChanges(); // reload ui to display error message to user;
        }

        if (this.uploadButtonRef) {
            this.uploadButtonRef.nativeElement.value = '';
        }
    }

    hide() {
        this.closeUpload.emit(true);
    }

    doRestrictionCheck(
        restrictionType: RestrictionTypes,
        file: File | null = null,
        img: HTMLImageElement | null = null
    ) {
        switch (restrictionType) {
            case 'minDimension':
                return (
                    img &&
                    (this.fileMinWidth === null ||
                        (this.fileMinWidth && img.width >= this.fileMinWidth)) &&
                    (this.fileMinHeight === null ||
                        (this.fileMinHeight && img.height >= this.fileMinHeight))
                );
            case 'maxDimension':
                return (
                    img &&
                    (this.fileMaxWidth === null ||
                        (this.fileMaxWidth && img.width <= this.fileMaxWidth)) &&
                    (this.fileMaxHeight === null ||
                        (this.fileMaxHeight && img.height <= this.fileMaxHeight))
                );
            case 'orientation':
                return img && this.passesOrientationLimit(img.width, img.height);
            case 'size':
                return file && file.size && file.size <= this.fileSizeLimit;
            case 'type':
                return (
                    this.acceptedFileTypes.length > 0 &&
                    file &&
                    file.type &&
                    this.acceptedFileTypes.indexOf(file.type) > -1
                );
        }
    }

    startUpload(files: File[]) {
        if (this.uploadUrl) {
            this.fileUploadError = false;
            this.store.dispatch(new FileUploadStartAction());
            this.filesUploading = files;
            this.sequentiallyUploadFiles(Array.from(files));
        }
    }

    sequentiallyUploadFiles(files: File[]) {
        if (files && files.length === 0) {
            this.fileUploadError = false;
            this.filesUploaded.emit();
            this.store.dispatch(new FileUploadSuccessAction());
        } else {
            const fileToUpload = files.shift() as File;
            this.activeFileUpload = new FileUpload().upload(
                fileToUpload,
                this.uploadUrl,
                { Authorization: `Bearer ${this.getToken()}` },
                {
                    'Content-Type': 'multipart/form-data',
                    type: this.type ? this.type : undefined,
                    formId: this.formId ? this.formId : undefined,
                    supplementalFileTypeId: this.fileId ? this.fileId : undefined,
                    contextMap: this.contextMap ? this.contextMap : undefined,
                    firmId: this.firmId ? this.firmId : undefined,
                    fundId: this.fundId ? this.fundId : undefined,
                    holdingOptionId: this.holdingOptionId ? this.holdingOptionId : undefined
                },
                {
                    onUploadedCallback: () => {
                        this.filesUploadCount++;
                        this.sequentiallyUploadFiles(files);
                        this.ref.detectChanges();
                    },
                    onErrorCallback: (fileUpload: FileUpload) => {
                        this.handleUploadError(fileUpload);
                    }
                }
            );
        }
    }

    handleUploadError(fileUpload: FileUpload) {
        this.fileUploadError = true;
        this.filesUploading = [];
        this.uploadError.emit();

        // Attempt to pull error message from the file uploader - fall back to default error message;
        let errorMessage = 'Failed to upload file.';
        if (
            fileUpload.xhr &&
            fileUpload.xhr.response &&
            fileUpload.xhr.response.error &&
            fileUpload.xhr.response.error.message
        ) {
            errorMessage = fileUpload.xhr.response.error.message;
        }

        // Dispatch failure;
        this.store.dispatch(
            new FileUploadFailureAction({
                error: new ErrorWrapper(errorMessage)
            })
        );

        this.ref.detectChanges();
    }

    returnToUploader() {
        this.resetFileErrors();
    }

    resetFileErrors() {
        this.fileUploadError = false;
        this.fileError = false;
        this.fileErrorMessage = '';
        this.selectedFiles = [];
        this.selectedFilesCount = 0;
        this.checkedFilesCount = 0;
        this.verifiedFilesCount = 0;
        this.ref.detectChanges();
    }

    startReplace(files: File[], payload: ReplacePayload) {
        this.fileUploadError = false;
        Array.from(files).forEach(file => {
            this.replaceFile(file, payload);
        });
    }

    downloadFile(doc: any) {
        console.log('download', doc);
    }

    removeFile(doc: any) {
        console.log('remove', doc);
    }

    cancelActiveUpload() {
        if (this.activeFileUpload) {
            this.activeFileUpload.cancel();
            this.store.dispatch(new FileUploadCancelAction());
        }
    }

    cancelAllUploads() {
        this.fileUploadError = false;
        this.cancelActiveUpload();
    }

    replaceFile(file: File, payload: ReplacePayload) {
        this.filesUploading = [file];
        this.activeFileUpload = new FileUpload().upload(
            file,
            `${this.uploadUrl}/${payload.id}/replace`,
            { Authorization: `Bearer ${this.getToken()}` },
            {
                'Content-Type': 'multipart/form-data',
                contextMap: payload.contextMap,
                supplementalFileTypeId: payload.supplementalFileTypeId,
                formId: payload.formId,
                type: this.type ? this.type : undefined,
                firmId: payload.firmId,
                fundId: payload.fundId,
                holdingOptionId: payload.holdingOptionId
            },
            {
                onUploadedCallback: () => {
                    this.filesUploadCount++;

                    if (this.filesUploadCount === this.filesUploading.length) {
                        this.filesUploaded.emit();
                    }

                    this.ref.detectChanges();
                },
                onErrorCallback: (fileUpload: FileUpload) => {
                    this.handleUploadError(fileUpload);
                }
            }
        );
    }

    openFileDialog() {
        if (this.uploadButtonRef) {
            this.uploadButtonRef.nativeElement.click();
        }
    }

    hasFileError() {
        return this.fileError;
    }

    clearFileError() {
        this.fileError = false;
        this.fileErrorMessage = '';
    }

    getDragDropLabel() {
        return this.multiple ? 'Drag multiple files here or' : 'Drag and drop the file here, or';
    }

    getFileLabel() {
        return this.multiple ? 'files' : 'file';
    }

    convertFileSize(fileSize: number) {
        switch (this.fileSizeLimitUnit) {
            case 'MB':
                return fileSize * 0.000001;
            case 'KB':
                return fileSize * 0.001;
            default:
                return fileSize;
        }
    }

    getFileOrientationMesssage(orientationLimit: OrientationLimiters) {
        switch (orientationLimit) {
            case 'both':
                return 'Square or Horizontal';
            case 'square':
                return 'Square';
            case 'horizontal':
                return 'Horizontal';
            default:
                return 'any';
        }
    }

    passesOrientationLimit(fileWidth: number, fileHeight: number) {
        switch (this.orientationLimit) {
            case 'both':
                return fileWidth > fileHeight || fileWidth === fileHeight;
            case 'square':
                return fileWidth === fileHeight;
            case 'horizontal':
                return fileWidth > fileHeight;
            default:
                return true;
        }
    }

    ngOnDestroy() {
        this.cancelActiveUpload();
        this.subscriptions.forEach(sub => sub.unsubscribe());
    }
}
