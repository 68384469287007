<div class="aix-form__container" [data-testing]="config.refId!">
    <!-- Repeater -->
    @for (groupFieldConfig of templateData.compiledTemplate; track trackByFn($index,
    groupFieldConfig)) {

    <div class="aix-repeater" [ngClass]="{ 'aix-hidden': groupFieldConfig.hidden }">
        <!-- Item Label -->
        <div class="flex-between-top u-mb8">
            <h5>
                <span [data-testing]="config.refId! + '__label' + $index"
                    >{{ config.label }} {{ itemCalculatedIndex($index + 1) }}</span
                >
            </h5>

            <!-- Remove Button -->
            @if (!templateData.fieldIsDisabled && canRemoveItem($index)) {
            <a
                class="remove-button br-link u-mt4"
                [data-testing]="config.refId! + '__remove' + $index"
                (click)="removeItem($index)"
            >
                <span class="fa-regular fa-xmark u-mr4"></span> {{ config.removeLabel }}
            </a>
            }
        </div>

        <!-- Group -->
        <ng-container aixDynamicField [config]="groupFieldConfig"></ng-container>
    </div>

    }

    <!-- Add Button -->
    @if (!templateData.fieldIsDisabled) {
    <aix-button
        [data-testing]="config.refId! + '__add'"
        title="{{ !templateData.canAddItem ? cannotAddItemsTooltip() : '' }}"
        (click)="addItem()"
        class="full-width"
        [isDisabled]="!templateData.canAddItem"
        [buttonType]="addLabelButtonType"
        [buttonLabel]="config.addLabel"
        [icon]="'fa-plus'"
    >
    </aix-button>
    }
</div>
