import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, output } from '@angular/core';
import {
    FormsModule,
    ReactiveFormsModule,
    UntypedFormGroup,
    ValidationErrors
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { ApiFormErrorItem, extractStreamValue, LogService } from '@trade-platform/ui-utils';
import { clone } from 'lodash-es';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Logger } from 'typescript-logging';
import { FieldConfig } from '@trade-platform/form-fields';
import { DynamicPendingFieldsManagerService } from './directives/dynamic-pending-fields-manager.service';
import { DynamicFormStore } from './dynamic-form-store';
import {
    DynamicFormControlClearServerValidatorsAction,
    DynamicFormControlInjectServerValidatorAction
} from './dynamic-form-store/actions';
import { DynamicFormCalculatedExpressions } from './dynamic-form-store/calculated-expressions';
import { DynamicFormState, RefId } from './dynamic-form-store/model';
import { DynamicFormRelations } from './dynamic-form-store/relations';
import { DynamicFormHelper, FieldMapType } from './dynamic-form.helper';
import { ObservableMapFuncts, showRelationsHeuristic } from './dynamic-form.utils';
import { DynamicFieldDirective } from './components/dynamic-field.directive';
import { NgFor, NgIf, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';

@Component({
    exportAs: 'dynamicForm',
    selector: 'aix-dynamic-form',
    styleUrls: ['dynamic-form.component.scss'],
    template: `
        @if (___form) {
        <form class="aix-form aix-form--centered u-pb40" [formGroup]="___form">
            @for (field of __config; track field) { @switch (field.type) { @case ('groupLight') {
            <ng-container aixDynamicField [config]="field"> </ng-container>
            } @default { @if (relationsManager.fieldToRelations(field, 'SHOW')) {
            <ng-container aixDynamicField [config]="field"> </ng-container>
            } } } }
            <!-- This container is for extra content -->
            <ng-content></ng-content>
        </form>
        }
    `,
    providers: [
        DynamicFormHelper,
        DynamicFormStore,
        DynamicFormRelations,
        DynamicFormCalculatedExpressions,
        DynamicPendingFieldsManagerService
    ],
    standalone: true,
    imports: [
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        NgSwitch,
        NgSwitchCase,
        NgSwitchDefault,
        DynamicFieldDirective
    ]
})
export class DynamicFormComponent implements OnInit, OnDestroy {
    /**
     * Just a name so we recognize this form when looking at the Store
     */
    @Input()
    formName = '';

    valueChanges = output<any>();
    completedPercentage = output<number>();
    formInitialized = output<boolean>();

    private subscriptions: Subscription[] = [];
    private readonly FORM_PROGRESS_DEBOUNCE_TIME = 200;
    private readonly VALUE_CHANGES_DEBOUNCE_TIME = 200;

    /**
     * Returns the data of all Dynamic Form controls that are not detached or hidden by a relation.
     */
    get value() {
        return clone(extractStreamValue(this.formStore.value$));
    }

    /**
     * Returns the whole Dynamic Form Store data.
     */
    get rawValue() {
        return clone(extractStreamValue(this.formStore.rawValue$));
    }

    get isFormValid() {
        return extractStreamValue(this.formStore.isFormValid$);
    }

    get isFormDirty() {
        return extractStreamValue(this.formStore.isFormDirty$);
    }

    /**
     * @deprecated This is private, don't use it. The only purpose of this variable is to satisfy the <form [formGroup]="___form"> requirement.
     * It's public only because it is used by the template
     */
    ___form = new UntypedFormGroup({});

    /**
     * @deprecated This is private, don't use it. Use the `initializeForm()` method instead.
     * It's public only because it is used by the template
     */
    __config: FieldConfig[] = [];

    readonly LOG: Logger;

    constructor(
        public helper: DynamicFormHelper,
        public store: Store<Record<string, DynamicFormState>>,
        public formStore: DynamicFormStore,
        private pendingManager: DynamicPendingFieldsManagerService,
        public relationsManager: DynamicFormRelations,
        private logService: LogService,
        private cd: ChangeDetectorRef
    ) {
        this.LOG = this.logService.getLogger('components.dynamic-form.dynamic-form.component');
    }

    ngOnInit() {
        this.formStore.setFormName(this.formName);

        this.subscriptions.push(
            this.formStore.value$
                .pipe(debounceTime(this.VALUE_CHANGES_DEBOUNCE_TIME))
                .subscribe(data => {
                    this.valueChanges.emit(data);
                }),
            this.formStore.formProgress$
                .pipe(debounceTime(this.FORM_PROGRESS_DEBOUNCE_TIME))
                .subscribe(progress => {
                    this.completedPercentage.emit(progress);
                }),
            showRelationsHeuristic(this.store, this.formStore.formUID, this.cd),
            this.formStore.isFormInitialized$.subscribe(isInitialized => {
                this.formInitialized.emit(isInitialized);
            })
        );

        this.cd.detach();
    }

    nextPendingField() {
        this.pendingManager.nextField();
    }

    nextBlockingNotification() {
        this.pendingManager.nextBlockingNotification();
    }

    /**
     * Returns the value present at the specified group + name path.
     */
    get<T>(path: string): T {
        return clone(extractStreamValue<T>(this.formStore.getDataStore(path)));
    }

    injectServerValidation(error: ApiFormErrorItem) {
        this.store.dispatch(
            new DynamicFormControlInjectServerValidatorAction(this.formStore.formUID, error)
        );
    }

    clearServerValidation(path: string) {
        this.store.dispatch(
            new DynamicFormControlClearServerValidatorsAction(this.formStore.formUID, path)
        );
    }

    getControlValidationResult(refId: RefId): Readonly<ValidationErrors> | null {
        const ctrl = extractStreamValue(this.formStore.getControlStoreByRefId(refId));
        return ctrl ? clone(ctrl.validation) : null;
    }

    patchValue(value: Record<string, any>) {
        this.LOG.debug('patchValue()');
        // wait a microtask, so we give time to the old form to be destroyed (if needed)
        setTimeout(() => this.formStore.patchStoreValue(clone(value)), 0);
    }

    destroyPreviousForm() {
        this.LOG.debug('destroyPreviousForm()');
        this.formStore.destroyPreviousForm();
    }

    initializeForm(
        value: FieldConfig[],
        data?: Record<string, any>,
        observableMapFuncts?: ObservableMapFuncts,
        actionFactory?: DynamicFormHelper['actionFactory']
    ) {
        this.LOG.debug(() => `initializeForm(): Initializing ${this.formName}`);
        this.formStore.destroyPreviousForm();

        if (actionFactory) {
            this.helper.actionFactory = actionFactory;
        }

        if (observableMapFuncts) {
            this.formStore.setObservableMapFuncts(observableMapFuncts);
            this.helper.setObservableMapFuncts(observableMapFuncts);
        }
        if (data) {
            this.patchValue(data);
        }

        // wait a microtask, so we give time to the old form to be destroyed
        setTimeout(() => {
            this.LOG.debug('initializeForm(): Setting form config');
            this.__config = value;

            this.cd.detectChanges();
        }, 0);
    }

    fieldMap(): { [refId: string]: FieldMapType } {
        return extractStreamValue(this.formStore.fieldMap$);
    }

    oneOfMap(): { [refId: string]: string } {
        return extractStreamValue(this.formStore.oneOfMap$);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
        this.formStore.destroy();
        this.LOG.debug(() => `${this.formName} removed`);
    }
}
