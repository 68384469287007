<ag-grid-angular
    #agGrid
    class="ag-theme-balham aix-grid"
    [class.-no-results]="!rowData()?.length || !gridApi?.getDisplayedRowCount()"
    [context]="context()"
    [ngClass]="appearance() ? '-' + appearance() : ''"
    [rowData]="rowData()"
    [gridOptions]="mergedGridOpts"
    [animateRows]="false"
    [ngStyle]="style()"
    [domLayout]="domLayout()"
    [components]="frameworkComponents()"
    [defaultColDef]="{ resizable: false, filter: false, sortable: sortable() }"
>
</ag-grid-angular>
