import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnChanges,
    output,
    SimpleChanges
} from '@angular/core';
import { commentTag as commentTagTypes } from '@trade-platform/lib-enums';
import {
    FormBuilder,
    FormsModule,
    ReactiveFormsModule,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { AixDataTestingDirective, AixRadioGroupComponent } from '@trade-platform/ui-components';

@Component({
    selector: 'aix-order-form-comment-tags',
    templateUrl: 'order-form-comment-tags.html',
    styleUrls: ['order-form-comment-tags.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, AixRadioGroupComponent, AixDataTestingDirective]
})
export class AixOrderFormCommentTagsComponent implements OnChanges {
    @Input() commentTag: string;
    @Input() isPrivate: boolean | undefined;
    commentTagChange = output<string>();

    commentTypeForm: UntypedFormGroup;

    commentTags: any[] = [
        {
            value: commentTagTypes.change,
            title: 'Needs Changes',
            description:
                'Select this option if material changes are necessary. To resolve a “Needs Changes” comment, the recipient will be required to edit the forms and collect new signatures.'
        },
        {
            value: commentTagTypes.clarification,
            title: 'Needs Clarification',
            description:
                'Select this option to request clarification or a supporting document upload. To resolve a “Needs Clarification” comment, the recipient will be required to reply to the comment.'
        },
        {
            value: commentTagTypes.information,
            title: 'Note',
            description:
                'Select this option to add an informational note. No action will be triggered by this selection.'
        }
    ];

    constructor(private _fb: FormBuilder) {
        this.commentTypeForm = this._fb.group({
            commentType: this._fb.control(null, Validators.required)
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.commentTag) {
            this.commentTypeForm.setValue({ commentType: this.commentTag });
        }
        if (changes.isPrivate) {
            if (this.isPrivate) {
                this.commentTags = [
                    {
                        value: commentTagTypes.information,
                        title: 'Note',
                        description:
                            'Select this option to add an informational note. No action will be triggered by this selection.'
                    }
                ];
            } else {
                this.commentTags = [
                    {
                        value: commentTagTypes.change,
                        title: 'Needs Changes',
                        description:
                            'Select this option if material changes are necessary. To resolve a “Needs Changes” comment, the recipient will be required to edit the forms and collect new signatures.'
                    },
                    {
                        value: commentTagTypes.clarification,
                        title: 'Needs Clarification',
                        description:
                            'Select this option to request clarification or a supporting document upload. To resolve a “Needs Clarification” comment, the recipient will be required to reply to the comment.'
                    },
                    {
                        value: commentTagTypes.information,
                        title: 'Note',
                        description:
                            'Select this option to add an informational note. No action will be triggered by this selection.'
                    }
                ];
            }
        }
    }

    onCommentTagChanged(tag: string) {
        this.commentTag = tag;
        this.commentTagChange.emit(this.commentTag);
    }
}
