import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AixButtonComponent } from './components/aix-button/aix-button.component';
import { AixButtonBarComponent } from './components/aix-button-bar/aix-button-bar.component';
import { AixColorInputComponent } from './components/aix-color-input/aix-color-input.component';
import { AixExpansionPanelComponent } from './components/aix-expansion-panel/expansion-panel';
import { AixDataGridComponent } from './components/aix-data-grid/aix-data-grid.component';
import { AixLoadingComponent } from './components/aix-loading/aix-loading.component';
import { AixModalComponent } from './components/aix-modal/aix-modal.component';
import { AixPaginationComponent } from './components/aix-pagination/aix-pagination.component';
import { AixPdfViewerComponent } from './components/aix-pdf/aix-pdf-viewer.component';
import { AixPdfComponent } from './components/aix-pdf/aix-pdf.component';
import { AixPrintButtonComponent } from './components/aix-print-button/aix-print-button.component';
import { AixSlideToggleComponent } from './components/aix-slide-toggle/aix-slide-toggle.component';
import { AixFootnotesContainerComponent } from './directives/footnote/aix-footnote.component';
import { AixFootnoteDirective } from './directives/footnote/aix-footnote.directive';
import { AixFootnoteService } from './directives/footnote/aix-footnote.service';
import { AixTooltipDirective } from './directives/tooltip/aix-tooltip';
import { AixNotificationComponent } from './components/aix-notification/aix-notification.component';
import { AixUiUtilsModule } from '@trade-platform/ui-utils';
import { AgGridModule } from 'ag-grid-angular';
import { AixLoadingButtonComponent } from './components/aix-loading-button/aix-loading-button.component';
import { AixFilterListComponent } from './components/aix-filter-list/aix-filter-list';
import { DropdownCellrendererDirective } from './components/button-dropdown/dropdown-cellrenderer.directive';
import { AixDropdownComponent } from './components/button-dropdown/dropdown';
import { DefaultDropdownCellrendererComponent } from './components/button-dropdown/default-dropdown.cellrenderer';
import { AixLoadingDirective } from './directives/loading/loading.directive';
import { AixPlaceholderDirective } from './directives/placeholder/placeholder.directive';
import { AixDataTestingDirective } from './directives/data-testing/data-testing.directive';
import { AixImageViewerComponent } from './components/aix-pdf/aix-image-viewer.component';
import { AixSearchBoxComponent } from './components/aix-search-box/aix-search-box.component';
import { AixInlineDropdownComponent } from './components/aix-inline-dropdown/aix-inline-dropdown.component';
import { AixSidebarMenuComponent } from './components/aix-sidebar-menu/sidebar.component';
import { AixTransientDataGridComponent } from './components/aix-transient-data-grid/aix-transient-data-grid.component';
import { AixGridSelectCurrentPageHeaderComponent } from './components/cell-renderers/grid-select-current-page-header.component';
import { AixAssignBoxComponent } from './components/assign-box/assign-box.component';
import { AixReadonlyDataOverviewComponent } from './components/aix-readonly-data-overview/aix-readonly-data-overview.component';
import { AixBreadcrumbsComponent } from './components/aix-breadcrumbs/aix-breadcrumbs.component';
import { AixPageHeaderComponent } from './components/aix-page-header/aix-page-header.component';
import { RouterModule } from '@angular/router';
import { AixDocumentPreviewComponent } from './components/aix-document-preview/aix-document-preview.component';
import { AixFilterPanelComponent } from './components/aix-filter-panel/aix-filter-panel';
import { AixHeaderSectionComponent } from './components/aix-header-section/aix-header-section';
import { AixPageSectionComponent } from './components/aix-page-section/aix-page-section';
import { AixMultipleTextComponent } from './components/aix-multiple-text/multiple.component';
import { AixTabsComponent } from './components/aix-tabs/aix-tabs.component';
import { AixTabComponent } from './components/aix-tabs/aix-tab.component';
import { AixCheckboxRendererComponent } from './components/aix-checkbox-render/aix-checkbox-render.component';
import { AixCheckboxItemComponent } from './components/aix-checkbox-item/aix-checkbox-item.component';
import { AixCurrencyComponent } from './components/aix-currency/aix-currency.component';
import { AixEinComponent } from './components/aix-ein/aix-ein.component';
import { AixSsnComponent } from './components/aix-ssn/aix-ssn.component';
import { AixPhoneComponent } from './components/aix-phone/aix-phone.component';
import { AixIntlPhoneComponent } from './components/aix-intl-phone/aix-intl-phone.component';
import { AixZipComponent } from './components/aix-zip/aix-zip.component';
import { AixDateComponent } from './components/aix-date/aix-date.component';
import { AixPercentageComponent } from './components/aix-percentage/aix-percentage.component';
import { AixNumberComponent } from './components/aix-number/aix-number.component';
import { AixTextareaComponent } from './components/aix-textarea/aix-textarea.component';
import { AixInputComponent } from './components/aix-input/aix-input.component';
import { AixCheckboxComponent } from './components/aix-checkbox/aix-checkbox.component';
import { AixAccountDocumentPreviewComponent } from './components/aix-account-document-preview/aix-account-document-preview.component';
import { AixThumbnailComponent } from './components/aix-thumbnail/aix-thumbnail.component';
import { NgxEditorModule } from 'ngx-editor';
import { AixRichTextComponent } from './components/aix-rich-text/aix-rich-text.component';
import { AixCheckboxPanelComponent } from './components/aix-checkbox-panel/aix-checkbox-panel';
import { AixCheckboxListComponent } from './components/aix-checkbox-list/aix-checkbox-list';
import { AixTruncateLinesCellRendererComponent } from './components/aix-data-grid/aix-truncate-lines-cell-renderer.component';
import { RemoteDataModule } from 'ngx-remotedata';
import { AixRadioGroupComponent } from './components/aix-radio-group/aix-radio-group.component';
import { AixRadioGroupColumnComponent } from './components/aix-radio-group-column/aix-radio-group-column.component';
import { AixCountryCodeDropdownComponent } from './components/aix-country-code-dropdown/aix-country-code-dropdown';
import { AixRadioGroupColumnSectionsComponent } from './components/aix-radio-group-column-sections/aix-radio-group-column-sections.component';
import { AixPendingFieldsManagerService } from './services/pending-fields-manager.service';
import { AixNextPendingFieldDirective } from './directives/next-pending-field/next-pending-field.directive';
import { AixInlineSelectComponent } from './components/aix-inline-select/aix-inline-select.component';
import { AixTagComponent } from './components/aix-tag/aix-tag.component';
import { AixFlagComponent } from './components/aix-flag/aix-flag.component';
import { BannerStylesDirective } from './directives/styles/styles.directive';
import { AixDateFilterComponent } from './components/aix-date-filter/aix-date-filter.component';
import { AixSelectCheckboxFilterComponent } from './components/aix-select-checkbox-filter/aix-select-checkbox-filter.component';

const LIB_PROVIDERS = [
    // Add services
    AixFootnoteService,
    AixPendingFieldsManagerService
];

const DIRECTIVES = [
    DropdownCellrendererDirective,
    AixFootnoteDirective,
    AixTooltipDirective,
    AixLoadingDirective,
    AixPlaceholderDirective,
    AixDataTestingDirective,
    AixNextPendingFieldDirective,
    BannerStylesDirective
];

const PIPES: NgModule['declarations'] = [
    // Add pipes here
];

const ICONS: any = {
    align_center: `<i class="fa-regular fa-align-center br-link" title="align center"></i>`,
    align_justify: `<i class="fa-regular fa-align-justify br-link" title="align justify"></i>`,
    align_left: `<i class="fa-regular fa-align-left br-link" title="align left"></i>`,
    align_right: `<i class="fa-regular fa-align-right br-link" title="align right"></i>`,
    bold: `<i class="fa-regular fa-bold br-link" title="bold"></i>`,
    bullet_list: `<i class="fa-regular fa-list br-link" title="list"></i>`,
    code: `<i class="fa-regular fa-code br-link" title="code"></i>`,
    color_fill: `<i class="fa-regular fa-fill-drip br-link" title="color fill"></i>`,
    format_clear: `<i class="fa-regular fa-eraser br-link" title="format clear"></i>`,
    horizontal_rule: `<i class="fa-regular fa-star-harp fa-regular fa-ruler-horizontal br-link" title="horizontal rule"></i>`,
    image: `<i class="fa-regular fa-image br-link" title="image"></i>`,
    italic: `<i class="fa-regular fa-italic br-link" title="italic"></i>`,
    link: `<i class="fa-regular fa-link br-link" title="link"></i>`,
    ordered_list: `<i class="fa-regular fa-list-ol br-link" title="order list"></i>`,
    blockquote: `<i class="fa-regular fa-quotes br-link" title="quotes"></i>`,
    strike: `<i class="fa-regular fa-strikethrough br-link" title="strike"></i>`,
    text_color: `<i class="fa-regular fa-palette br-link" title="Text color"></i>`,
    underline: `<i class="fa-regular fa-underline br-link" title="Text underline"></i>`,
    unlink: `<i class="fa-regular fa-link-slash br-link" title="unlink"></i>`
};

const COMPONENTS = [
    AixDropdownComponent,
    DefaultDropdownCellrendererComponent,
    AixPrintButtonComponent,
    AixPaginationComponent,
    AixLoadingComponent,
    AixLoadingButtonComponent,
    AixPdfComponent,
    AixPdfViewerComponent,
    AixImageViewerComponent,
    AixFootnotesContainerComponent,
    AixModalComponent,
    AixButtonBarComponent,
    AixDataGridComponent,
    AixColorInputComponent,
    AixSlideToggleComponent,
    AixExpansionPanelComponent,
    AixNotificationComponent,
    AixFilterListComponent,
    AixSearchBoxComponent,
    AixInlineDropdownComponent,
    AixInlineSelectComponent,
    AixSidebarMenuComponent,
    AixTransientDataGridComponent,
    AixAssignBoxComponent,
    AixGridSelectCurrentPageHeaderComponent,
    AixReadonlyDataOverviewComponent,
    AixBreadcrumbsComponent,
    AixPageHeaderComponent,
    AixDocumentPreviewComponent,
    AixFilterPanelComponent,
    AixHeaderSectionComponent,
    AixButtonComponent,
    AixPageSectionComponent,
    AixMultipleTextComponent,
    AixTabsComponent,
    AixTabComponent,
    AixCheckboxRendererComponent,
    AixCheckboxItemComponent,
    AixCurrencyComponent,
    AixEinComponent,
    AixSsnComponent,
    AixPhoneComponent,
    AixIntlPhoneComponent,
    AixCountryCodeDropdownComponent,
    AixZipComponent,
    AixPercentageComponent,
    AixDateComponent,
    AixNumberComponent,
    AixTextareaComponent,
    AixInputComponent,
    AixRadioGroupComponent,
    AixRadioGroupColumnComponent,
    AixRadioGroupColumnSectionsComponent,
    AixCheckboxComponent,
    AixAccountDocumentPreviewComponent,
    AixThumbnailComponent,
    AixRichTextComponent,
    AixCheckboxPanelComponent,
    AixTruncateLinesCellRendererComponent,
    AixCheckboxListComponent,
    AixTagComponent,
    AixFlagComponent,
    AixDateFilterComponent
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        AgGridModule,
        FormsModule,
        AixUiUtilsModule,
        ReactiveFormsModule,
        NgxEditorModule.forRoot({ icons: ICONS }),
        RemoteDataModule,
        ...COMPONENTS,
        ...DIRECTIVES,
        ...PIPES
    ],
    exports: [...COMPONENTS, ...DIRECTIVES, ...PIPES],
    providers: LIB_PROVIDERS
})
export class AixUiComponentsModule {}
