import { ChangeDetectionStrategy, Component, Inject, Input, output } from '@angular/core';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';

@Component({
    selector: 'aix-e-sign',
    templateUrl: './e-sign.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixButtonComponent, AixDataTestingDirective]
})
export class AixOrderProcessESignComponent {
    @Input() signaturesCompleted = false;
    @Input() isReadOnly = false;

    onClickContinue = output();

    continueButtonType = BUTTON_TYPE.primary;

    constructor(@Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade) {}
}
