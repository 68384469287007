<div class="order-form-comments__comment-container">
    <aix-modal
        #deleteModal
        [modalTitle]="'Remove comment?'"
        modalType="confirmation"
        (optionSelected)="modalSelected($event)"
    >
        <ng-template>
            <span class="u-fw500">This comment will be removed.</span>
            <div><p>You can edit existing comments or add new comments.</p></div>
        </ng-template>
    </aix-modal>

    <div data-testing="comment-reply" [ngClass]="{ '-comment--reply': comment.parentId }">
        <div class="flex-right-center">
            <h6 data-testing="comment-item-organization">{{ comment.organizationName }}</h6>
            <div class="flex-spacer"></div>
            @if (!comment.readOnly) {
            <div class="order-form-comments__comment-icons">
                <i
                    class="order-form-comments__comment-icon fa-regular fa-pen-to-square br-primary__color u-pr16"
                    data-testing="edit-comment-button"
                    (click)="editComment()"
                ></i>
                <i
                    class="order-form-comments__comment-icon fa-regular fa-trash-can br-primary__color"
                    data-testing="delete-comment-button"
                    (click)="onClickDelete(comment)"
                ></i>
            </div>
            }
        </div>

        <p class="-text--info" data-testing="comment-item-date">
            {{ commentDate | dfnsFormat : 'MM/dd/yyyy - hh:mm a' }}
        </p>
        @if (!editModeActive && !comment.parentId && commentTagInfo) {
        <span
            data-testing="comment-item-type"
            class="aix-pill u-inline__block u-mt6 u-mb6"
            [ngClass]="commentTagInfo.class"
        >
            {{ commentTagInfo.label }}
        </span>
        }
        <p>
            @if (comment.commentType !== commentTypes.systemGenerated) {
            <a
                data-testing="comment-item-document"
                class="br-link"
                (click)="transmitCommentAction('link', comment)"
            >
                {{ comment.documentName }}
            </a>
            }
        </p>
        @if (!editModeActive) {
        <p data-testing="comment-item-message" class="order-form-comments__comment-text">
            {{ comment.text }}
        </p>
        } @if (editModeActive) {
        <textarea
            class="order-form-comments__input order-form-comments__input__text-area"
            data-testing="edit-comment-text-area"
            [(ngModel)]="commentText"
        >
        </textarea>
        } @if (editModeActive && !comment.parentId) {
        <aix-order-form-comment-tags
            [(commentTag)]="commentTag"
            [isPrivate]="isPrivate"
        ></aix-order-form-comment-tags>
        }
    </div>

    @if (editModeActive) {
    <div class="aix-toolbar u-full-width u-text-right u-mt16">
        <aix-button
            (click)="onCancelEdit()"
            data-testing="cancel-save-comment-button"
            [buttonType]="cancelButtonType"
            [buttonLabel]="'Cancel'"
        >
        </aix-button>
        <aix-button
            (click)="onClickSave(comment)"
            data-testing="save-comment-button"
            [isDisabled]="commentText.trim().length === 0"
            [buttonType]="saveButtonType"
            [buttonLabel]="'Save'"
        >
        </aix-button>
    </div>
    }

    <!-- Replies Accordion -->
    <ng-template #commentChildrenHeader>
        <div class="flex-center-center">
            <span class="bold">{{ commentReplyLabel }}</span>
        </div>
    </ng-template>
    <ng-template #commentChildrenBody>
        <aix-order-form-comments-list
            [comments]="comment.children"
            [showSystemUpdates]="false"
            [commentRef]="commentRef"
            [mode]="'modify'"
        >
            <ng-template #commentRef let-comment>
                <aix-order-form-comment
                    [comment]="comment"
                    [canReply]="false"
                    (onCommentAction)="onCommentAction.emit($event)"
                >
                </aix-order-form-comment>
            </ng-template>
        </aix-order-form-comments-list>
    </ng-template>
    @if (comment.children && comment.children.length > 0) {
    <aix-expansion-panel
        [headerTemplate]="commentChildrenHeader"
        [headerNgClass]="commentChildrenHeaderClass"
        [bodyTemplate]="commentChildrenBody"
        [bodyNgClass]="commentChildrenBodyClass"
        [arrowNgClass]="commentChildrenArrowClass"
        [arrowPosition]="'left'"
        [isClosed]="isCommentChildrenClosed"
        [isDisabled]="false"
        (isClosedChange)="isCommentChildrenClosed = $event"
    >
    </aix-expansion-panel>
    }

    <!-- Replies Add -->
    @if (replyModeActive) {
    <textarea
        class="order-form-comments__input order-form-comments__input__text-area u-mt16"
        data-testing="reply-input"
        placeholder="Add a reply"
        [(ngModel)]="commentReplyText"
    >
    </textarea>
    } @if (canReply && commentTag === commentTagTypes.clarification) {
    <div class="aix-toolbar u-full-width u-text-right u-mt16">
        @if (replyModeActive) {
        <aix-button
            data-testing="cancel-reply-button"
            (click)="onCancelReply()"
            [buttonLabel]="'Cancel'"
            [buttonType]="cancelButtonType"
        >
        </aix-button>
        }
        <aix-button
            (click)="onClickReply()"
            [buttonLabel]="'Reply'"
            [attr.data-testing]="replyModeActive ? 'reply-message-button' : 'reply-button' "
            [buttonType]="replyModeActive ? replyPrimaryButtonType : replySecondaryButtonType"
            [isDisabled]="replyModeActive && commentReplyText.trim().length === 0"
        >
        </aix-button>
    </div>
    }
</div>
