import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchHttpError } from '../utils/catch-http-error';
import {
    LoadPlanByTaxIdAction,
    LoadPlanByTaxIdFailureAction,
    LoadPlanByTaxIdSuccessAction,
    PlanActionTypes
} from './actions';
import { PlanService } from './service';

@Injectable()
export class PlanEffects {
    constructor(private actions$: Actions, private service: PlanService) {}

    loadPlanByTaxId$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadPlanByTaxIdAction>(PlanActionTypes.LOAD_PLAN_BY_TAX_ID),
            switchMap(action =>
                this.service.getPlanByTaxId(action.payload.taxId, action.payload.planNumber).pipe(
                    map(plan => new LoadPlanByTaxIdSuccessAction({ plan: plan })),
                    catchHttpError(error => of(new LoadPlanByTaxIdFailureAction({ error: error })))
                )
            )
        )
    );
}
