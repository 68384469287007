import {
    Component,
    forwardRef,
    Input,
    OnChanges,
    OnDestroy,
    output,
    SimpleChanges
} from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import {
    AbstractControl,
    ControlValueAccessor,
    FormsModule,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
    Validators
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { isActiveInvestor, ProfileRepCode, UserByAdvisor } from '@trade-platform/ui-shared';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import {
    AixCurrencyComponent,
    AixDataTestingDirective,
    AixDropdownComponent,
    AixNextPendingFieldDirective,
    AixRadioGroupComponent
} from '@trade-platform/ui-components';
import { NgIf } from '@angular/common';

@Component({
    selector: 'aix-advisor-information',
    templateUrl: './advisor-information.html',
    styleUrls: ['./advisor-information.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AixAdvisorInformationGroupComponent),
            multi: true
        },
        {
            provide: NG_VALIDATORS,
            useExisting: AixAdvisorInformationGroupComponent,
            multi: true
        }
    ],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AixRadioGroupComponent,
        AixDataTestingDirective,
        NgIf,
        AixDropdownComponent,
        AixCurrencyComponent,
        AixNextPendingFieldDirective
    ]
})
export class AixAdvisorInformationGroupComponent
    implements ControlValueAccessor, OnChanges, OnDestroy
{
    // TODO: Remove any, add compatible interfaces between purchase and maintenance
    // For example: StartNewOrder interface for StartPurchaseOrder and StartMaintenanceOrder
    @Input() startOrder: any;
    @Input() advisors: UserByAdvisor[] = [];

    onSelectRepCode = output<string>();
    loadTrainingData = output();

    form: UntypedFormGroup;

    repCode: string | null;
    advisorTypes = [
        {
            value: 'brokerDealer',
            title: 'Broker Dealer'
        },
        {
            value: 'registeredInvestmentAdvisor',
            title: 'Registered Investment Advisor'
        }
    ];

    subscriptions: Subscription[] = [];

    private _onChange: (value: any | null | undefined) => void;
    private _destroy$: Subject<void> = new Subject<void>();

    constructor(private _fb: UntypedFormBuilder, public profileStore: ProfileStoreFacade) {
        this._createFormGroup();
        this._setupObservables();
    }

    selectRepCode(value: ProfileRepCode) {
        if (value) {
            // First check if the advisor value changed;
            // If it changed, clear the selected advisor;
            // Active investors can't change the advisor
            if (
                !isActiveInvestor(this.profileStore.profile) &&
                value.id &&
                this.repCode !== value.id
            ) {
                this.repCode = null;
                this.form.patchValue({ advisor: '' });
            }

            this.onSelectRepCode.emit(value.id);
        }
    }

    selectAdvisor(value: UserByAdvisor) {
        const repCode = this.form.get('repCode')?.value;
        if (value && repCode?.length) {
            this.repCode = repCode[0]?.id as string;
            this.loadTrainingData.emit();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.startOrder) {
            this.patchForm();
        }
    }

    patchForm() {
        const data = {};

        this.form.patchValue(data);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());

        if (this._destroy$ && !this._destroy$.closed) {
            this._destroy$.next();
            this._destroy$.complete();
        }
    }

    writeValue(value: any | null | undefined): void {
        if (!value) {
            return;
        }
    }

    validate(control: AbstractControl) {
        return this.form.valid ? null : { invalid: true };
    }

    registerOnChange(fn: (value: any | null | undefined) => void): void {
        this._onChange = fn;
    }

    registerOnTouched(fn: any): void {
        // TODO: implement this method
        // throw new Error('registerOnTouched not implemented');
    }

    private _createFormGroup() {
        this.form = this._fb.group({
            firmType: this._fb.control(null, [Validators.required]),
            repCode: this._fb.control('', [Validators.required]),
            advisor: this._fb.control('', [Validators.required])
        });
    }

    private _setupObservables() {
        this.form.valueChanges.pipe(takeUntil(this._destroy$)).subscribe(value => {
            if (this._onChange) {
                this._onChange(value);
            }
        });
    }
}
