import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { InactivityService } from './inactivity-service';

@Injectable()
export class InactivityInterceptor implements HttpInterceptor {
    constructor(public inactivityService: InactivityService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            map(resp => {
                if (resp instanceof HttpResponse) {
                    const inactivityTimeout = resp.headers.get('inactivity-timeout');
                    if (inactivityTimeout) {
                        // NOTE: Use new Number() to handle large numbers with "e" in them, since parseInt() doesn't work in that case;
                        this.inactivityService.startInactivityTimer({
                            inactivityTimeout: Number(inactivityTimeout) as number
                        });
                    }
                }
                return resp;
            })
        );
    }
}
