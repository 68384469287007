import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchHttpError } from '@trade-platform/ui-shared';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import {
    LoadOrdersAdvancedFilters,
    LoadOrdersAdvancedFiltersActionTypes,
    LoadOrdersAdvancedFiltersFailure,
    LoadOrdersAdvancedFiltersSuccess
} from './actions';
import { OrdersAdvancedFiltersService } from './service';

@Injectable()
export class OrdersAdvancedFiltersEffects {
    constructor(private actions$: Actions, private service: OrdersAdvancedFiltersService) {}

    loadOrdersAdvancedFiltersEffects$ = createEffect(() =>
        this.actions$.pipe(
            ofType<LoadOrdersAdvancedFilters>(
                LoadOrdersAdvancedFiltersActionTypes.LOAD_ORDERS_ADVANCED_FILTERS
            ),
            switchMap(action =>
                this.service.getFilters().pipe(
                    map(filters => new LoadOrdersAdvancedFiltersSuccess(filters)),
                    catchHttpError(error => of(new LoadOrdersAdvancedFiltersFailure({ error })))
                )
            )
        )
    );
}
