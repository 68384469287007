<!-- Order Header -->
<aix-page-header
    header="Orders"
    [breadcrumbs]="[
        {
            type: 'link',
            value: {
                routerLink: '/orders/' + store.type + '/status',
                text: 'Orders'
            }
        },
        {
            type: 'text',
            value: {
                text: (store.orderSuccess$ | async) ? (breadcrumbText | titlecase) + ' Order #' + (store.orderSuccess$ | async)?.orderNumber + '-' + (store.orderSuccess$ | async)?.title : ''
            }
        }
    ]"
>
</aix-page-header>

<main class="-no-background">
    <div class="container order">
        <div class="order-container order-container__no-padding">
            @if (((isLoading$ | async) || savingProgress) || !isFormInitiated) {
            <aix-loading [relative]="true"></aix-loading>
            }
            <aix-error-box [states]="states"></aix-error-box>

            <div class="aix-card-sticky u-top40">
                <div class="aix-card aix-card--flex u-mb0">
                    <aix-page-section [pageClasses]="'-no-padding -no-border'">
                        <ng-container header>
                            <h2 [ngClass]="cssClasses()">
                                {{ (store.orderFormSuccess$ | async)?.name }}
                            </h2>
                        </ng-container>
                        <ng-container controls>
                            @if (totalComments > 0) {
                            <aix-step-progress
                                [maxSteps]="totalComments"
                                [progress]="totalComments - getUnresolvedComments()"
                                [sqSize]="80"
                                [strokeWidth]="6"
                                [strokeColor]="'u-warning-orange--stroke'"
                                [fontSize]="7.5"
                                [showTotal]="false"
                                type="circular"
                                [label]="(getUnresolvedComments() === 1) ? 'Action Item' : 'Action Items'"
                                class="u-pl24"
                            >
                            </aix-step-progress>
                            }

                            <aix-progress
                                [progress]="progress"
                                [sqSize]="80"
                                [strokeWidth]="6"
                                [fontSize]="7.5"
                                type="circular"
                                class="u-pl10"
                            >
                            </aix-progress>
                        </ng-container>
                    </aix-page-section>
                </div>

                @if (isReadOnly) {
                <aix-notification
                    class="order-process-notification"
                    status="alert"
                    data-testing="read-only-notification"
                    [canClose]="false"
                    [isOpen]="true"
                >
                    You have read-only access to this order. You can view but not make changes.
                </aix-notification>
                }
                <aix-notification #prefillNotification status="alert" [canClose]="true">
                    <p>{{ prefillText }}</p>
                </aix-notification>
                <aix-notification
                    #notification
                    status="warning"
                    [canClose]="false"
                    [hidden]="!notificationText"
                >
                    {{ notificationText }}
                </aix-notification>
                @if ((store?.orderCancelRemoteData$ | async)! | isFailure) {
                <aix-notification status="error" [canClose]="true" [isOpen]="true">
                    <strong>{{ ERROR_CANCEL_ORDER_MESSAGE }}</strong>
                    <p class="u-mt8" [innerHTML]="ERROR_CONTACT | sanitizeHtml"></p>
                </aix-notification>
                }
            </div>

            <div class="aix-card flex-fill order-process-card">
                <p class="order-process-directions">
                    Please complete the fields below. All required fields are marked with an
                    asterisk (*).
                </p>

                <aix-dynamic-form
                    #dynamicFormRef
                    formName="Order Process Form"
                    (completedPercentage)="updateProgress($event)"
                    (formInitialized)="onFormInitiated($event)"
                >
                </aix-dynamic-form>
            </div>

            <div class="u-sticky-bottom u-mb0" [attr.hidden]="nextForm ? null : ''">
                <div class="aix-card u-mb0 aix-flex-grid__col--full flex-between-center">
                    <aix-button
                        (click)="goToNextField()"
                        [isDisabled]="(isLoading$ | async)"
                        [buttonType]="goToNextFieldButtonType"
                        [buttonLabel]="'Go to Next Field'"
                    >
                    </aix-button>

                    <div>
                        <aix-button
                            (click)="onSaveProgress()"
                            [isDisabled]="(isLoading$ | async) || isReadOnly || !isDirty() || !isFormInitiated"
                            [buttonType]="saveProgressButtonType"
                            [buttonLabel]="'Save Progress'"
                        >
                        </aix-button>

                        <aix-button
                            (click)="onContinue()"
                            [isDisabled]="hasUnresolvedComments() || (isLoading$ | async) || isReadOnly || !isFormInitiated"
                            class="u-ml24"
                            [buttonType]="continueProgressButtonType"
                            [buttonLabel]="'Continue'"
                            data-testing="continue-button"
                        >
                        </aix-button>
                    </div>
                </div>
                <aix-notification
                    #serverValidationErrorNotification
                    status="warning"
                    [canClose]="true"
                    [isOpen]="false"
                >
                    @for (message of serverValidationErrorNotificationMessages; track message) {
                    <div>
                        <span [innerHTML]="message | aixSanitize"></span>
                        <div
                            [ngClass]="{ 'u-mb8': serverValidationErrorNotificationMessages.length > 1 }"
                        ></div>
                    </div>
                    } @if (serverValidationErrorNotificationMessages.length === 0) {
                    <div>
                        <span
                            [innerHTML]="defaultServerValidationErrorNotificationMessage | aixSanitize"
                        ></span>
                    </div>
                    }
                    <p class="u-fw500">Changes will not be saved until all errors are resolved.</p>
                </aix-notification>
                <!-- Progress notification-->
                <aix-notification
                    #progressNotification
                    [status]="progressNotificationConfig.status"
                >
                    {{ progressNotificationConfig.message }}
                </aix-notification>
            </div>
        </div>
        <div class="order-cards">
            <div class="aix-card aix-card-center aix-card-sticky u-top40 u-mb0">
                <form #resolveCommentsForm [formGroup]="commentsForm">
                    @if (!(store.orderFormRemoteData$ | async | isSuccess)) {
                    <aix-loading [relative]="true"></aix-loading>
                    }
                    <aix-sidebar
                        [parentForm]="commentsForm"
                        [comments]="activeComments"
                        [isReadOnly]="isReadOnly"
                        (clickElem)="gotoForm($event)"
                        (clickCancel)="cancelOrder()"
                    >
                    </aix-sidebar>
                </form>
            </div>
        </div>
    </div>
</main>

<aix-modal
    #modal
    [buttonOptions]="modalActive.buttons"
    [modalTitle]="modalActive.title"
    (optionSelected)="modalSelected($event)"
    modalType="confirmation"
>
    <ng-template>
        <div><p class="modal-title u-mt16">{{ modalActive.content }}</p></div>
    </ng-template>
</aix-modal>

<aix-modal
    #cancelOrderConfirmModal
    modalType="confirmation"
    [modalTitle]="CONFIRM_ORDER_CANCELLATION_TITLE"
    (optionSelected)="cancelOrderConfirmModalSelected($event)"
>
    <ng-template>
        <p class="u-fw500">{{ CONFIRM_ORDER_CANCELLATION_MSG }}</p>
        <p>{{ CONFIRM_ORDER_CANCELLATION_MSG_SUB }}</p>
    </ng-template>
</aix-modal>

<aix-cancel-changes-modal
    #cancelChangesModal
    (optionSelected)="cancelChangesModalSelected($event)"
></aix-cancel-changes-modal>
