import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    input,
    model,
    output,
    TemplateRef
} from '@angular/core';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-expansion-panel',
    templateUrl: './expansion-panel.html',
    styleUrls: ['./expansion-panel.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [AixDataTestingDirective, NgClass, NgIf, NgTemplateOutlet]
})
export class AixExpansionPanelComponent {
    collapse = output();
    expand = output();
    isClosedChange = output<boolean>();

    isClosed = model<boolean>(false);
    isDisabled = input<boolean>(false);
    arrowPosition = input<'left' | 'right' | 'none'>('left');
    arrowNgClass = input<NgClass['ngClass']>({ 'u-color-tertiary-black': true });
    headerNgClass = input<NgClass['ngClass']>();
    headerTemplate = input<TemplateRef<any> | null>();
    headerTemplateContext = input<any>();
    bodyNgClass = input<NgClass['ngClass']>();
    bodyTemplate = input<TemplateRef<any>>();

    toggle() {
        this.isClosed.set(!this.isClosed());
        this.emitAction();
    }

    emitAction() {
        this.isClosedChange.emit(this.isClosed());
        if (this.isClosed()) {
            this.collapse.emit();
        } else {
            this.expand.emit();
        }
    }
}
