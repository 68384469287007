import {
    Component,
    EventEmitter,
    Input,
    OnInit,
    output,
    Output,
    ViewEncapsulation
} from '@angular/core';
import { eSignEnvelopeRecipient } from '@trade-platform/ui-shared';
import { recipientStatus } from '@trade-platform/lib-enums';
import { format } from 'date-fns';
import { AixDataTestingDirective } from '@trade-platform/ui-components';
import { AixPhonePipe } from '@trade-platform/dynamic-forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'aix-signer',
    templateUrl: './signer.component.html',
    styleUrls: ['./signer.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [AixDataTestingDirective, NgIf, AixPhonePipe]
})
export class AixSignerComponent implements OnInit {
    @Input() recipient: eSignEnvelopeRecipient;
    @Input() showSending = false;
    onMakeChanges = output<string>();

    recipientTimestamp: string | null = '';
    recipientStatus = recipientStatus;

    ngOnInit() {
        this.recipientTimestamp = this.recipient.timestamp
            ? format(new Date(this.recipient.timestamp), 'M/d/yyyy h:mm a')
            : null;
    }
}
