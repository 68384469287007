import {
    ChangeDetectionStrategy,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BaseOrder, ErrorWrapper, OrderSigningMethod } from '@trade-platform/ui-shared';
import { DetailViewState } from '../../overview';
import { fileType, orderStatus } from '@trade-platform/lib-enums';
import { AixOrderStepComponent } from '../step/order-step';
import {
    AixButtonComponent,
    AixDataTestingDirective,
    AixExpansionPanelComponent,
    AixHeaderSectionComponent,
    BUTTON_TYPE
} from '@trade-platform/ui-components';
import { RemoteData } from 'ngx-remotedata';
import { ActivatedRoute } from '@angular/router';
import { AixTemplatesComponent } from '@advisor-ui/app-components';
import { NgIf } from '@angular/common';
import { AixPdfGenerationComponent } from '../../pdf-generation/pdf-generation';
import { AixFormUploadComponent } from '../../../../form-upload/form-upload';
import { AixOrderProcessDeliveryComponent } from '../../delivery/delivery';
import { AixMapSignaturesComponent } from '../../docusign/map-signatures/map-signatures';
import { AixOrderProcessDocuSignRecipientsComponent } from '../../docusign/recipients/recipients';
import { AixOrderProcessDocuSignSignaturesComponent } from '../../docusign/signatures/signatures';
import { AixOrderProcessESignComponent } from '../../e-sign/e-sign';
import { AixOrderProcessWetSignComponent } from '../../wet-sign/wet-sign';
import { AixFileUploadComponent } from '../../../../file-upload/file-upload';
import { DocuSignForm } from '../../../../store/order-docusign/service';
import { areFormUploadsCompletedInCurrentStep } from '../../utils/order-utils';
import { AixOrdersDetailUploadDocumentsComponent } from '../../../../detail/upload-documents/upload-documents';

@Component({
    selector: 'aix-signatures-step',
    templateUrl: './signatures-step.html',
    styleUrls: ['./signatures-step.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        AixTemplatesComponent,
        NgIf,
        AixPdfGenerationComponent,
        AixHeaderSectionComponent,
        AixDataTestingDirective,
        AixButtonComponent,
        AixFormUploadComponent,
        AixOrderProcessDeliveryComponent,
        AixMapSignaturesComponent,
        AixOrderProcessDocuSignRecipientsComponent,
        AixOrderProcessDocuSignSignaturesComponent,
        AixFileUploadComponent,
        AixOrderProcessESignComponent,
        AixOrderProcessWetSignComponent,
        AixExpansionPanelComponent,
        AixOrdersDetailUploadDocumentsComponent
    ]
})
export class AixSignaturesStepComponent extends AixOrderStepComponent implements OnInit, OnDestroy {
    readonly reducerSuffix = this.storeFacade.type;

    @ViewChild('orderFormUpload')
    orderFormUpload: AixFileUploadComponent;

    @ViewChild('orderFormUploadPrincipal')
    orderFormUploadPrincipal: AixFileUploadComponent;

    // upload
    @ViewChild('orderFileUploadRef')
    orderFileUploadRef: AixFileUploadComponent;
    uploadButtonType = BUTTON_TYPE.link;

    viewState: DetailViewState = 'preview';

    filesGenerated = true;
    orderStatus = orderStatus;
    signaturesCompleted = false;
    eSignWithWetSignEnabled = false;
    eSignWithWetSignCompleted = false;
    wetSignOnly = false;
    subscriptions: Subscription[] = [];
    linkButtonType = BUTTON_TYPE.link;

    docuSignError: Observable<RemoteData<any, string | ErrorWrapper>>[] = [
        this.storeFacade.orderDocusignSendRemoteData$
    ];

    ngOnInit() {
        this.subscriptions.push(
            (this.route.parent as ActivatedRoute).queryParams.subscribe(params => {
                if (params.mappingCompleted === 'true') {
                    this.storeFacade.actions.finishMapSignatures.dispatch({
                        orderId: this.orderId
                    });
                    this.router.navigate(
                        this.storeFacade.routes.process.collectSignatures(this.orderId)
                    );
                }
            }),
            this.storeFacade.actions.sendDocusign.success$.subscribe(action => {
                this.loadOrder();
            }),
            this.storeFacade.actions.mapSignatures.success$.subscribe(action => {
                // Redirect user to DocuSign map signatures url
                window.location.href = action.payload;
            }),
            this.storeFacade.actions.finishMapSignatures.success$.subscribe(action => {
                this.loadOrder();
            }),
            this.storeFacade.actions.signingMethod.success$.subscribe(action => {
                this.loadOrder();
            }),
            this.storeFacade.actions.takeOrderOffline.success$.subscribe(action => {
                this.downloadTradePackage();
                this.loadOrder();
            }),
            this.storeFacade.actions.removeDocument.success$.subscribe(action => {
                this.loadOrder();
            }),
            this.storeFacade.actions.getOrder.success$.subscribe(action => {
                this.onOrderUpdated(action.payload.order);
            })
        );

        this.order = this.storeFacade.order;
        if (this.order) {
            this.onOrderUpdated(this.order);
        }
    }

    onOrderUpdated(order: BaseOrder): void {
        if (order.eSign?.isAvailable === false) {
            this.wetSignOnly = true;
        }

        if (order.status === orderStatus.pendingSignatures) {
            // Form is not onboarded and the envelope is not sent yet
            if (
                Object.values(order.forms).some(form => form.isOnboarded === false) &&
                !order.eSign?.envelope?.status
            ) {
                this.viewState = 'map-signatures';
            } else {
                this.viewState = 'docu-sign';
                this.eSignWithWetSignEnabled = !!order.eSign?.wetSignRoles?.length;
                this.eSignWithWetSignCompleted = this.eSignWithWetSignEnabled
                    ? areFormUploadsCompletedInCurrentStep(order)
                    : true;
            }
        } else if (order.status === orderStatus.readyToSend) {
            if (Object.values(order.forms).some(form => form.isOnboarded === false)) {
                if (
                    order.eSign?.isSelected &&
                    order.eSign?.mappedSignatures?.status === 'Complete'
                ) {
                    this.viewState = 'docu-sign';
                } else if (order.eSign?.isSelected) {
                    this.viewState = 'map-signatures';
                } else if (order.eSign?.isSelected === false) {
                    this.viewState = 'wet-sign';
                } else {
                    this.viewState = 'preview';
                }
            } else {
                if (order.eSign?.isSelected) {
                    this.viewState = Object.keys(order.forms).some(
                        formId => order.forms[formId].isOnboarded === false
                    )
                        ? 'map-signatures'
                        : 'docu-sign';
                } else if (order.eSign?.isSelected === false) {
                    this.viewState = 'wet-sign';
                } else {
                    this.viewState = 'preview';
                }
            }
        }

        this.ref.detectChanges();
    }

    onClickESign() {
        const order = this.storeFacade.order;
        for (const file of order.files) {
            const isOnboarded = order.forms[file.formId as string]?.isOnboarded ?? true;

            if (
                (file.isUserUploaded && file.type === fileType.form && isOnboarded) ||
                (file.type === fileType.unassignedForm &&
                    file.orderStatus === orderStatus.readyToSend)
            ) {
                this.storeFacade.actions.removeDocument.dispatch({
                    orderId: order.id,
                    fileToRemove: file
                });
            }
        }
        this.viewState = 'preview';
        this.clearSigningMethod();
    }

    onClickContinue(): void {
        const order = this.storeFacade.order;
        if (order) {
            this.storeFacade.actions.signingComplete.dispatch({ orderId: order.id });
        }
    }

    onSelectSigningMethod(state: DetailViewState) {
        const order = this.storeFacade.order;
        const signingMethod: { [key: string]: OrderSigningMethod } = {
            'docu-sign': 'eSign',
            'wet-sign': 'wetsign'
        };

        this.storeFacade.actions.signingMethod.dispatch({
            orderId: order.id,
            signingMethod: signingMethod[state]
        });
    }

    clearSigningMethod() {
        const order = this.storeFacade.order;
        this.storeFacade.actions.signingMethod.dispatch({
            orderId: order.id,
            signingMethod: null
        });
        if (order.eSign?.mappedSignatures && order.eSign.envelope?.id) {
            this.storeFacade.actions.cancelEnvelope.dispatch({
                orderId: order.id,
                voidedReason: 'User cancelled'
            });
        }
    }

    setSignaturesCompleted() {
        const order = this.storeFacade.order;
        this.storeFacade.actions.getOrder.dispatch({
            orderId: order.id,
            reducerSuffix: this.reducerSuffix
        });
        this.signaturesCompleted = true;
        this.ref.detectChanges();
    }

    onSubmitDocuSign(form: DocuSignForm) {
        this.storeFacade.actions.sendDocusign.dispatch({ ...form, orderId: this.orderId });
    }

    onSubmitMapSignatures() {
        this.storeFacade.actions.mapSignatures.dispatch({ orderId: this.orderId });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.storeFacade.actions.sendDocusign.reset();
    }
}
