<aix-templates #aixTemplates (onInit)="aixTemplatesInitialized = true"></aix-templates>

<ng-template #uploadsStepContent>
    <div class="order-step-content">
        <div class="u-flex">
            @if (totalUnresolvedFileComments <= 0) {
            <aix-step-progress
                [maxSteps]="unfinishedUploadsTotal"
                [progress]="unfinishedUploadsProgress"
                [sqSize]="130"
                [strokeWidth]="10"
                [fontSize]="10"
                class="u-pr32"
                type="circular"
            >
            </aix-step-progress>
            } @if (totalUnresolvedFileComments > 0) {
            <aix-step-progress
                [maxSteps]="totalFileComments"
                [progress]="totalFileComments - totalUnresolvedFileComments"
                [sqSize]="130"
                [strokeWidth]="10"
                [strokeColor]="'u-warning-orange--stroke'"
                [fontSize]="10"
                [showTotal]="false"
                class="u-pr32"
                [label]="(totalUnresolvedFileComments === 1) ? 'Action Item' : 'Action Items'"
                type="circular"
            >
            </aix-step-progress>
            } @if (totalUnresolvedFileComments > 0) {
            <p class="order-step-upload-description" data-testing="uploads-required-message">
                Your order requires attention. Please address the action items to continue.
            </p>
            } @if (totalUnresolvedFileComments <= 0) {
            <div class="order-step-upload-description" data-testing="uploads-completed-message">
                <p>{{ getUploadLabel() }}</p>
                @for (item of requiredFileInfo | keyvalue;track item) {
                <div class="u-mt24">
                    <h5 class="u-mb16">{{ item.key }}</h5>
                    @for (entity of item.value;track entity) {
                    <div>
                        @if (!hideUploadDescriptionOwner) {
                        <p class="u-fw500 u-mt8">{{ entity.entityName }}</p>
                        }
                        <ul class="u-pl20">
                            @for (document of entity.documents;track document) {
                            <li>
                                <div [innerHTML]="document"></div>
                            </li>
                            }
                        </ul>
                    </div>
                    }
                </div>
                }
            </div>
            }
        </div>

        <div class="order-step-forms u-mt40">
            @if (arrayRequiredFiles.length > 0 || arrayFiles.length > 0) {
            <aix-header-section
                [sectionClasses]="'u-mb0'"
                [title]="'Supporting Documents'"
                data-testing="filelist-header"
            >
                <aix-button
                    [buttonType]="uploadButtonType"
                    [buttonLabel]="'Upload'"
                    icon="'fa-upload u-mr4'"
                    data-testing="upload-button"
                    [isDisabled]="orderFileUploadRef.fileUploadOpen || isReadOnly"
                    (click)="uploadDocuments()"
                >
                </aix-button>
            </aix-header-section>
            } @if (!arrayFiles?.length && !arrayRequiredFiles?.length) {
            <div class="aix-toolbar">
                <aix-button
                    (click)="uploadDocuments()"
                    data-testing="upload-documents-button"
                    [isDisabled]="isReadOnly"
                    [buttonType]="uploadDocumentsButtonType"
                    [buttonLabel]="'Upload Documents'"
                >
                </aix-button>

                <aix-button
                    (click)="finish()"
                    data-testing="continue-button"
                    [isDisabled]="unfinishedUploads || this.totalUnresolvedFileComments > 0 || isReadOnly"
                    [buttonType]="continueButtonType"
                    [buttonLabel]="'Continue'"
                >
                </aix-button>
            </div>
            }

            <aix-file-upload
                #orderFileUploadRef
                [isReadOnly]="isReadOnly"
                [orderFileComments]="orderComments"
                [enableIgnore]="true"
            >
            </aix-file-upload>
        </div>

        @if (arrayFiles?.length || arrayRequiredFiles?.length) {
        <div class="aix-toolbar u-mt24">
            @if (!unfinishedRequiredUploads) {
            <div>
                <aix-button
                    (click)="finish()"
                    data-testing="continue-button"
                    [isDisabled]="unfinishedUploads || this.totalUnresolvedFileComments > 0 || isReadOnly"
                    [buttonType]="continueButtonType"
                    [buttonLabel]="'Continue'"
                >
                </aix-button>
            </div>
            } @if (unfinishedRequiredUploads) {
            <aix-button
                (click)="finish()"
                [isDisabled]="disableContinueButton() || isReadOnly"
                [buttonType]="uploadLaterButtonType"
                [buttonLabel]="'Upload Later'"
            >
            </aix-button>
            }
        </div>
        }
    </div>
</ng-template>

@if (aixTemplatesInitialized) {

<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(1, 2)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(2, 2)"
    [bodyTemplate]="uploadsStepContent"
    [isClosed]="false"
    [isDisabled]="false"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(3, 2)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(4, 2)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>
<aix-expansion-panel
    [headerTemplate]="getTemplate('orderStep')"
    [headerTemplateContext]="getTemplateConfig(5, 2)"
    [isClosed]="true"
    [isDisabled]="true"
    [arrowPosition]="'none'"
>
</aix-expansion-panel>

}
