<div class="aix-form__group aix-blink">
    <aix-dropdown
        #dropdownRef
        [aixDynamicNextPendingField]="{ config: config, margin: 0 }"
        [ngClass]="{ 'aix-form__control--required': templateData.fieldIsRequired }"
        [class.aix-form__control--dirty]="templateData.fieldIsDirty"
        [class.aix-form__control--invalid]="templateData.ctrlIsInvalid"
        [class.aix-form__control--invalid-required]="templateData.ctrlHasRequiredError"
        [class.aix-form__control--invalid-disabled]="
            templateData.ctrlIsInvalid && templateData.fieldIsDisabled
        "
        [labelField]="config.labelField!"
        [valueField]="config.valueField!"
        [options]="templateData.options"
        [value]="templateData.valueToRender"
        [cellRenderer]="getCellrendererByKey(config.cellRenderer!)!"
        [selectionStrategy]="selectByIdFunction"
        [unwrapSelectionStrategy]="unwrapSelectByIdFunction"
        [data-testing]="config.refId!"
        [placeholder]="config.placeholder!"
        [aixDisabled]="templateData.fieldIsDisabled"
        [omitFiltering]="!!config.storeActionOnFilter"
        [isExternalFiltering]="templateData.ctrlIsExternalFiltering"
        [hint]="config?.hint ?? ''"
        (filter)="onFilter($event)"
        (onFocused)="onFocus()"
        (onBlurred)="onBlur()"
    >
    </aix-dropdown>
    <label class="aix-form__label aix-label"> {{ config.label }} </label>
    <!-- Field decoration -->
    @if (fieldDecoration) {
    <div class="field-decoration">
        <ng-container
            aixFieldDecoration
            [decoration]="fieldDecoration"
            [params]="fieldDecorationParams"
            [hostObservables]="fieldDecorationHostObservables"
        >
        </ng-container>
    </div>
    }
</div>

@if (templateData.canDisplayErrors) {
<div class="form-error--container">
    <div
        class="form-error"
        title="{{ templateData.ctrlErrors | aixFormErrors }}"
        [data-testing]="config.refId! + '_validation_error'"
    >
        {{ templateData.ctrlErrors | aixFormErrors : config.label }}
    </div>
</div>
}
