import { Component, input } from '@angular/core';
import { ColorStyle } from '@trade-platform/ui-utils';
import {
    AixBreadcrumb,
    AixBreadcrumbsComponent
} from '../aix-breadcrumbs/aix-breadcrumbs.component';
import { NgIf, NgStyle } from '@angular/common';
import { AixTooltipDirective } from '../../directives/tooltip/aix-tooltip';
import { AixDataTestingDirective } from '../../directives/data-testing/data-testing.directive';

@Component({
    selector: 'aix-page-header',
    templateUrl: './aix-page-header.component.html',
    styleUrls: ['./aix-page-header.component.scss'],
    standalone: true,
    imports: [NgStyle, AixBreadcrumbsComponent, NgIf, AixTooltipDirective, AixDataTestingDirective]
})
export class AixPageHeaderComponent {
    header = input<string>();
    headerStyle = input<ColorStyle>();
    subHeader = input<string>(); // NOTE: This displays on the bottom right using flex;
    subHeaderTooltip = input<string | undefined>();
    breadcrumbs = input<AixBreadcrumb[]>([]);

    constructor() {}
}
