import { ErrorWrapper, OrderQueueGroup } from '@trade-platform/ui-shared';
import { failure, inProgress, notAsked, RemoteData, success } from 'ngx-remotedata';
import { LoadOrdersAdvancedFiltersActions, LoadOrdersAdvancedFiltersActionTypes } from './actions';

export type OrdersAdvancedFiltersState = RemoteData<OrderQueueGroup[], ErrorWrapper>;

export function ordersAdvancedFiltersReducer(
    state: OrdersAdvancedFiltersState = notAsked(),
    action: LoadOrdersAdvancedFiltersActions
): OrdersAdvancedFiltersState {
    switch (action.type) {
        case LoadOrdersAdvancedFiltersActionTypes.LOAD_ORDERS_ADVANCED_FILTERS:
            return inProgress();
        case LoadOrdersAdvancedFiltersActionTypes.LOAD_ORDERS_ADVANCED_FILTERS_SUCCESS:
            return success(action.payload);
        case LoadOrdersAdvancedFiltersActionTypes.LOAD_ORDERS_ADVANCED_FILTERS_FAILURE:
            return failure(action.payload.error);
        default:
            return state;
    }
}
