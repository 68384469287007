import { AixAction } from '../aix-action';
import { ErrorWrapper } from '../models/common';
import { type } from '../models/type-cache';
import { OrderReducerSuffixPayload } from '../../models/order/model';
import { Plan } from '../../models/plan/model';

export class PlanActionTypes {
    // Plan
    static readonly LOAD_PLAN_BY_TAX_ID = type('[Plan] Load Plan by Tax Id');
    static readonly LOAD_PLAN_BY_TAX_ID_SUCCESS = type('[Plan] Load Plan by Tax Id Success');
    static readonly LOAD_PLAN_BY_TAX_ID_FAILURE = type('[Plan] Load Plan by Tax Id Failure');
    static readonly CLEAR_PLAN = type('[Plan] Clear Plan');
}

export type PlanActions =
    | LoadPlanByTaxIdAction
    | LoadPlanByTaxIdSuccessAction
    | LoadPlanByTaxIdFailureAction
    | ClearPlanAction;

// Load Plan by Tax Id
export class LoadPlanByTaxIdAction implements AixAction {
    readonly type = PlanActionTypes.LOAD_PLAN_BY_TAX_ID;

    constructor(public payload: { taxId: string; planNumber: string }) {}
}
export class LoadPlanByTaxIdSuccessAction implements AixAction {
    readonly type = PlanActionTypes.LOAD_PLAN_BY_TAX_ID_SUCCESS;

    constructor(public payload: { plan: Plan }) {}
}
export class LoadPlanByTaxIdFailureAction implements AixAction {
    readonly type = PlanActionTypes.LOAD_PLAN_BY_TAX_ID_FAILURE;

    constructor(public payload: { error: ErrorWrapper }) {}
}
export class ClearPlanAction implements AixAction {
    readonly type = PlanActionTypes.CLEAR_PLAN;
    constructor(public payload: OrderReducerSuffixPayload) {}
}
