import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    Inject,
    OnDestroy,
    OnInit,
    output
} from '@angular/core';
import {
    BaseOrder,
    constants,
    DocumentFile,
    DocumentViewerTabTypes
} from '@trade-platform/ui-shared';
import {
    commentStatus,
    commentTag,
    fileType,
    orderStatus,
    orderStatusIndex
} from '@trade-platform/lib-enums';
import { Subscription } from 'rxjs';
import { ProfileStoreFacade } from '@advisor-ui/app-services';
import { getDocumentIndexById } from '../../../utils';
import { AsyncPipe, NgClass, NgFor, NgIf } from '@angular/common';
import { LetDirective } from '@ngrx/component';
import { AixDataTestingDirective, AixHeaderSectionComponent } from '@trade-platform/ui-components';
import { AixDocumentListComponent } from '../document-list/document-list';
import { AixOrderFormCommentsComponent } from '../../../order-comments/order-form-comments/order-form-comments';
import { OrderFormCommentsPipe } from '../../../order-comments/order-form-comments-pipe';
import { BaseOrdersStoreFacade, ORDERS_STORE_FACADE } from '../../../base.orders.store.facade';
import { getFilesToDisplay } from '../../document-viewer/document-viewer.helper';
import { getFormByIndex } from '../../../process/overview/utils/order-utils';

const flatten = (array: any[]): any[] => {
    return array.reduce((res: any[], curr) => {
        if (curr.children) {
            return res.concat(curr).concat(flatten(curr.children));
        } else {
            return res.concat(curr);
        }
    }, []);
};

@Component({
    selector: 'aix-document-tabs',
    templateUrl: 'document-tabs.html',
    styleUrls: ['./document-tabs.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
        LetDirective,
        NgFor,
        NgClass,
        AixHeaderSectionComponent,
        AixDataTestingDirective,
        NgIf,
        AixDocumentListComponent,
        AixOrderFormCommentsComponent,
        AsyncPipe,
        OrderFormCommentsPipe
    ]
})
export class AixDocumentTabsComponent implements OnInit, OnDestroy {
    onNotOnboarded = output<string>();
    onDownloadDocument = output<string>();

    tabs: DocumentViewerTabTypes[] = ['Order'];
    subscriptions: Subscription[] = [];

    filesToDisplay: DocumentFile[] = [];
    filesToDisplayIds: string[] = [];
    requiredDocuments: DocumentFile[] = [];
    supplementalFiles: DocumentFile[] = [];
    roles: string[];

    readonly commentsMode = 'modify';

    constructor(
        private ref: ChangeDetectorRef,
        @Inject(ORDERS_STORE_FACADE) public storeFacade: BaseOrdersStoreFacade,
        public profileStoreFacade: ProfileStoreFacade
    ) {}

    ngOnInit() {
        const profile = this.profileStoreFacade.profile;
        this.roles = profile ? profile.roles.map(role => role.name) : [];

        const orderId = this.storeFacade.order.id;
        this.storeFacade.actions.getFormComments.dispatch({ data: { orderId: orderId } });

        this.subscriptions.push(
            this.storeFacade.orderSuccess$.subscribe(order => {
                const currentDoc = this.storeFacade.documentViewer.currentDocument;
                this.filesToDisplay = getFilesToDisplay(order);
                this.filesToDisplayIds = this.filesToDisplay.map(
                    (f: DocumentFile) => f.formId || f.id
                );
                this.requiredDocuments = this.filesToDisplay.filter(
                    f => f.type === fileType.form || f.type === fileType.unassignedForm
                );
                this.supplementalFiles = this.filesToDisplay.filter(
                    f =>
                        f.type === fileType.supplemental ||
                        f.type === fileType.unassignedSupplemental
                );
                this.activateFile(order, currentDoc);
                this.ref.detectChanges();
            }),
            this.storeFacade.orderFormCommentsSuccess$.subscribe(_ => {
                this.setCommentsTab();
                this.ref.detectChanges();
            })
        );
    }

    activateFile(order: BaseOrder, docId: string | null) {
        const hasFilesToDisplay = this.filesToDisplay && this.filesToDisplay.length > 0;
        const index = getDocumentIndexById(this.filesToDisplay, docId);

        if (hasFilesToDisplay) {
            this.storeFacade.actions.documentViewerSetFiles.dispatch({
                filesToDisplay: this.filesToDisplay
            });
        }

        if (Number.isInteger(index)) {
            const currentFile = hasFilesToDisplay ? this.filesToDisplay[index] : null;
            let form = null;
            if (currentFile) {
                this.storeFacade.actions.documentViewerSetFile.dispatch({
                    currentFile: currentFile
                });
                form = order.forms[currentFile.formId as string];
            } else {
                form = getFormByIndex(order, index);
            }

            const isOnboarded = form ? form.isOnboarded : true;
            if (!isOnboarded) {
                if (form && !currentFile) {
                    this.storeFacade.actions.documentViewerSetForm.dispatch({ currentForm: form });
                }
                if (orderStatusIndex[order.status] < orderStatusIndex[orderStatus.readyToSend]) {
                    this.onNotOnboarded.emit(form?.id ?? '');
                }
            }
        } else if (hasFilesToDisplay) {
            this.storeFacade.actions.documentViewerSetFile.dispatch({
                currentFile: this.filesToDisplay[0]
            });
        }
        this.storeFacade.actions.documentViewerSetDocument.dispatch({
            currentDocument: docId ? docId : this.filesToDisplay[0]?.id
        });
        this.ref.markForCheck();
    }

    setCommentsTab() {
        // Comments;
        const orderFormComments = this.storeFacade.orderFormComments;
        const documentViewAction = this.storeFacade.documentViewer.action;

        if (documentViewAction !== 'none' || (orderFormComments && orderFormComments.length > 0)) {
            if (this.tabs.indexOf('Comments') === -1) {
                this.tabs.push('Comments');

                // Select comments tab if the last message for a reviewer it's a clarification resolved
                if (this.roles.includes(constants.profileTypes.REVIEWER)) {
                    const lastComment = flatten(orderFormComments).sort(
                        (a: number, b: number) => new Date(a).getTime() - new Date(b).getTime()
                    )[0];
                    if (
                        lastComment &&
                        lastComment.commentTag === commentTag.clarification &&
                        lastComment.status === commentStatus.resolved
                    ) {
                        this.onClickTab('Comments');
                    }
                }
            }
        }
    }

    onClickTab(tab: DocumentViewerTabTypes) {
        this.storeFacade.actions.documentViewerSetTab.dispatch({ tab: tab });
    }

    downloadDocument(fileId: string) {
        this.onDownloadDocument.emit(fileId);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }
}
